import styled from 'styled-components';
import { Button, OverlayModalContent } from '@streda/web_components';

export const StepHeader = styled.h4`
  color: ${({ theme }) => theme.colors.font1};
`;

export const GrayedDescription = styled.p`
  color: ${({ theme }) => theme.colors.font3};

  .highlight {
    color: ${({ theme }) => theme.colors.font1};
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.font1};
`;
export const ActionButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const ActionBtn = styled(Button)`
  flex: 1;

  button {
    width: 100%;
  }
`;

export const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  align-items: center;
`;
