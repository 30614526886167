export enum PairingStatusesEnums {
  DEVICE_JOINED = 'DeviceJoined',
  INTERVIEW_STARTED = 'InterviewStarted',
  DEVICE_ANNOUNCED = 'DeviceAnnounced',
  INTERVIEW_SUCCESS = 'InterviewSuccess',
};

export enum DeviceOriginsEnums {
  EXTERNAL = 'External',
}

export interface IPairingMessage {
  pairingStatus: typeof PairingStatusesEnums[keyof typeof PairingStatusesEnums];
  locationId: string;
  deviceOrigin: typeof DeviceOriginsEnums[keyof typeof DeviceOriginsEnums];
  zigbeeId: string;
}
