import * as React from 'react';
import { IAddDeviceStepComponent, IStep } from '../add-external-device.types';
import InfoStep from './info-step/info-step';
import OpeningSuccess from './opening-success-step/opening-success-step';
import CountdownStep from './countdown-step/countdown-step';

const stepsDefinition: IStep[] = [
  {
    Component: InfoStep,
  },
  {
    Component: CountdownStep,
  },
  {
    Component: OpeningSuccess,
  },
];
const ZigbeeNetworkOpen = function(props: IAddDeviceStepComponent) {
  const { onNextStep } = props;
  const [step, setStep] = React.useState(0);
  const StepComponent = stepsDefinition[step].Component;

  return (
    <StepComponent
      onNextStep={(stepNumber = step + 1) => {
        if (step >= stepsDefinition.length - 1) {
          onNextStep();
          return;
        }
        setStep(stepNumber);
      }}
    />
  );
};

export default ZigbeeNetworkOpen;
