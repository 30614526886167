import {
  ToastHelper,
  getRoomsApi,
  getDocksApi,
  getDictionaryApi,
  ToastType,
  getLocationsApi,
  getCompanyApi,
  CompanyDto,
} from '@streda/web_components';
import { getAccount } from '../utils/auth.utils';

export const getLocationRooms = locationId =>
  getRoomsApi(getAccount())
    .roomGetRooms(locationId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting location rooms. Please try again or contact with system administrator',
        error,
      );
    });

export const getLocationDocks = (locationId, roomId) =>
  getDocksApi(getAccount())
    .dockGetDocks(locationId, roomId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting room docks. Please try again or contact with system administrator',
        error,
      );
    });

export const getLocationDetails = locationId =>
  getLocationsApi(getAccount())
    .locationGetLocation(locationId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting location details. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const getMyCompany: () => Promise<CompanyDto | void> = () =>
  getCompanyApi(getAccount())
    .companyGetCurrentCompanyInformation()
    .then(response => response.data)
    .catch(() => {
      return Promise.resolve();
    });

export const getDeviceTypes = () =>
  getDictionaryApi(getAccount())
    .dictionaryGetDeviceTypes()
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting device types. Please try again or contact with system administrator',
        error,
      );
    });
