import {
  getSnapinApi,
  SnapInProductDto,
  SnapInStatusEnum2,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { getAccount } from '../auth.utils';

export const snapInUnregister = (locationId: string, serialNumber: string) =>
  getSnapinApi(getAccount())
    .snapInDeleteSnapInFromLocation(locationId, serialNumber)
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during removing snap-in. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
export const snapInRegister = (
  locationId: string,
  serialNumber: string,
  dockNumber: string,
  force: boolean = false,
): Promise<SnapInProductDto> =>
  getSnapinApi(getAccount())
    .snapInAddSnapInToLocation({
      locationId,
      serialNumber,
      dockNumber,
      force,
    })
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during registering snap-in. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
export const getSnapInById = (snapInId: string): Promise<SnapInProductDto> =>
  getSnapinApi(getAccount())
    .snapInGetSnapInById(snapInId)
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting snap-in. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const updateSnapInStatus = (
  serialNumber: string,
  snapInStatus: SnapInStatusEnum2,
): Promise<SnapInProductDto> =>
  getSnapinApi(getAccount())
    .snapInUpdateSnapInStatus(serialNumber, snapInStatus)
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during updating snap-in status. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const swapSnapIns = (
  locationId: string,
  sourceSnapInDockNumber: string,
  targetSnapInDockNumber: string,
): Promise<SnapInProductDto> =>
  getSnapinApi(getAccount())
    .snapInSwapSnapIn({
      locationId,
      sourceDockNumber: sourceSnapInDockNumber,
      targetDockNumber: targetSnapInDockNumber,
    })
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during swapping snap-ins. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
