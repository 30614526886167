import * as React from 'react';
import { Spinner } from '@streda/web_components';
import { GrayedDescription, StepHeader } from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import NewSnapInContext from '../../add-new-snap-in.context';
import { snapInRegister } from '../../../../../../utils/api/snap-in.api';
import { AppContext } from '../../../../../../contexts/app-context/app-context';

const Registering: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { newSnapIn, setNewSnapIn } = React.useContext(NewSnapInContext);
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const { serialNumber } = newSnapIn;
  const { dock, onNextStep } = props;
  const { number } = dock;

  React.useEffect(() => {
    setNewSnapIn({
      ...newSnapIn,
      zigbeeId: null,
    });

    snapInRegister(currentLocationId, serialNumber, number, true)
      .then(snapInData => setNewSnapIn(snapInData))
      .finally(() => onNextStep());
  }, []);

  return (
    <>
      <StepHeader>Registration</StepHeader>
      <Spinner size={145} show />
      <GrayedDescription>
        The system is registering <br /> the snap-in to gateway <br />
        <br />
        Please wait...
      </GrayedDescription>
    </>
  );
};

export default Registering;
