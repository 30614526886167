import * as React from 'react';
import { Text, baseColors } from '@streda/web_components';
import { BLOCKS, MARKS, Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';

export interface IRichContentfulProps {
  document: Document;
}

const InfoDetails = styled(Text)<{ color?: string }>`
  font-weight: 500;
  line-height: 28px;
  white-space: pre-line !important;
  margin-bottom: 12px;
`;

const BoldyText = styled.p`
  font-weight: bold;
  width: auto;
`;

const RichContentful: React.FC<IRichContentfulProps> = ({ document }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <BoldyText>{text}</BoldyText>,
      [MARKS.ITALIC]: text => (
        <Text bold style={{ fontStyle: 'italic' }}>
          {text}
        </Text>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => {
        return <InfoDetails fontSize="16px">{children}</InfoDetails>;
      },
      [BLOCKS.HEADING_4]: (_node, children) => (
        <InfoDetails fontSize="18px" color={baseColors.white}>
          {children}
        </InfoDetails>
      ),
      [BLOCKS.HEADING_1]: (_node, children) => (
        <InfoDetails fontSize="25px" color={baseColors.white}>
          {children}
        </InfoDetails>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <InfoDetails fontSize="23px" color={baseColors.white}>
          {children}
        </InfoDetails>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <InfoDetails fontSize="21px" color={baseColors.white}>
          {children}
        </InfoDetails>
      )
    },
  };
  return <>{documentToReactComponents(document, options)}</>;
};

export default RichContentful;
