import * as React from 'react';
import SceneButton from './scene-button/scene-button';

const DashboardScenes = function(props) {
  const { scenes, docks } = props;

  return (
    <>
      {scenes.map(scene => (
        <SceneButton key={scene.id} docks={docks} scene={scene} />
      ))}
    </>
  );
};

export default DashboardScenes;
