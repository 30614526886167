import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ScenesList from './scenes-list/scenes-list';
import ScenesCreator from './scenes-creator/scenes-creator';

const Scenes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={url}>
        <ScenesList />
      </Route>
      <Route path={[`${url}/create-scene`, `${url}/edit-scene`]}>
        <ScenesCreator />
      </Route>
    </Switch>
  );
};

export default Scenes;
