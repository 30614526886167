import * as React from 'react';
import {
  Button,
  ButtonColorType,
  DeleteIcon,
  ActionModal,
  deleteExternalDevice,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import { IExternalDeviceModal } from './external-device-modal.types';
import {
  DevicePropertyLabel,
  DevicePropertyValue,
  FunctionsDescriptionLabel,
  SectionContainer,
  StyledCheckIcon,
} from '../../add-external-device/device-pairing/captured-device-step/captured-device-step.components';
import { deviceClusterTypesTranslations } from '../../add-external-device/device-pairing/captured-device-step/captured-device-step';
import { StyledHorizontalLine, StyledModal } from './external-device-modal.components';
import { AppContext } from '../../../../../contexts/app-context/app-context';
import { initAppState } from '../../../../main-view.logic';

const ExternalDeviceModal: React.FC<IExternalDeviceModal> = function({
  show,
  externalDevice,
  onHide,
}) {
  const { colors } = React.useContext(ThemeContext);
  const [appContext, dispatchGlobalAction] = React.useContext(AppContext);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState<boolean>(false);

  if (!show) {
    return null;
  }

  const { description, vendor, model, endpoints, zigbeeId } = externalDevice;
  const defaultEndpoint = endpoints?.find(endpoint => endpoint.name === 'default') || {};
  const { currentLocationId } = appContext;
  const onDeleteDevice = () => {
    setPending(true);
    deleteExternalDevice(currentLocationId, zigbeeId)
      .then(() => {
        initAppState(currentLocationId, dispatchGlobalAction);
        onHide();
        ToastHelper(ToastType.SUCCESS, 'Success!. External device has been deleted');
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <>
      <StyledModal title="Device Details" show onHide={onHide}>
        <SectionContainer>
          <p>
            <DevicePropertyLabel>Name: </DevicePropertyLabel>
            <DevicePropertyValue>{description}</DevicePropertyValue>
          </p>
          <p>
            <DevicePropertyLabel>Model: </DevicePropertyLabel>
            <DevicePropertyValue>{model}</DevicePropertyValue>
          </p>
          <p>
            <DevicePropertyLabel>Producer: </DevicePropertyLabel>
            <DevicePropertyValue>{vendor}</DevicePropertyValue>
          </p>
        </SectionContainer>
        <SectionContainer>
          <FunctionsDescriptionLabel>Supported function by Streda:</FunctionsDescriptionLabel>
          {defaultEndpoint?.clusters
            .filter(({ clusterType }) => deviceClusterTypesTranslations[clusterType])
            .map(({ clusterType }) => (
              <p key={clusterType}>
                <StyledCheckIcon width={16} height={16} fill={colors.font3} />
                <DevicePropertyValue>
                  {deviceClusterTypesTranslations[clusterType]}
                </DevicePropertyValue>
              </p>
            ))}
        </SectionContainer>
        <StyledHorizontalLine />
        <Button
          type="button"
          text="Delete device"
          onClick={() => setShowDeleteModal(true)}
          testId="delete-external-device-button"
          startIcon={<DeleteIcon />}
          colorType={ButtonColorType.UNIVERSAL}
        />
      </StyledModal>
      <ActionModal
        onHide={() => setShowDeleteModal(false)}
        onConfirm={() => onDeleteDevice()}
        show={showDeleteModal}
        icon={<DeleteIcon width={80} height={80} />}
        header="Delete device?"
        description={description}
        pending={pending}
      />
    </>
  );
};

export default ExternalDeviceModal;
