// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';

export interface ILoginLocationState {
  systemError?: string;
}

export interface ILoginLocation extends Location {
  state: ILoginLocationState;
}

export interface ILoginPageProps {
  history: any;
  loginCallback(): any;
  location: ILoginLocation;
}

export interface ILoginData {
  login: string;
  password: string;
}

export const LoginErrorsStatuses = {
  403: 'The email or password you entered is incorrect.\nPlease try again.',
  504: 'Cannot connect to backend service.',
};

export const LoginErrorsCodes = {
  ECONNABORTED: 'Reached request timeout. Please try again.',
};

export const LoginErrorsMessages = {
  'Network Error': 'Network error. Check your internet connection.',
  'Insufficient Privileges': 'Insufficient Privileges.',
};
