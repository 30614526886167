import * as React from 'react';
import { SceneTile } from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../contexts/app-context/app-context';
import { onSceneInvoke } from '../../../scenes/scenes-list/scenes-list.logic';

const SceneButton = function(props) {
  const [appContext] = React.useContext(AppContext);
  const [pending, setPending] = React.useState<boolean>();
  const { scene, docks } = props;
  const history = useHistory();

  return (
    <SceneTile
      scene={scene}
      docks={docks}
      pending={pending}
      onSceneInvoke={event => {
        event.preventDefault();
        event.stopPropagation();
        setPending(true);
        onSceneInvoke(appContext.currentLocationId, scene.id).finally(() => setPending(false));
      }}
      onSceneEdit={event => {
        event.preventDefault();
        event.stopPropagation();
        history.push(`/scenes/edit-scene`, {
          scene,
        });
      }}
    />
  );
};

export default SceneButton;
