import * as React from 'react';
import { Toast } from '@streda/web_components';
import { ISnapInReplaceStepComponent, IStep } from '../../snap-in-replace.types';
import SnapInDetails from '../registration/snap-in-details/snap-in-details';
import { initAppState } from '../../../../main-view.logic';

const stepsDefinition: IStep<ISnapInReplaceStepComponent>[] = [
  {
    Component: SnapInDetails,
    props: {
      onNextStep: (dock, room, appContext, history) => {
        const [globalContext, dispatchGlobalAction] = appContext;
        const { currentLocationId } = globalContext;

        initAppState(currentLocationId, dispatchGlobalAction).then(() => {
          history.push(`/dashboard/room/${room.id}`);
          Toast.showToast({
            message: 'Success! Snap-in has been replaced',
            colorType: 'Success',
          });
        });
      },
      actionBtnText: 'Finish',
    },
  },
];

const Finish: React.FC<ISnapInReplaceStepComponent> = function({
  onNextStep,
  onPreviousStep,
  dock,
  snapIn,
  room,
}) {
  const [step, setStep] = React.useState(0);
  const { Component, props } = stepsDefinition[step];

  return (
    <Component
      onNextStep={(stepNumber = step + 1) => {
        if (!stepsDefinition[stepNumber]) {
          onNextStep();
          return;
        }
        setStep(stepNumber);
      }}
      onPreviousStep={onPreviousStep}
      dock={dock}
      snapIn={snapIn}
      room={room}
      {...props}
    />
  );
};

export default Finish;
