import styled from 'styled-components';
import { GridFullRow, SvgImage } from '@streda/web_components';

export const InfoIcon = styled(SvgImage)`
  div {
    svg {
      position: absolute;
      right: -9px;
      top: -10px;
      background: var(--background-2);
      padding: 2px;
      border-radius: 50%;

      path {
        fill: var(--fonts-1);
      }

      circle {
        stroke: var(--fonts-1);
      }
    }
  }
`;

export const SectionLabel = styled(GridFullRow)`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 16px;
`;

export const SnapinWrapper = styled.div`
  position: relative;
`;

export const Dock = styled(GridFullRow)`
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.table.rowHover};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: end;
  grid-column: 1 / -1;
  gap: 16px;
`;
