import { SnapInInDockStateEnum } from '@streda/web_components';

export const snapInStatusesTranslations = {
  [SnapInInDockStateEnum.Unknown]: 'Unknown',
  [SnapInInDockStateEnum.Available]: 'Available',
  [SnapInInDockStateEnum.Empty]: 'Empty',
  [SnapInInDockStateEnum.Broken]: 'Broken',
};

export default {
  snapInStatusesTranslations,
};
