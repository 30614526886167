import * as React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import {
  Cookies,
  unsubscribeForDeviceStates,
  useCookies,
  useCookiesModal,
  Page,
  getAccount,
  storeAccount,
  useDeviceState,
  useInitialDeviceState,
  useHubConnection,
  useInitialDevicesConfiguration,
} from '@streda/web_components';
import styled from 'styled-components';
import { AppContext } from '../contexts/app-context/app-context';
import NavigationTopBar from './navigation-bar/navigation-top-bar/navigation-top-bar';
import Dashboard from './pages/dashboard/dashboard';
import Connections from './pages/connections/connections';
import Scenes from './pages/scenes/scenes';
import Settings from './pages/settings/settings';
import * as pkj from '../../package.json';
import { initAppState } from './main-view.logic';
import Analytics from './pages/analytics/analytics';
import footerLinks from './Footer/config';
import Footer from './Footer/Footer';
import Contact from './pages/contact/contact';
import Privacy from './pages/privacy/PrivacyWrapper';
import ExternalDevices from './pages/external-devices/external-devices';
import SnapInReplace from './pages/snap-in-replace/snap-in-replace';
import LedsHomeSettings from './leds-home-settings/leds-home-settings';

const MainContent = styled.div`
  background: ${({ theme }) => theme.colors.background2};
  width: 100%;
  height: 100%;
`;

const BuildVersion = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 3px;
  color: var(--fonts-1);
  z-index: 999;
`;

const PageWrapper = styled(Page)`
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const MainView: React.FC = function() {
  const [appContext, dispatch] = React.useContext(AppContext);
  const { locationId } = getAccount();
  const { hubConnection, devicesState } = useDeviceState();
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const { name, setCookie } = useCookies(Cookies.CookiesAccepted);
  const { onPresentCookiesModal, removeCookiesModal } = useCookiesModal({
    onCookiesAccept: () => setCookie(Cookies.CookiesAccepted, 'acc'),
    onTermsClicked: () => history.push('/terms&policy'),
  });

  const { docks, rooms } = appContext;

  useInitialDeviceState(docks, rooms, locationId);
  useInitialDevicesConfiguration(locationId);
  useHubConnection(locationId);

  React.useEffect(() => {
    if (locationId) {
      initAppState(locationId, dispatch).catch(error => {
        storeAccount(null);
        history.push(`/login`, {
          systemError: error.toString(),
        });
      });
    }
    if (!name) {
      onPresentCookiesModal();
    }
    return () => {
      removeCookiesModal();
      if (locationId && hubConnection) {
        unsubscribeForDeviceStates(hubConnection, locationId);
      }
    };
  }, []);

  return (
    devicesState && (
      <MainContent>
        <NavigationTopBar />
        <PageWrapper grid>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/connections" component={Connections} />
          <Route path="/scenes" component={Scenes} />
          <Route path="/analytics/:roomId?" component={Analytics} />
          <Route path="/external-devices" component={ExternalDevices} />
          <Route path="/snap-in-replace/:dockNumber" component={SnapInReplace} />
          <Route path="/settings" component={Settings} />
          <Route path="/terms&policy" component={Privacy} />
          <Route path="/contact" component={Contact} />
          <BuildVersion>[{pkj.version}]</BuildVersion>
          <Footer
            companyName={`© ${currentYear} Streda, Isolectra B.V`}
            footerLinks={footerLinks}
          />
        </PageWrapper>
        <LedsHomeSettings />
      </MainContent>
    )
  );
};

export default MainView;
