import {
  ToastType,
  ToastHelper,
  PagedResultOfLocationLevelPowerConsumptionDto,
  SnapInStateDataDto,
} from '@streda/web_components';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { getScenesForLocation } from '../../../utils/api/scenes.api';
import {
  getDevicesStatesForLocation,
  getPowerConsumptionForLocation,
} from '../../../utils/api/devices-state.api';

const getPowerConsumption = (
  locationId: string,
  onGetData: (result: PagedResultOfLocationLevelPowerConsumptionDto) => void,
) => {
  getPowerConsumptionForLocation(locationId)
    .then(res => {
      onGetData(res);
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting power consumption data. Please try again or contact with system administrator',
        error,
      );
    });
};

export const getScenesList = (locationId: string) =>
  getScenesForLocation(locationId).catch(error => {
    ToastHelper(
      ToastType.DANGER,
      'Error during getting location scenes. Please try again or contact with system administrator',
      error,
    );
  });

export const initDevicesStates = (locationId: string): Promise<SnapInStateDataDto[]> =>
  getDevicesStatesForLocation(locationId)
    .then(result => {
      return result;
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting devices connections. Please try again or contact with system administrator',
        error,
      );
      return [];
    });

export const filterGlobalScenes = scenes => scenes.filter(scene => scene.roomIds.length === 0);

export const watchPowerConsumption = (
  locationId: string,
  onGetData: (result: PagedResultOfLocationLevelPowerConsumptionDto) => void,
  refreshInterval: number,
): NodeJS.Timer => {
  getPowerConsumption(locationId, onGetData);
  return setInterval(() => {
    getPowerConsumption(locationId, onGetData);
  }, refreshInterval);
};

export const parsePowerConsumption = (
  powerConsumptionDto: PagedResultOfLocationLevelPowerConsumptionDto,
) => {
  const parsedConsumption = powerConsumptionDto.data.map(
    ({ locationPowerMeasurments }) => locationPowerMeasurments.powerConsumption.power,
  );

  return {
    power: parsedConsumption.reduce(
      (previousConsumption, consumption) => previousConsumption + consumption,
      0,
    ),
  };
};

export default {
  getScenesList,
  filterGlobalScenes,
  initDevicesStates,
  watchPowerConsumption,
  parsePowerConsumption,
};
