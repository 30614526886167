import styled from 'styled-components';
import { ArrowsLoopIcon } from '@streda/web_components';
import { ActionButton } from '../../../external-devices/add-external-device/device-pairing/captured-device-step/captured-device-step.components';

export const SnapInDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SnapInWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`;

export const SnapInRenderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SnapInDetailContainer = styled.div`
  text-align: left;
  margin-bottom: 8px;
`;

export const ArrowsIcon = styled(ArrowsLoopIcon)`
  transform: rotate(90deg);
`;

export const SnapInPlaceholderWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 24px 0;

  &:after {
    content: '';
    position: absolute;
    top: -24px;
    left: -24px;
    right: -24px;
    bottom: -24px;
    border: 1px dashed ${({ theme }) => theme.colors.font3};
    z-index: -1;
  }
`;

export const SnapInPlaceholderDescription = styled.p`
  width: 297px;
  color: ${({ theme }) => theme.colors.font3};
  margin: 0 24px;
  font-size: 14px;
`;

export const AddSnapInToSwapBtn = styled.p`
  color: ${({ theme }) => theme.colors.font1};
  margin: 8px 0;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
`;

export const SwapButton = styled(ActionButton)`
  width: 287px;
`;

export const ClearSnapInBtn = styled.span`
  color: ${({ theme }) => theme.colors.font1};
  user-select: none;
  cursor: pointer;
`;

export const SelectedSnapInWrapper = styled.div`
  text-align: left;
  width: 100%;
`;
