export interface FooterLink {
  label: string;
  href: string;
}

const footerLinks: FooterLink[] = [
  {
    label: 'Contact & support',
    href: '/contact',
  },
  {
    label: 'Terms & privacy',
    href: '/terms&policy',
  },
];

export default footerLinks;
