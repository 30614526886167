import * as React from 'react';
import { ButtonColorType, SnapInsPlugOutImg } from '@streda/web_components';
import {
  ActionBtn,
  ActionButtonsContainer,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';

const RemoveInstruction: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  return (
    <>
      <StepHeader>Remove snap-ins</StepHeader>
      <SnapInsPlugOutImg />
      <GrayedDescription>
        Remove frame, loosen screws and take out snap-in from the dock
      </GrayedDescription>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Snap-ins removed"
          onClick={() => onNextStep()}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default RemoveInstruction;
