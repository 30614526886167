import * as React from 'react';
import { ISnapInReplaceStepComponent, IStep } from '../../snap-in-replace.types';
import MountInfo from './mount-info/mount-info';
import ZigbeeCountdown from './zigbee-countdown/zigbee-countdown';
import ZigbeeOpenStatus from './zigbee-open-status/zigbee-open-status';
import PairingCountdown from './pairing-countdown/pairing-countdown';
import PairingStatus from './pairing-status/pairing-status';

const stepsDefinition: IStep<ISnapInReplaceStepComponent>[] = [
  {
    Component: MountInfo,
  },
  {
    Component: ZigbeeCountdown,
  },
  {
    Component: ZigbeeOpenStatus,
  },
  {
    Component: PairingCountdown,
  },
  {
    Component: PairingStatus,
  },
];

const Pairing: React.FC<ISnapInReplaceStepComponent> = function({
  onNextStep,
  onPreviousStep,
  dock,
  snapIn,
  room,
}) {
  const [step, setStep] = React.useState(0);
  const { Component, props } = stepsDefinition[step];

  return (
    <Component
      onNextStep={(stepNumber = step + 1) => {
        if (!stepsDefinition[stepNumber]) {
          onNextStep();
          return;
        }
        setStep(stepNumber);
      }}
      onPreviousStep={onPreviousStep}
      dock={dock}
      snapIn={snapIn}
      room={room}
      {...props}
    />
  );
};

export default Pairing;
