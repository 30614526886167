import { ToastHelper, ToastType, getConfigurationApi } from '@streda/web_components';
import { getAccount } from '../../../utils/auth.utils';
import { sendLocationToBcb } from '../../../utils/api/configuration.api';
import { mapConnectionToClassConnections } from './connections.logic';

const showSaveConfigurationErrorToast = (errorMsg: string) => {
  ToastHelper(ToastType.DANGER, errorMsg);
};

const CONFIGURATION_REPLACE_ERROR =
  'Error during replacing connection configurations. Please try again or contact with system administrator';
const BCB_LOCATION_SEND_ERROR =
  'Error during sending location to SCB. Please try again or contact with system administrator';

export const getLocationConnections = locationId =>
  getConfigurationApi(getAccount())
    .configurationGetConfigureConnections(locationId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting location rooms. Please try again or contact with system administrator',
        error,
      );
    });

export const saveConfigurations = (locationId, connections, setLastSaveConnections) => {
  const parsedConnections = connections.map(connection => {
    const { _connection } = connection;

    return _connection || connection;
  });

  return getConfigurationApi(getAccount())
    .configurationReplaceConnectionConfigurations(locationId, false, parsedConnections)
    .then(result => {
      if (result.status !== 200) {
        showSaveConfigurationErrorToast(CONFIGURATION_REPLACE_ERROR);

        return Promise.reject();
      }

      ToastHelper(ToastType.SUCCESS, 'Success!. Configuration has been saved');
      setLastSaveConnections(mapConnectionToClassConnections(parsedConnections));

      return Promise.resolve();
    })
    .catch(error => {
      showSaveConfigurationErrorToast(CONFIGURATION_REPLACE_ERROR);

      return Promise.reject(error);
    });
};

export const activateLocation = locationId => {
  sendLocationToBcb(locationId)
    .then(result => {
      if (result.status !== 200) {
        showSaveConfigurationErrorToast(BCB_LOCATION_SEND_ERROR);
      } else {
        ToastHelper(ToastType.SUCCESS, 'Success!. Configuration has been activated');
      }
    })
    .catch(() => {
      showSaveConfigurationErrorToast(BCB_LOCATION_SEND_ERROR);
    });
};

export default {
  getLocationConnections,
  saveConfigurations,
};
