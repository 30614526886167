import * as React from 'react';
import { dark, light } from '@streda/web_components';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}
const ThemeContext = React.createContext<ThemeContextType>({
  isDark: true,
  toggleTheme: () => null,
});
const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = React.useState(true);
  const toggleTheme = () => {
    setIsDark(prevState => {
      return !prevState;
    });
  };
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? dark : light}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
};
export { ThemeContext, ThemeContextProvider };
