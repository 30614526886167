import { getDictionaryApi, ToastHelper, ToastType } from '@streda/web_components';
import { getAccount } from '../auth.utils';

export const getSnapInTypes = () =>
  getDictionaryApi(getAccount())
    .dictionaryGetSnapInTypes()
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting snap-in types. Please try again or contact with system administrator',
        error,
      );
      return error;
    });

export const getDocksPositions = () =>
  getDictionaryApi(getAccount())
    .dictionaryGetDict('Position')
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting dock positions. Please try again or contact with system administrator',
        error,
      );
      return error;
    });

