import { getAccount, getScenesApi, Toast } from '@streda/web_components';

const showSaveSceneErrorToast = (
  errMsg = 'Error during saving scene. Please try again or contact with system administrator',
) => {
  Toast.showToast({
    message: errMsg,
    colorType: 'Danger',
  });
};

export const saveScene = (locationId, scene) => {
  const sceneObject = {
    ...scene,
    actions: scene.actions.map(({ action, actionParameters, targetDevice }) => ({
      action,
      actionParameters,
      targetDevice,
    })),
  };
  const saveSceneFunction = scene.id
    ? getScenesApi(getAccount()).sceneUpdateScene(locationId, sceneObject)
    : getScenesApi(getAccount()).sceneAddScene(locationId, sceneObject);

  return saveSceneFunction
    .then(result => {
      if (result.status !== 200) {
        showSaveSceneErrorToast();
      } else {
        Toast.showToast({
          message: 'Success! Scene has been saved',
          colorType: 'Success',
        });
      }
      return Promise.resolve();
    })
    .catch(error => {
      const { response = {} } = error;
      const { data } = response;

      showSaveSceneErrorToast(data);
      return Promise.reject();
    });
};

export default {
  saveScene,
};
