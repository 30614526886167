import * as React from 'react';
import { Svg, SvgProps } from '@streda/web_components';

const Icon: React.FC<SvgProps> = () => {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0004 15.9201V18.9201C21.0016 19.1986 20.9445 19.4743 20.8329 19.7294C20.7214 19.9846 20.5577 20.2137 20.3525 20.402C20.1473 20.5902 19.905 20.7336 19.6412 20.8228C19.3773 20.912 19.0978 20.9452 18.8204 20.9201C15.7433 20.5857 12.7874 19.5342 10.1904 17.8501C7.77425 16.3148 5.72576 14.2663 4.19042 11.8501C2.5004 9.2413 1.44866 6.27109 1.12042 3.1801C1.09543 2.90356 1.1283 2.62486 1.21692 2.36172C1.30555 2.09859 1.44799 1.85679 1.63519 1.65172C1.82238 1.44665 2.05023 1.28281 2.30421 1.17062C2.5582 1.05843 2.83276 1.00036 3.11042 1.0001H6.11042C6.59573 0.995321 7.06621 1.16718 7.43418 1.48363C7.80215 1.80008 8.0425 2.23954 8.11042 2.7201C8.23704 3.68016 8.47187 4.62282 8.81042 5.5301C8.94497 5.88802 8.97408 6.27701 8.89433 6.65098C8.81457 7.02494 8.62928 7.36821 8.36042 7.6401L7.09042 8.9101C8.51398 11.4136 10.5869 13.4865 13.0904 14.9101L14.3604 13.6401C14.6323 13.3712 14.9756 13.1859 15.3495 13.1062C15.7235 13.0264 16.1125 13.0556 16.4704 13.1901C17.3777 13.5286 18.3204 13.7635 19.2804 13.8901C19.7662 13.9586 20.2098 14.2033 20.527 14.5776C20.8441 14.9519 21.0126 15.4297 21.0004 15.9201Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
