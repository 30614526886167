import styled from 'styled-components';
import { Button } from '@streda/web_components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;
  background: ${({ theme }) => theme.colors.modal};
  color: ${({ theme }) => theme.colors.font1};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 32px;
`;
export const Description = styled.p`
  color: ${({ theme }) => theme.colors.font3};
  font-size: 16px;
  max-width: 354px;
  text-align: center;
  line-height: 24px;
`;

export const Content = styled.div`
  display: flex;
  gap: 55px;
  flex-direction: column;
  align-items: center;
`;

export const ActionBtn = styled(Button)`
  flex: 1;
  width: 100%;

  button {
    width: 100%;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
`;
