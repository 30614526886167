import * as React from 'react';
import { Svg } from '@streda/web_components';
import styled from 'styled-components';

const SvgWave = styled(Svg)`
  width: 100%;
  height: 15vw;
  overflow: hidden;
  grid-column: 1 / -1;
  margin-top: 56px;
`;

const Icon: React.FC = () => {
  return (
    <SvgWave
      width="1131"
      height="135"
      viewBox="0 0 1131 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-327.35 263.229C-292.352 237.55 -253.913 216.759 -215.092 197.757C-175.179 178.222 -133.788 160.531 -90.6108 150.453C-19.162 133.739 55.2714 138.506 128.253 144.416C138.797 145.272 149.345 146.152 159.897 147.056C241.432 154.039 323.272 161.599 403.9 176.213C490.01 191.823 574.547 216.088 660.129 234.198C705.245 243.749 750.635 252.453 796.238 259.157C827.553 263.756 859.048 267.865 890.246 273.276C1006.79 293.519 1122.06 320.555 1235.44 354.246"
        stroke="url(#paint0_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-326.836 257.228C-291.783 232 -253.539 211.353 -214.834 192.58C-175.039 173.28 -133.76 155.945 -90.7229 146.146C-19.468 129.928 54.695 134.915 127.37 141.191C137.89 142.092 148.4 143.026 158.902 143.993C240.503 151.458 322.439 159.648 403.134 174.591C489.1 190.517 573.425 214.953 658.868 233.212C703.898 242.835 749.22 251.566 794.777 258.021C825.994 262.445 857.444 266.221 888.552 271.343C1004.66 290.54 1119.63 316.04 1232.96 347.731"
        stroke="url(#paint1_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-326.328 251.237C-291.239 226.459 -253.179 205.938 -214.592 187.409C-174.918 168.348 -133.716 151.368 -90.81 141.907C-19.7263 126.189 54.1481 131.401 126.517 138.015C136.985 138.976 147.457 139.964 157.931 140.979C239.632 148.913 321.585 157.716 402.358 172.997C488.161 189.215 572.297 213.84 657.591 232.244C702.535 241.939 747.794 250.714 793.293 256.909C824.401 261.157 855.779 264.599 886.842 269.433C1002.41 287.427 1117.17 311.705 1230.45 341.244"
        stroke="url(#paint2_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-325.825 245.245C-290.701 220.85 -252.826 200.518 -214.333 182.241C-174.736 163.432 -133.633 146.849 -90.835 137.69C-19.9272 122.503 53.6586 127.914 125.725 134.87C136.149 135.874 146.599 136.915 157.018 137.987C238.782 146.38 320.798 155.792 401.607 171.402C487.243 187.944 571.189 212.727 656.33 231.275C701.184 241.042 746.381 249.827 791.852 255.796C822.87 259.85 854.172 262.977 885.176 267.509C1000.33 284.403 1114.77 307.289 1227.99 334.739"
        stroke="url(#paint3_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-325.323 239.258C-290.194 215.264 -252.477 195.108 -214.106 177.087C-174.585 158.54 -133.519 142.357 -90.8513 133.523C-20.1102 118.881 53.1825 124.513 124.938 131.775C135.319 132.829 145.708 133.918 156.104 135.041C237.923 143.885 319.975 153.886 400.824 169.83C486.271 186.679 570.042 211.632 655.047 230.311C699.815 240.154 744.949 248.962 790.375 254.688C821.298 258.585 852.537 261.364 883.46 265.617C998.221 281.412 1112.34 302.928 1225.48 328.265"
        stroke="url(#paint4_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-324.839 233.271C-289.706 209.655 -252.142 189.661 -213.861 171.938C-174.399 153.665 -133.373 137.893 -90.7822 129.392C-20.217 115.305 52.7782 121.116 124.227 128.712C134.569 129.811 144.913 130.948 155.258 132.122C237.122 141.407 319.197 151.985 400.063 168.257C485.353 185.417 568.925 210.547 653.777 229.374C698.455 239.285 743.535 248.124 788.911 253.607C819.74 257.332 850.92 259.788 881.784 263.743C996.121 278.403 1109.96 298.599 1223.01 321.809"
        stroke="url(#paint5_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-324.337 227.291C-289.226 204.032 -251.797 184.232 -213.607 166.788C-174.185 148.799 -133.177 133.45 -90.6992 125.296C-20.3053 111.781 52.3878 117.772 123.535 125.679C133.823 126.819 144.138 128.008 154.422 129.224C236.321 138.937 318.4 150.083 399.294 166.684C484.404 184.151 567.791 209.447 652.49 228.404C697.087 238.388 742.103 247.254 787.435 252.493C818.168 256.048 849.299 258.183 880.059 261.832C993.964 275.347 1107.55 294.269 1220.49 315.317"
        stroke="url(#paint6_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-323.888 221.301C-288.772 198.383 -251.47 178.786 -213.351 161.671C-173.957 143.984 -132.958 129.077 -90.5519 121.265C-20.3428 108.322 52.0482 114.507 122.884 122.702C133.127 123.888 143.375 125.115 153.627 126.383C235.544 136.515 317.619 148.21 398.517 165.127C483.451 182.899 566.649 208.376 651.199 227.473C695.705 237.533 740.659 246.421 785.945 251.417C816.602 254.801 847.669 256.63 878.367 259.977C991.825 272.303 1105.18 289.963 1218.01 308.884"
        stroke="url(#paint7_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-323.4 215.345C-288.293 192.769 -251.108 173.384 -213.057 156.598C-173.667 139.217 -132.655 124.761 -90.3208 117.3C-20.2921 104.956 51.7834 111.308 122.313 119.787C132.506 121.012 142.722 122.287 152.902 123.594C234.828 134.131 316.881 146.367 397.775 163.622C482.532 181.696 565.536 207.321 649.933 226.58C694.359 236.712 739.253 245.637 784.49 250.385C815.048 253.593 846.075 255.125 876.668 258.139C989.703 269.262 1102.83 285.701 1215.5 302.46"
        stroke="url(#paint8_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-322.934 209.38C-287.837 187.129 -250.751 167.969 -212.749 151.526C-173.351 134.474 -132.315 120.485 -90.0448 113.372C-20.2144 101.623 51.5635 108.15 121.777 116.899C131.918 118.161 142.062 119.47 152.209 120.828C234.131 131.757 316.152 144.52 397.014 162.095C481.582 180.475 564.41 206.285 648.649 225.666C692.989 235.87 737.825 244.822 782.999 249.327C813.476 252.363 844.435 253.602 874.97 256.292C987.639 266.203 1100.49 281.448 1213 296.027"
        stroke="url(#paint9_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-322.471 203.419C-287.368 181.484 -250.4 162.567 -212.426 146.479C-172.995 129.765 -131.924 116.25 -89.6984 109.497C-20.0664 98.3604 51.3914 105.068 121.289 114.079C131.375 115.376 141.482 116.728 151.554 118.133C233.458 129.441 315.429 142.722 396.265 160.616C480.648 179.299 563.287 205.275 647.382 224.796C691.636 235.068 736.413 244.051 781.556 248.313C811.951 251.182 842.834 252.129 873.297 254.494C985.632 263.207 1098.2 277.249 1210.52 289.634"
        stroke="url(#paint10_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-322.013 197.463C-286.913 175.913 -250.153 157.186 -212.081 141.46C-172.614 125.098 -131.489 112.073 -89.2995 105.681C-19.8704 95.1613 51.2674 102.009 120.845 111.254C130.867 112.605 140.917 113.957 150.93 115.403C232.806 127.085 314.719 140.866 395.505 159.08C479.699 178.055 562.153 204.203 646.081 223.859C690.263 234.22 734.968 243.231 780.061 247.236C810.375 249.939 841.181 250.601 871.581 252.642C983.61 260.129 1095.87 273 1208.01 283.2"
        stroke="url(#paint11_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-321.562 191.516C-286.476 170.249 -249.733 151.842 -211.688 136.473C-172.158 120.48 -130.988 107.951 -88.8029 101.924C-19.5722 92.0349 51.2094 99.0223 120.472 108.506C130.435 109.857 140.426 111.29 150.371 112.754C232.207 124.787 314.026 139.064 394.771 157.589C478.771 176.866 561.04 203.18 644.81 222.971C688.893 233.387 733.553 242.424 778.596 246.204C808.839 248.736 839.568 249.114 869.918 250.822C981.627 257.129 1093.62 268.793 1205.53 276.785"
        stroke="url(#paint12_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-321.104 185.575C-286.028 164.59 -249.291 146.509 -211.262 131.514C-171.656 115.903 -130.395 103.879 -88.2463 98.2255C-19.4709 88.9945 51.4595 96.1261 120.145 105.79C130.041 107.182 139.982 108.637 149.855 110.132C231.633 122.508 313.379 137.262 394.029 156.085C477.84 175.659 559.938 202.145 643.527 222.066C687.529 232.554 732.131 241.623 777.124 245.155C807.29 247.525 837.947 247.615 868.234 248.998C979.641 254.088 1091.35 264.59 1203.04 270.365"
        stroke="url(#paint13_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-320.659 179.642C-285.577 158.948 -248.831 141.211 -210.803 126.612C-171.287 111.466 -129.72 99.3291 -87.6973 94.779C-19.2059 87.3636 51.4945 93.2743 119.869 103.145C129.701 104.564 139.561 106.046 149.381 107.573C231.086 120.278 312.733 135.505 393.284 154.638C476.901 174.506 558.796 201.162 642.241 221.219C686.158 231.775 730.7 240.87 775.64 244.164C805.738 246.367 836.323 246.168 866.547 247.232C977.656 251.133 1089.11 260.45 1200.54 264.004"
        stroke="url(#paint14_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-320.212 173.717C-285.106 153.312 -248.33 135.923 -210.279 121.737C-170.768 107.046 -129.376 95.3871 -87.3897 91.8011C-19.1373 85.976 51.6488 90.4766 119.667 100.536C129.427 101.983 139.224 103.492 148.971 105.042C230.573 118.061 312.121 133.735 392.573 153.179C475.978 173.339 557.697 200.162 640.985 220.35C684.82 230.977 729.304 240.105 774.194 243.154C804.22 245.191 834.732 244.709 864.893 245.448C975.714 248.151 1086.91 256.291 1198.06 257.611"
        stroke="url(#paint15_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-319.762 167.753C-284.633 147.643 -247.828 130.61 -209.758 116.845C-170.264 102.632 -129.288 91.7292 -87.3686 89.1207C-19.3732 84.8905 51.7872 87.6791 119.462 97.9147C129.156 99.3834 138.876 100.915 148.551 102.496C230.08 115.813 311.475 131.933 391.805 151.674C475.021 172.127 556.546 199.113 639.662 219.435C683.394 230.13 727.842 239.289 772.66 242.096C802.618 243.975 833.072 243.204 863.156 243.623C973.697 245.168 1084.65 252.102 1195.52 251.196"
        stroke="url(#paint16_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-319.319 161.382C-284.145 141.565 -247.281 124.905 -209.161 111.596C-169.704 97.8964 -129.44 88.0798 -87.6513 86.5345C-19.3178 84.0072 51.3601 86.2552 119.347 95.1303C128.954 96.3827 138.57 97.982 148.142 99.5813C229.572 113.169 310.827 129.725 391.035 149.759C474.048 170.483 555.393 197.63 638.343 218.088C682.024 228.86 726.405 238.064 771.205 240.614C801.1 242.33 831.473 241.28 861.512 241.384C971.787 241.758 1082.5 247.493 1193.06 244.357"
        stroke="url(#paint17_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-318.869 154.522C-283.621 135.007 -246.67 118.735 -208.473 105.907C-169.16 92.7794 -129.896 84.454 -88.3648 84.089C-19.7473 83.4854 50.7323 84.5666 119.048 92.4776C128.606 93.5813 138.335 94.7301 147.739 96.2258C228.254 108.921 310.167 127.023 390.227 147.345C473.014 168.366 554.188 195.622 636.966 216.215C680.585 227.067 724.934 236.325 769.702 238.673C799.543 240.24 829.848 238.916 859.829 238.704C969.834 237.92 1080.33 242.452 1190.57 237.073"
        stroke="url(#paint18_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-318.422 147.074C-283.085 127.869 -246.029 112.007 -207.737 99.6937C-168.749 87.2371 -130.72 80.9119 -89.6986 81.876C-56.58 82.6509 -23.2676 84.8359 9.9998 85.1603C46.3599 85.5117 82.0933 86.4082 118.237 89.8951C127.921 90.8322 137.677 91.8774 147.316 93.1028C228.39 103.406 309.933 123.864 389.34 144.317C471.947 165.594 552.949 192.94 635.547 213.695C679.116 224.643 723.46 233.968 768.197 236.104C797.993 237.527 828.235 235.937 858.166 235.41C967.941 233.482 1078.21 236.798 1188.09 229.179"
        stroke="url(#paint19_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-317.969 138.789C-282.231 119.841 -244.816 104.744 -206.734 92.3865C-169.274 80.2228 -131.223 77.5738 -91.833 80.0065C-58.9534 82.0338 -26.0106 84.6603 7.05383 84.7684C24.5283 84.827 42.0208 83.8674 59.4818 83.9935C78.5072 84.1602 97.776 86.1785 116.738 87.5301C126.594 88.2329 136.517 89.0302 146.331 89.9988C227.59 98.045 309.215 119.769 388.391 140.456C470.782 161.963 551.594 189.372 634.017 210.285C677.563 221.332 721.889 230.752 766.649 232.693C796.404 233.982 826.61 232.135 856.478 231.301C966.032 228.251 1076.09 230.328 1185.59 220.489"
        stroke="url(#paint20_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-317.491 130.482C-281.956 112.088 -244.086 95.8918 -205.949 85.1742C-169.278 74.871 -132.463 74.9206 -94.4795 78.795C-61.8839 82.1242 -29.3064 85.1832 3.55074 85.1021C21.8007 85.0661 40.0686 83.4127 58.2915 83.4803C77.0329 83.5569 95.95 85.0346 114.646 85.868C124.691 86.3185 134.794 86.8456 144.807 87.5439C226.589 93.2474 308.124 115.525 387.453 136.501C469.627 158.229 550.291 185.71 632.466 206.758C675.99 217.908 720.316 227.418 765.08 229.166C794.794 230.324 824.937 228.22 854.796 227.085C964.12 222.918 1074 223.747 1183.11 211.655"
        stroke="url(#paint21_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-316.999 122.187C-281.712 104.401 -243.751 87.5296 -205.642 78.632C-169.751 70.2525 -134.175 72.9871 -97.6209 78.2806C-65.3724 82.9524 -33.151 86.4213 -0.510358 86.2321C17.947 86.124 36.3321 84.1508 54.7534 83.912C73.8013 83.6597 93.007 84.6598 112.037 84.9932C122.284 85.1689 132.582 85.4122 142.798 85.8176C225.486 89.1064 306.668 111.253 386.552 132.621C468.488 154.538 548.926 182.065 630.907 203.266C674.403 214.528 718.73 224.115 763.498 225.67C793.181 226.697 823.27 224.341 853.075 222.899C962.178 217.637 1071.89 217.204 1180.58 202.874"
        stroke="url(#paint22_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-316.48 113.854C-281.288 96.6715 -243.896 79.5882 -205.737 72.6458C-170.616 66.2576 -136.307 71.6502 -101.142 78.3313C-69.3309 84.3726 -37.4386 88.2425 -4.9963 87.9812C13.6459 87.828 32.1212 85.6025 50.7228 85.0529C70.1088 84.4807 89.6121 84.9448 108.989 84.7466C119.444 84.6384 129.949 84.5799 140.381 84.679C183.472 85.0889 225.409 89.6932 267.558 99.289C307.148 108.299 346.54 118.104 385.736 128.703C467.428 150.805 547.65 178.372 629.392 199.708C672.862 211.056 717.188 220.751 761.961 222.107C791.617 223.008 821.661 220.4 851.421 218.661C960.33 212.304 1069.87 210.601 1178.12 194.04"
        stroke="url(#paint23_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-315.925 105.56C-280.76 88.999 -244.535 72.0734 -206.241 67.2665C-171.887 62.9325 -138.845 70.9742 -105.113 78.9797C-73.8387 86.3951 -42.2123 90.693 -9.98641 90.4002C8.82707 90.2245 27.3791 87.8052 46.1294 86.9493C65.8671 86.0482 85.6905 85.9356 105.428 85.1923C116.104 84.7868 126.816 84.4129 137.474 84.1967C180.948 83.2956 224.409 85.3815 267.027 95.1531C306.523 104.202 345.817 114.102 384.907 124.851C466.333 147.115 546.307 174.731 627.815 196.194C671.257 207.636 715.588 217.417 760.361 218.584C789.986 219.359 819.984 216.498 849.704 214.462C958.428 207.019 1067.82 204.032 1175.61 185.246"
        stroke="url(#paint24_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-315.323 97.2887C-280.956 81.1019 -245.263 64.5682 -207.122 62.3607C-173.309 60.4055 -141.751 70.7987 -109.421 80.0657C-78.7642 88.8642 -47.3723 93.6081 -15.3493 93.3198C3.58595 93.1486 22.2236 90.5852 41.1047 89.4319C61.2121 88.2065 81.36 87.4811 101.463 86.1747C112.364 85.4629 123.297 84.7691 134.185 84.224C178.367 82.012 222.937 82.3048 266.564 91.1528C306.094 99.1674 345.276 110.236 384.138 120.968C465.289 143.38 545.025 171.033 626.239 192.599C669.654 204.132 713.985 214.007 758.758 214.98C788.36 215.624 818.314 212.516 847.992 210.187C956.536 201.667 1065.8 197.379 1173.1 176.398"
        stroke="url(#paint25_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-314.549 88.5526C-281.327 72.8208 -245.489 57.553 -208.327 57.9585C-175.204 58.3144 -145.052 71.1044 -114.016 81.6148C-45.1549 104.933 26.0369 94.2561 97.1296 87.7327C108.261 86.71 119.419 85.6874 130.537 84.8044C176.04 81.2003 222.093 79.8758 267.344 87.8138C306.449 94.6706 345.217 106.487 383.434 117.147C464.288 139.695 543.757 167.374 624.678 189.061C668.067 200.689 712.393 210.65 757.166 211.434C786.745 211.947 816.677 208.591 846.292 205.969C954.674 196.378 1063.82 190.782 1170.6 167.568"
        stroke="url(#paint26_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-313.835 79.8804C-281.411 64.9325 -245.921 51.0072 -209.827 53.9806C-177.475 56.6837 -148.756 71.7938 -118.875 83.5431C-50.7711 110.312 21.4893 98.1441 92.4512 89.7871C103.812 88.4356 115.201 87.0841 126.557 85.8722C172.912 80.8851 219.944 77.8847 266.398 84.5567C305.752 90.2061 344.564 102.609 382.778 113.358C463.329 136.014 542.505 163.72 623.142 185.507C666.499 197.22 710.825 207.271 755.594 207.861C785.155 208.253 815.019 204.645 844.621 201.734C952.849 191.08 1061.88 184.165 1168.14 158.742"
        stroke="url(#paint27_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-313.207 71.3476C-281.522 57.2602 -246.483 44.8081 -211.615 50.3899C-180.056 55.4401 -152.772 72.8253 -123.972 85.782C-56.752 116.025 16.6895 102.388 87.4351 92.2874C99.0306 90.6295 110.649 88.9446 122.244 87.3813C168.969 81.0742 216.47 76.4159 263.636 81.4842C304.027 85.8226 343.205 98.5675 382.157 109.605C462.406 132.338 541.253 160.062 621.552 181.957C664.882 193.76 709.2 203.892 753.963 204.293C783.507 204.555 813.33 200.689 842.887 197.5C950.975 185.786 1059.92 177.524 1165.62 149.921"
        stroke="url(#paint28_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-312.664 62.9452C-280.943 49.484 -247.112 39.5187 -213.615 47.1278C-183.296 54.0161 -157.066 74.0953 -129.227 88.2729C-63.0124 121.998 11.669 106.938 82.162 95.1837C93.9875 93.2105 105.84 91.1967 117.666 89.2866C165.306 81.6279 213.762 75.2937 262.168 78.9654C303.294 82.0829 342.187 94.6476 381.604 105.897C461.497 128.693 540.029 156.458 620.003 178.429C663.283 190.313 707.614 200.504 752.369 200.734C781.899 200.873 811.677 196.774 841.184 193.282C949.147 180.524 1058.01 170.897 1163.13 141.122"
        stroke="url(#paint29_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-312.217 54.6743C-281.713 42.4249 -247.833 33.5003 -215.814 44.1504C-186.018 54.0616 -161.614 75.5284 -134.618 90.9538C-69.5125 128.166 6.41766 111.736 76.6447 98.4278C88.7002 96.1437 100.801 93.8056 112.843 91.553C160.897 82.6059 209.773 74.713 258.914 76.6862C301.491 78.4117 340.547 90.5619 381.127 102.221C460.66 125.071 538.844 152.84 618.453 174.866C661.734 186.831 706.024 197.121 750.765 197.139C780.282 197.139 810.019 192.805 839.499 189.03C947.349 175.222 1056.13 164.225 1160.66 132.288"
        stroke="url(#paint30_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-311.841 46.5248C-280.863 34.9151 -248.723 28.9054 -218.165 41.398C-189.591 53.0662 -166.351 77.0468 -140.085 93.7653C-76.1694 134.446 0.96454 116.741 70.9301 101.969C83.2155 99.3786 95.5233 96.7116 107.809 94.1347C156.806 83.8451 206.637 74.3483 256.969 74.8574C300.492 75.3079 339.359 86.6788 380.683 98.6218C459.841 121.49 537.651 149.268 616.877 171.352C660.104 183.399 704.403 193.765 749.131 193.594C778.638 193.477 808.33 188.886 837.77 184.827C945.52 169.96 1054.24 157.594 1158.15 123.504"
        stroke="url(#paint31_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-311.54 38.4875C-280.784 27.8464 -249.586 24.0712 -220.628 38.8209C-192.983 52.9038 -171.216 78.5784 -145.591 96.6348C-82.9468 140.785 -4.69477 121.863 65.0499 105.758C77.5562 102.87 90.0805 99.901 102.587 96.9817C153.081 85.2685 204.39 74.0598 256.485 73.2849C299.314 72.6407 339.994 80.9977 380.344 94.8148C417.912 107.681 456.445 118.079 494.23 130.374C534.413 143.438 574.705 156.436 615.316 167.838C658.506 179.966 702.779 190.4 747.506 190.039C777 189.8 806.656 184.962 836.051 180.619C943.711 164.721 1052.38 150.935 1155.65 114.718"
        stroke="url(#paint32_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-311.298 30.5546C-280.641 20.9092 -250.435 19.5306 -223.168 36.3662C-196.483 52.8323 -176.141 80.0657 -151.083 99.5097C-89.7694 147.066 -10.5211 127.117 59.0433 109.745C71.7659 106.565 84.5021 103.28 97.2248 100.059C149.206 86.8909 202.022 73.9883 255.87 71.8619C298.47 70.177 339.726 77.0338 380.018 90.6572C457.237 116.782 535.467 142.205 613.768 164.343C656.918 176.542 701.19 187.048 745.864 186.494C775.353 186.129 804.964 181.048 834.318 176.425C941.92 159.482 1050.53 144.263 1153.16 105.97"
        stroke="url(#paint33_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-311.119 22.7157C-280.155 14.0659 -251.468 15.1876 -225.748 33.9784C-200.028 52.7692 -181.065 81.4531 -156.526 102.321C-96.6324 153.273 -16.5049 132.415 52.9377 113.876C65.8677 110.425 78.8113 106.844 91.7368 103.321C144.174 89.0082 197.346 74.7181 251.784 70.8347C295.385 67.7262 338.043 73.1278 379.579 86.6386C456.934 111.8 534.086 138.627 612.212 160.86C655.316 173.132 699.575 183.705 744.244 182.935C773.724 182.444 803.304 177.119 832.608 172.217C940.129 154.233 1048.69 137.591 1150.66 97.167"
        stroke="url(#paint34_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.98 14.9667C-279.873 7.42063 -252.371 11.1419 -228.324 31.613C-203.64 52.6113 -185.945 82.6964 -161.875 105.019C-103.388 159.274 -22.6243 137.744 46.7732 118.12C59.9016 114.412 73.0391 110.556 86.1585 106.736C182.043 78.8355 280.501 52.1608 379.082 82.791C456.522 106.853 532.817 135.104 610.677 157.413C653.737 169.753 697.978 180.39 742.62 179.452C772.095 178.831 801.625 173.262 830.894 168.082C938.103 149.106 1047.35 131.703 1147.98 88.1296"
        stroke="url(#paint35_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.879 7.26245C-279.536 0.851697 -253.271 7.26245 -230.855 29.1798C-207.208 52.318 -190.654 83.6915 -167.075 107.505C-110.12 165.013 -28.8568 143.015 40.5858 122.395C53.8991 118.439 67.2213 114.322 80.5166 110.231C177.059 80.5244 278.029 49.4798 378.467 79.0693C416.238 90.2014 453.445 104.167 490.856 116.525C530.129 129.504 569.469 142.519 609.138 153.962C652.175 166.365 696.357 177.064 740.99 175.933C770.461 175.186 799.946 169.374 829.174 163.914C937.19 143.74 1044.03 122.778 1145.81 79.9253"
        stroke="url(#paint36_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.814 -0.351579C-279.187 -5.56848 -254.152 3.5093 -233.315 26.679C-210.674 51.8175 -195.174 84.4479 -172.086 109.753C-151.609 132.193 -124.482 146.244 -93.8298 149.398C-50.1797 153.903 -6.61965 139.379 34.4111 126.67C47.9002 122.494 61.3938 118.11 74.8558 113.785C172.034 82.4026 275.519 47.0421 377.779 75.4918C415.126 85.8806 451.648 100.401 488.459 112.609C528.025 125.737 567.649 138.951 607.616 150.547C650.581 163.012 694.776 173.771 739.351 172.446C768.822 171.568 798.266 165.513 827.449 159.778C935.578 138.527 1041.99 115.123 1144.17 72.6672"
        stroke="url(#paint37_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.732 -7.97363C-278.804 -11.9471 -255 -0.23386 -235.623 23.9765C-213.983 51.0432 -199.479 84.881 -176.865 111.691C-157.281 134.906 -130.807 150.146 -100.164 153.89C-56.401 159.233 -12.7012 144.078 28.28 130.914C41.9268 126.535 55.5782 121.949 69.189 117.367C167.102 84.399 272.864 44.9523 376.994 72.0505C414.865 81.9032 451.554 97.216 488.658 109.569C527.66 122.557 566.707 135.64 606.097 147.137C649.012 159.661 693.181 170.473 737.715 168.955C767.186 167.946 796.59 161.652 825.727 155.638C933.478 133.41 1041.06 108.682 1143.04 66.266"
        stroke="url(#paint38_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.672 -15.547C-278.455 -18.25 -255.8 -3.99588 -237.785 21.093C-217.046 49.9257 -203.462 84.9663 -181.371 113.267C-162.688 137.198 -136.896 153.665 -106.266 158.062C-62.4852 164.342 -18.6276 148.601 22.2228 135.086C36.014 130.531 49.8096 125.742 63.5512 120.949C162.235 86.5026 270.161 43.1185 376.135 68.766C414.114 77.9564 450.569 93.7964 487.592 106.158C526.454 119.138 565.348 132.252 604.593 143.771C647.45 156.354 691.605 167.198 736.085 165.5C765.551 164.364 794.915 157.841 823.998 151.534C879.131 139.622 934.282 127.832 988.441 111.916C1039.92 96.7878 1092.93 81.5876 1142.38 60.702"
        stroke="url(#paint39_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.59 -23.1153C-278.107 -24.5344 -256.535 -7.89256 -239.736 17.9352C-219.899 48.4437 -207.109 84.6332 -185.559 114.412C-167.765 139.01 -142.702 156.76 -112.073 161.86C-68.3728 169.153 -24.398 152.908 16.2766 139.149C30.1985 134.437 44.1204 129.472 57.9747 124.485C157.484 88.6698 267.434 41.5284 375.216 65.6127C413.79 74.2355 450.42 90.7061 487.813 103.239C526.107 116.079 564.455 129.054 603.101 140.469C645.904 153.084 690.014 164.013 734.457 162.094C763.924 160.828 793.247 154.048 822.29 147.488C877.36 135.05 932.416 122.607 986.395 105.938C1038.24 89.9313 1092.31 77.5918 1142.16 55.9313"
        stroke="url(#paint40_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.482 -30.6927C-277.752 -30.8549 -257.175 -12.01 -241.441 14.4619C-222.384 46.5157 -210.387 83.8405 -189.387 115.079C-172.436 140.307 -148.176 159.341 -117.591 165.234C-74.0809 173.614 -29.9934 156.963 10.4468 143.024C24.4814 138.19 38.5115 133.072 52.4649 127.918C152.894 90.8279 264.598 40.2041 374.192 62.5945C412.608 70.4424 448.558 87.0662 485.581 99.5093C524.127 112.466 562.674 125.616 601.609 137.167C644.348 149.854 688.422 160.806 732.802 158.702C762.269 157.305 791.551 150.277 820.545 143.448C875.547 130.482 930.518 117.386 984.33 99.9689C1036.8 82.9891 1091.8 74.7133 1142.33 51.8409"
        stroke="url(#paint41_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.34 -38.3022C-277.379 -37.2661 -257.673 -16.43 -242.868 10.6051C-224.523 44.1005 -213.297 82.8399 -192.825 115.2C-176.594 140.852 -153.548 161.332 -122.751 168.13C-79.5334 177.663 -35.3513 160.724 4.77331 146.695C18.8981 141.762 33.0183 136.518 47.0484 131.225C106.708 108.726 166.173 84.2951 228.209 68.748C275.511 56.8906 324.76 50.7276 373.122 59.6792C411.443 66.7793 446.726 83.4617 483.424 95.8372C522.196 108.902 560.968 122.233 600.132 133.91C642.809 146.646 686.842 157.634 731.182 155.332C760.653 153.804 789.895 146.533 818.834 139.429C846.484 132.644 874.152 125.913 901.621 118.448C928.766 111.051 955.361 101.721 982.479 94.3415C1036.29 79.7044 1092.1 72.6314 1142.87 48.3715"
        stroke="url(#paint42_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-310.141 -45.9596C-276.959 -43.8242 -258.001 -21.2446 -243.967 6.32209C-226.231 41.16 -215.699 80.9626 -195.817 114.733C-180.394 140.934 -158.222 162.775 -127.506 170.501C-84.7395 181.26 -40.4943 164.154 -0.671733 150.125C13.5252 145.124 27.7311 139.763 41.8018 134.357C102.769 110.962 163.429 85.301 226.749 68.5285C273.979 56.0224 323.35 48.8142 371.978 56.8874C410.98 63.3612 446.483 80.3409 483.632 92.9282C521.859 105.894 560.076 119.085 598.668 130.658C641.281 143.435 685.274 154.459 729.555 151.959C759.027 150.296 788.232 142.782 817.117 135.411C844.271 128.478 871.443 121.581 898.408 113.926C926.085 106.065 953.257 96.298 981.322 89.9098C1036.19 77.4172 1092.91 71.2812 1143.7 45.4085"
        stroke="url(#paint43_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-309.872 -53.686C-276.456 -50.5865 -258.103 -26.5113 -244.713 1.55551C-227.495 37.6369 -217.581 78.4891 -198.344 113.629C-183.719 140.344 -162.444 163.563 -131.832 172.294C-89.6333 184.359 -45.3475 167.24 -5.92168 153.274C8.32481 148.237 22.5713 142.826 36.6915 137.339C98.3662 113.363 159.586 86.8236 223.492 68.8617C271.218 55.4771 321.283 46.9534 370.776 54.2382C410.152 60.0227 445.313 77.1782 482.647 89.7204C520.779 102.533 558.771 115.913 597.219 127.5C639.76 140.322 683.712 151.377 727.926 148.674C757.406 146.872 786.571 139.119 815.388 131.478C842.502 124.27 869.624 117.098 896.539 109.124C924.568 100.816 951.907 91.8468 980.715 86.4767C1036.51 76.0655 1094.18 70.7404 1144.8 42.9665"
        stroke="url(#paint44_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-309.11 -61.2601C-275.486 -57.2055 -257.678 -31.7517 -244.852 -3.21643C-228.108 34.0317 -218.762 75.8616 -200.115 112.29C-186.198 139.478 -165.748 163.959 -135.258 173.622C-93.6317 186.818 -49.2152 169.68 -10.1726 155.859C4.11449 150.799 18.3971 145.339 32.5354 139.784C94.2597 115.552 155.38 88.5345 219.124 69.559C267.814 55.0616 319.21 45.0558 370.105 51.6692C410.176 56.8726 445.508 74.3209 483.342 87.0027C520.965 99.617 558.344 112.988 596.3 124.449C638.809 137.28 682.671 148.371 726.813 145.479C756.298 143.546 785.422 135.545 814.199 127.634C869.652 112.403 924.068 92.4585 981.072 83.9348C1037.61 75.4742 1096.3 70.7124 1146.44 40.7084"
        stroke="url(#paint45_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-305.922 -67.4363C-272.533 -61.7328 -255.513 -34.058 -243.485 -5.2659C-227.408 33.1356 -218.504 75.7853 -199.979 113.101C-186.35 140.582 -166.112 165.5 -135.478 175.271C-93.9601 188.507 -48.5246 170.496 -9.69388 156.562C4.66983 151.408 19.0245 145.844 33.2304 140.19C95.365 115.457 156.846 87.868 220.996 68.2889C269.871 53.3725 321.533 42.79 372.797 49.1693C412.872 54.1609 448.087 71.6722 485.868 84.4037C523.368 97.045 560.589 110.533 598.437 121.99C640.861 134.843 684.656 145.912 728.73 142.812C758.223 140.74 787.316 132.5 816.039 124.341C843.95 116.408 871.853 108.357 899.476 99.4237C927.292 90.4135 955.677 85.8003 984.639 82.2142C1041.7 75.1367 1101.09 70.2577 1150.23 37.4921"
        stroke="url(#paint46_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-302.699 -73.6129C-269.373 -66.2561 -253.274 -36.9459 -241.876 -7.64022C-226.516 31.865 -217.757 75.159 -199.38 113.299C-186.04 141.015 -166.005 166.392 -135.231 176.249C-93.9113 189.507 -47.4344 170.766 -8.87869 156.796C5.54813 151.575 19.9524 145.93 34.2124 140.19C96.117 115.3 157.327 87.5211 221.175 67.4418C270.767 51.8452 323.317 40.4112 375.506 46.6823C415.18 51.4487 449.926 68.7844 487.273 81.4347C525.036 94.2292 562.473 107.965 600.592 119.561C642.93 132.441 686.661 143.483 730.631 140.181C760.134 137.965 789.195 129.491 817.864 121.066C845.748 112.876 873.34 103.699 901.17 95.333C929.442 86.8318 959.197 83.9846 988.502 81.0203C1046.07 75.1636 1106.06 69.9017 1153.96 34.1402"
        stroke="url(#paint47_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-299.429 -79.8485C-266.383 -71.0546 -251.843 -38.9422 -240.424 -9.76719C-224.816 30.1165 -216.078 74.2845 -198.302 112.817C-185.444 140.681 -165.391 166.589 -134.468 176.577C-93.4144 189.849 -45.9637 170.563 -7.7281 156.624C6.73479 151.349 21.1887 145.645 35.4892 139.847C97.7049 114.619 159.163 86.4167 223.281 65.887C272.995 49.9705 325.784 38.068 378.235 44.1814C417.859 48.7992 452.56 66.1573 489.785 78.8707C527.426 91.7418 564.728 105.554 602.743 117.164C644.996 130.067 688.664 141.086 732.544 137.577C762.051 135.22 791.081 126.512 819.695 117.826C847.034 109.532 874.166 99.1707 901.879 92.2238C931.512 84.7949 962.25 82.7947 992.596 80.3034C1050.68 75.5325 1111.17 69.5317 1157.47 30.5761"
        stroke="url(#paint48_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-296.197 -85.9932C-265.089 -73.8294 -249.972 -41.4332 -238.489 -12.56C-222.575 27.4903 -212.733 70.8834 -195.926 110.515C-183.595 139.622 -164.84 165.815 -133.182 176.145C-92.4222 189.435 -44.1644 169.838 -6.24444 155.971C8.2455 150.673 22.7264 144.934 37.036 139.095C99.5222 113.628 161.215 85.088 225.531 64.1799C275.345 47.993 328.323 35.6761 380.999 41.6769C420.524 46.182 455.229 63.5582 492.311 76.3662C529.82 89.3229 567.005 103.208 604.916 114.831C647.079 127.76 690.679 138.753 734.469 135.041C763.99 132.54 792.979 123.593 821.535 114.651C848.437 106.222 875.257 95.3597 902.902 89.6292C933.798 83.232 965.569 82.1237 996.943 80.0289C1055.62 76.114 1116.34 69.095 1160.84 26.7606"
        stroke="url(#paint49_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-293.828 -90.8629C-263.36 -77.0998 -247.937 -45.1046 -236.089 -15.9431C-219.809 24.1162 -209.625 67.5274 -192.894 107.352C-180.465 136.946 -163.68 164.234 -131.278 174.929C-90.7974 188.273 -42.0122 168.622 -4.38071 154.868C10.1228 149.556 24.6082 143.812 38.9403 137.956C102.188 112.092 164.584 82.9934 229.749 61.7338C279.079 45.6506 331.606 33.3067 383.796 39.1723C422.847 43.5558 457.147 60.7653 493.741 73.5282C531.495 86.6921 568.919 100.798 607.1 112.551C649.172 125.504 692.705 136.469 736.401 132.554C765.931 129.91 794.883 120.724 823.376 111.515C850.485 102.757 877.301 91.8685 905.546 87.2868C937.236 82.1465 969.498 81.8807 1001.48 80.1327C1060.91 76.9026 1121.57 68.46 1163.98 22.6207"
        stroke="url(#paint50_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-291.361 -95.8585C-261.372 -80.8565 -245.493 -49.3839 -233.203 -20.0061C-216.482 19.9946 -205.955 63.3968 -189.292 103.38C-177.15 132.505 -160.763 162.135 -128.762 172.826C-88.5157 186.269 -39.5862 166.874 -2.16667 153.251C12.3278 147.976 26.8177 142.232 41.1138 136.375C104.511 110.444 167.001 81.1379 232.269 59.6666C281.641 43.4212 334.298 30.7889 386.609 36.623C425.512 40.9659 459.857 58.2024 496.271 71.0645C533.88 84.3546 571.214 98.4916 609.287 110.259C651.269 123.238 694.735 134.176 738.335 130.059C767.874 127.266 796.786 117.845 825.216 108.376C853.213 99.0457 880.372 88.72 909.902 85.2601C941.844 81.5164 974.106 81.9354 1006.19 80.5073C1066.5 77.8267 1126.84 67.4425 1166.87 18.044"
        stroke="url(#paint51_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M-288.765 -100.973C-260.182 -87.4167 -243.383 -55.3133 -231.233 -27.7782C-214.764 9.52856 -204.273 49.0563 -189.756 87.1019C-176.871 120.89 -159.099 160.576 -121.139 171.208C-98.4118 177.569 -73.2821 172.73 -50.907 167.239C-18.4962 159.287 12.8506 146.966 43.6158 134.437C107.085 108.591 169.643 79.1144 234.942 57.4854C284.336 41.1274 337.093 28.2608 389.472 34.1175C428.212 38.4514 462.611 55.7419 498.832 68.7031C536.292 82.1238 573.545 96.2743 611.51 108.06C653.397 121.061 696.795 131.977 740.301 127.652C769.844 124.715 798.72 115.038 827.082 105.316C855.12 95.6887 881.972 86.5929 911.736 84.0836C944.76 81.2949 977.96 82.4662 1011.05 81.1868C1072.57 78.8081 1132.04 66.1037 1169.5 13.0652"
        stroke="url(#paint52_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1170.83 9.9613C1099.52 119.097 958.487 63.189 854.086 92.0847C844.993 94.6031 835.999 97.7161 827.036 100.838C798.701 110.704 769.847 120.476 740.276 123.643C696.996 128.288 653.589 118.075 611.743 105.663C590.201 99.2749 568.818 92.1207 547.53 84.8044C518.735 74.8932 489.29 65.9506 461.212 54.1698C430.555 41.2987 398.785 33.7346 365.896 33.4463C320.875 33.0634 275.918 44.5874 233.07 58.81C169.614 79.8849 108.35 108.47 46.4499 133.928C16.1896 146.376 -14.4899 158.553 -46.1703 167.189C-68.6716 173.325 -93.9186 179.227 -117.317 174.956C-158.375 167.459 -177.743 129.563 -191.34 94.3462C-206.146 56.0529 -216.339 15.4168 -232.24 -22.7864C-244.142 -51.4073 -260.192 -83.853 -288.96 -98.8235"
        stroke="url(#paint53_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1172.05 6.6719C1157.29 31.0985 1137.87 51.4391 1112.07 64.229C1087.94 76.1946 1060.99 81.0286 1034.33 82.9342C974.479 87.2185 913.372 75.6269 854.818 88.8854C845.499 90.9938 835.95 93.4176 826.987 96.3684C798.995 105.59 769.816 116.249 740.264 119.655C697.164 124.611 653.852 115.069 611.987 103.288C590.491 97.2379 569.135 90.3991 547.878 83.4658C519.123 74.0636 489.679 65.603 461.637 54.2277C431.052 41.8161 399.39 34.5134 366.731 34.2295C322.17 33.8511 277.659 44.9517 235.19 58.8364C171.964 79.5012 110.952 107.753 49.4446 133.243C18.3593 146.123 -13.1138 158.786 -45.5831 167.981C-67.7418 174.234 -93.2187 180.753 -116.437 177.352C-158.338 171.221 -179.009 135.982 -193.057 100.198C-208.205 61.6115 -218.033 20.4574 -233.362 -18.4216C-244.881 -47.6552 -260.322 -80.9254 -289.176 -96.7338"
        stroke="url(#paint54_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1173.16 3.19891C1159.12 28.3239 1140.52 49.9889 1115 63.9547C1091.46 76.8257 1064.74 82.34 1038.2 84.4709C977.446 89.3454 915.78 75.6949 855.972 86.372C846.414 88.0795 836.739 90.1653 827.366 92.6476C798.346 100.306 770.11 112.02 740.282 115.682C697.38 120.962 654.145 112.078 612.267 100.928C590.816 95.2155 569.478 88.7101 548.248 82.1147C519.538 73.1946 490.089 65.2341 462.079 54.2642C431.557 42.3121 400.007 35.2751 367.574 34.9958C323.482 34.6174 279.422 45.29 237.331 58.8143C174.394 79.0422 113.662 106.893 52.5644 132.333C21.2492 145.366 -10.3861 158.228 -42.9772 167.869C-65.447 174.523 -91.3882 181.785 -115.111 179.132C-157.134 174.406 -180.059 141.51 -194.459 105.649C-210.017 66.8829 -219.503 25.2377 -234.34 -14.1864C-245.525 -43.9201 -260.462 -78.0822 -289.342 -94.6069"
        stroke="url(#paint55_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1174.14 -0.47752C1160.78 25.3592 1143.03 48.3532 1117.83 63.5715C1094.94 77.3886 1068.52 83.6867 1042.09 86.124C980.442 91.8229 918.37 76.384 857.444 84.5022C847.643 85.8086 837.711 87.4845 828.063 89.5749C798.704 95.9271 770.031 107.843 740.262 111.731C697.532 117.313 654.391 109.095 612.495 98.585C591.093 93.215 569.769 87.0475 548.575 80.8034C519.91 72.3609 490.448 64.9004 462.465 54.336C432.015 42.8434 400.569 36.0633 368.361 35.7975C324.747 35.437 281.151 45.6636 239.421 58.814C176.877 78.5418 116.469 105.969 55.8041 131.269C24.9352 144.15 -6.19973 156.872 -38.1957 166.86C-61.7114 174.158 -88.4056 182.24 -113.346 180.267C-154.616 177.005 -180.922 146.028 -195.538 110.645C-211.579 71.7887 -220.785 29.7065 -235.171 -10.1861C-246.073 -40.4108 -260.54 -75.3614 -289.511 -92.5574"
        stroke="url(#paint56_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1174.98 -4.35091C1162.29 22.2021 1145.39 46.5027 1120.6 63.0634C1098.43 77.8491 1072.36 85.0348 1046.09 87.873C983.628 94.6306 921.205 77.6419 859.327 83.2372C849.283 84.1382 839.094 85.4132 829.175 87.1026C799.366 92.1888 770.115 103.681 740.319 107.826C697.783 113.705 654.556 106.263 612.804 96.2885C591.525 91.2022 570.146 85.4492 548.975 79.543C520.355 71.56 490.88 64.6041 462.932 54.4406C432.559 43.3896 401.221 36.9112 369.216 36.6409C326.094 36.2805 282.953 46.0791 241.629 58.8375C179.414 78.0383 119.384 104.947 59.193 130.059C28.1979 142.988 -3.02264 155.796 -35.0322 166.059C-58.8725 173.695 -85.8597 182.309 -111.224 180.998C-151.042 178.939 -181.406 149.913 -196.252 115.223C-212.874 76.3939 -221.778 33.9243 -235.826 -6.32427C-246.556 -37.013 -260.609 -72.6799 -289.625 -90.493"
        stroke="url(#paint57_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1175.69 -8.45953C1163.63 18.8324 1147.61 44.4393 1123.25 62.3877C1101.86 78.1555 1076.23 86.3278 1050.07 89.657C986.927 97.7121 924.125 79.3899 861.499 82.5435C851.211 83.057 840.761 83.895 830.572 85.206C800.217 89.0578 770.403 99.5187 740.359 103.916C698.025 110.115 654.898 103.321 613.006 93.9233C591.727 89.1479 570.461 83.8454 549.348 78.2861C520.769 70.7581 491.28 64.3203 463.355 54.5397C433.05 43.9482 401.825 37.7447 370.031 37.4834C327.391 37.1365 284.719 46.4936 243.797 58.8376C182.032 77.4707 122.391 103.825 62.7085 128.667C31.6007 141.601 0.357608 154.454 -31.6835 164.951C-55.8304 172.857 -83.0475 181.908 -108.777 181.21C-149.497 180.101 -179.058 152.571 -196.118 118.508C-214.94 80.926 -222.744 37.5509 -236.382 -2.67961C-246.918 -33.7648 -260.633 -70.0399 -289.739 -88.4252"
        stroke="url(#paint58_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1176.26 -12.7401C1164.8 15.3042 1149.63 42.1771 1125.79 61.5941C1105.23 78.326 1080.1 87.602 1054.05 91.5079C990.397 101.054 927.144 81.5967 863.964 82.3626C853.432 82.4887 842.743 82.9347 832.274 83.8357C801.32 86.4982 770.847 95.3553 740.416 100.054C698.199 106.573 655.207 100.338 613.248 91.6566C592.018 87.2641 570.793 82.304 549.729 77.0736C521.191 69.987 491.688 64.0358 463.777 54.6787C433.544 44.5422 402.431 38.618 370.841 38.3657C328.697 38.0369 286.494 46.9254 245.959 58.8639C184.645 76.8843 125.473 102.635 66.3271 127.13C35.1877 140.028 3.91305 152.876 -28.0965 163.54C-52.5364 171.681 -79.9203 181.078 -105.979 180.948C-146.266 180.74 -177.694 155.237 -195.497 121.147C-215.14 83.5473 -223.436 41.0958 -236.803 0.775123C-247.244 -30.675 -260.67 -67.4771 -289.849 -86.3986"
        stroke="url(#paint59_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1176.69 -17.2578C1165.78 11.5748 1151.44 39.6731 1128.21 60.5633C1108.51 78.2638 1083.95 88.7291 1058.01 93.2973C994.051 104.56 930.167 84.1294 866.703 82.6427C855.928 82.3904 844.99 82.4355 834.242 82.9401C802.711 84.4132 771.747 91.4997 740.468 96.2121C697.904 102.659 655.471 97.46 613.503 89.4544C592.314 85.3998 571.124 80.7866 550.093 75.876C521.595 69.222 492.074 63.7753 464.176 54.8192C434.015 45.1422 403.02 39.5019 371.633 39.2586C329.98 38.9432 288.255 47.3903 248.103 58.8783C187.326 76.268 128.631 101.348 70.0219 125.437C38.9051 138.258 7.65296 151.057 -24.298 161.833C-48.9994 170.168 -76.5005 179.827 -102.848 180.219C-143.076 180.813 -175.757 157.152 -194.553 123.328C-215.079 86.3864 -223.907 44.2412 -237.035 4.05117C-247.404 -27.7413 -260.654 -64.9895 -289.914 -84.3434"
        stroke="url(#paint60_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1176.95 -21.9756C1157.19 34.4012 1123.4 82.3896 1061.9 95.0624C997.847 108.267 933.183 86.9532 869.656 83.3851C858.642 82.7634 847.465 82.4166 836.46 82.5202C804.343 82.831 772.568 88.8048 740.82 92.9945C698.306 98.6034 656.062 94.7966 613.823 87.3902C592.67 83.678 571.503 79.3621 550.494 74.7759C522.032 68.5498 492.498 63.6032 464.614 55.057C434.525 45.8306 403.638 40.456 372.449 40.2398C331.301 39.956 290.063 47.939 250.272 58.972C190.098 75.6498 131.908 100.022 73.9081 123.643C42.7778 136.324 11.5798 149.029 -20.2674 159.859C-45.2168 168.338 -72.7721 178.181 -99.3715 179.051C-139.523 180.366 -173.422 158.566 -193.263 125.08C-214.646 88.9939 -224.411 47.1731 -237.175 7.20834C-247.445 -24.94 -260.618 -62.5531 -289.954 -82.308"
        stroke="url(#paint61_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1177.13 -26.1605C1158.47 30.4776 1126.79 82.0294 1065.19 96.4818C1001.21 111.488 935.747 89.4854 872.305 84.282C861.093 83.3584 849.714 82.6917 838.492 82.4799C805.892 81.8357 773.572 86.7237 741.264 90.6477C698.674 95.815 656.507 92.9002 614.169 86.066C593.042 82.6691 571.894 78.6551 550.898 74.3527C522.468 68.5456 492.952 63.991 465.049 55.9809C434.951 47.3356 404.457 42.0151 373.421 41.7042C332.904 41.2943 292.234 48.6601 252.961 59.0894C193.451 74.8888 135.915 98.3423 78.6493 121.332C48.1997 133.554 17.7185 145.844 -13.3217 156.53C-39.0465 165.383 -67.4223 175.902 -94.8648 177.177C-134.985 179.065 -170.119 159.72 -191.34 126.652C-213.719 91.7243 -224.251 50.4891 -237.325 11.1326C-248.051 -21.1104 -260.579 -59.2731 -290.208 -79.1902"
        stroke="url(#paint62_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1177.4 -27.209C1158.14 29.384 1128.21 81.7153 1066.16 96.8839C1002.14 112.53 936.318 90.1263 872.854 84.5264C861.583 83.5263 850.136 82.7829 838.847 82.4946C806.134 81.6612 773.755 86.4185 741.331 90.5497C698.848 95.9558 656.799 93.7033 614.433 87.5088C593.348 84.4228 572.23 80.7511 551.262 76.7866C522.859 71.4301 493.379 67.3665 465.472 59.7934C435.626 51.6842 405.578 46.1294 374.903 45.314C335.491 44.2508 295.808 50.3327 257.378 59.5681C199.099 73.597 142.658 95.4287 86.646 117.233C56.3632 129.019 26.1119 140.953 -4.63074 151.504C-30.7388 160.482 -59.0289 170.948 -86.7464 173.043C-126.695 176.061 -163.894 160.212 -187.315 128.176C-211.318 95.3791 -223.193 55.3603 -236.97 17.2201C-248.561 -14.8697 -261.609 -52.289 -290.818 -72.7377"
        stroke="url(#paint63_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1177.67 -28.2596C1157.63 28.1173 1129.59 81.4261 1067.16 97.3066C1003.14 113.601 936.913 90.8237 873.425 84.7869C862.091 83.7102 850.581 82.8902 839.225 82.5343C806.395 81.5027 773.943 86.1114 741.393 90.4453C699.014 96.0857 657.086 94.5359 614.707 88.9631C593.658 86.197 572.577 82.8452 551.631 79.2231C523.228 74.3125 493.811 70.6859 465.877 63.5904C436.266 56.0668 406.596 50.3048 376.277 49.0254C337.956 47.4035 299.256 52.1789 261.656 60.234C204.711 72.3978 149.442 92.495 94.7647 112.944C64.7659 124.161 34.8258 135.618 4.47534 145.889C-21.8987 154.814 -49.9995 164.987 -77.8883 167.771C-117.76 171.722 -156.785 159.152 -182.483 128.428C-208 97.9237 -221.413 59.324 -236.056 22.4452C-248.679 -9.33376 -262.407 -45.8116 -291.288 -66.526"
        stroke="url(#paint64_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1177.95 -29.328C1166.68 0.725489 1155.91 30.6529 1134.88 55.5526C1117.55 76.1734 1094.23 90.9113 1068.16 97.7338C1004.11 114.7 937.516 91.5393 874.02 85.0744C862.618 83.9166 851.027 83.0111 839.616 82.5786C806.665 81.3352 774.132 85.7772 741.464 90.3094C699.203 96.166 657.365 95.328 615.004 90.404C594.018 87.9487 572.959 84.9438 552.045 81.6415C523.642 77.1725 494.292 73.9378 466.304 67.3378C436.905 60.409 407.56 54.5433 377.579 52.8314C340.309 50.7005 302.578 54.2415 265.798 61.0622C210.282 71.3744 156.254 89.566 102.96 108.523C73.3401 119.061 43.7877 129.932 13.9151 139.784C-12.6438 148.542 -40.4876 158.255 -68.3855 161.58C-108.28 166.301 -148.829 156.719 -176.857 127.562C-203.862 99.4772 -218.961 62.4723 -234.623 26.936C-248.419 -4.35187 -262.981 -39.7394 -291.614 -60.5125"
        stroke="url(#paint65_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1178.24 -30.4127C1166.55 -0.872755 1156.6 28.8158 1136.35 53.9183C1118.98 75.4391 1095.91 90.8331 1069.23 98.1899C1005.21 115.841 938.169 92.2927 874.673 85.3909C863.203 84.143 851.554 83.1383 840.062 82.6428C806.984 81.1786 774.361 85.4314 741.558 90.1528C699.405 96.2211 657.675 96.104 615.324 91.8376C594.364 89.7247 573.346 87.0532 552.459 84.0934C524.056 80.0658 494.751 77.169 466.709 71.0961C437.499 64.789 408.407 58.8828 378.755 56.7879C342.49 54.2335 305.688 56.5897 269.72 62.194C215.75 70.5735 163.034 86.7739 111.106 104.141C81.9413 113.899 52.8577 124.076 23.5217 133.393C-3.10485 141.849 -30.6285 150.963 -58.4092 154.688C-98.4707 160.031 -140.281 153.107 -170.605 125.756C-199.008 100.145 -215.937 64.9376 -232.775 30.7619C-248.104 -0.291724 -263.581 -33.2826 -291.835 -54.6953"
        stroke="url(#paint66_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1178.53 -31.5127C1166.55 -1.99979 1156.89 27.869 1136.91 53.2868C1119.78 75.0779 1096.96 90.9494 1070.29 98.6171C1006.29 117.016 938.832 93.0803 875.354 85.7325C863.817 84.3809 852.095 83.3132 840.527 82.7231C807.318 81.0292 774.592 85.0702 741.649 89.9718C699.599 96.2293 657.982 96.8465 615.658 93.256C594.739 91.481 573.757 89.1518 552.906 86.5254C524.557 82.9483 495.117 80.7093 467.125 74.8121C438.221 68.7077 409.102 63.2835 379.824 60.8688C344.515 57.954 308.619 59.2064 273.426 63.5719C221.039 70.0772 169.68 84.1782 119.15 99.8649C64.4629 116.84 8.87002 138.96 -48.0844 147.263C-88.4209 153.142 -131.255 148.515 -163.806 123.161C-193.489 100.041 -212.356 66.802 -230.53 34.0138C-247.211 3.85671 -263.779 -27.9808 -291.965 -49.0602"
        stroke="url(#paint67_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1178.83 -32.6342C1166.56 -3.16183 1157.15 26.8917 1137.48 52.6203C1120.62 74.6953 1098.05 91.0533 1071.38 99.0499C1007.43 118.237 939.523 93.914 876.076 86.1067C864.467 84.6786 852.673 83.4982 841.028 82.8225C807.666 80.8808 774.845 84.6921 741.745 89.7648C699.817 96.1891 658.29 97.5541 615.997 94.6528C595.128 93.2247 574.182 91.247 553.371 88.9494C524.968 85.7958 495.664 83.755 467.531 78.4705C438.718 73.0644 409.824 67.7394 380.767 65.0588C346.341 61.8827 311.307 62.126 276.863 65.2931C226.094 69.9603 176.127 81.8944 126.982 95.8331C72.7503 111.214 18.3566 130.842 -37.5744 139.533C-78.3166 145.84 -121.935 143.164 -156.596 119.944C-187.469 99.257 -208.338 68.1267 -227.954 36.7622C-246.141 7.6638 -263.801 -22.9709 -291.973 -43.5997"
        stroke="url(#paint68_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1179.13 -33.783C1166.57 -4.37371 1157.39 25.8825 1138.05 51.904C1121.45 74.2403 1099.16 91.1344 1072.5 99.4868C1008.63 119.498 940.249 94.797 876.834 86.5167C865.153 84.9894 853.277 83.7145 841.556 82.9441C808.058 80.7411 775.098 84.2956 741.835 89.5215C700.34 96.062 658.275 98.2409 616.326 96.0224C595.515 94.9457 574.605 93.3193 553.831 91.3551C525.351 88.652 496.196 86.7329 467.919 82.0926C439.178 77.3803 410.433 72.2625 381.57 69.3882C347.815 66.0412 313.848 65.3694 279.987 67.3789C230.846 70.2982 182.308 79.9932 134.52 92.157C80.9915 105.758 27.6708 122.994 -27.0519 131.685C-68.3126 138.235 -112.445 137.249 -149.098 116.219C-181.04 97.8874 -203.893 69.0503 -225.119 39.0869C-244.874 11.1958 -263.683 -18.1639 -291.933 -38.2972"
        stroke="url(#paint69_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1179.44 -34.9584C1166.37 -5.13461 1157.3 25.7478 1137.75 52.3414C1121.52 74.4164 1099.82 91.306 1073.63 99.8973C1009.85 120.819 941.039 95.7391 877.683 86.9676C865.921 85.3413 853.969 83.9582 842.161 83.0887C808.511 80.6109 775.437 83.8726 741.967 89.2382C700.161 95.9463 658.905 98.7439 616.634 97.2978C595.896 96.5905 575.04 95.3651 554.328 93.7342C525.772 91.4817 496.738 89.6391 468.317 85.6566C439.63 81.638 410.98 76.8356 382.261 73.8397C349.233 70.3547 316.012 69.0273 282.811 69.8662C235.284 71.1456 188.184 78.6106 141.699 88.9273C89.0767 100.618 36.8291 115.431 -16.5908 123.932C-58.4781 130.599 -102.944 130.991 -141.405 112.178C-174.317 96.0813 -199.185 69.5688 -222.105 41.0606C-243.412 14.5165 -263.438 -13.5908 -291.823 -33.1429"
        stroke="url(#paint70_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1179.77 -36.1754C1166.38 -6.44175 1157.52 24.6434 1138.35 51.5344C1122.39 73.9067 1100.99 91.3504 1074.84 100.334C1011.19 122.197 941.862 96.7295 878.596 87.4715C866.752 85.7371 854.701 84.2414 842.822 83.2638C809.009 80.4886 775.746 83.4305 742.104 88.9132C700.361 95.7159 659.186 99.2749 616.965 98.4955C596.294 98.1171 575.488 97.3377 554.867 96.0672C497.335 92.5307 440.124 84.4621 382.849 78.4072C350.524 74.9879 317.789 72.9335 285.315 72.7668C239.375 72.5326 193.691 77.7675 148.504 86.2461C96.9009 95.932 45.6451 108.321 -6.37716 116.43C-48.9002 123.089 -93.5738 124.58 -133.631 107.961C-167.444 93.9363 -194.264 69.7709 -218.907 42.7538C-241.787 17.6694 -263.089 -9.18998 -291.654 -28.1384"
        stroke="url(#paint71_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1180.11 -37.4412C1166.39 -7.82464 1157.7 23.4858 1138.94 50.6515C1123.29 73.3302 1102.22 91.3596 1076.09 100.78C1012.62 123.661 942.752 97.8334 879.599 88.0394C867.665 86.1878 855.542 84.5749 843.532 83.4802C809.539 80.3852 776.055 82.9576 742.233 88.5214C700.584 95.3736 659.436 99.685 617.247 99.5904C596.617 99.5453 575.874 99.0543 555.252 98.1668C497.707 95.7025 440.5 89.0079 383.257 83.0567C351.469 79.7499 319.356 77.2316 287.45 76.1008C243.02 74.524 198.734 77.5514 154.773 84.21C104.351 91.8416 53.9652 101.496 3.44875 109.438C-39.3809 116.196 -84.6496 118.268 -125.879 103.708C-160.544 91.4676 -189.208 69.726 -215.596 44.2407C-240.031 20.6835 -262.6 -4.9415 -291.427 -23.2863"
        stroke="url(#paint72_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1180.47 -38.7969C1166.4 -9.3155 1157.86 22.2022 1139.57 49.6562C1124.24 72.6638 1103.53 91.3329 1077.45 101.235C1014.2 125.252 943.754 99.0591 880.753 88.7064C868.73 86.7287 856.507 84.9807 844.42 83.7508C810.233 80.2909 776.484 82.4398 742.45 88.0306C700.878 94.8649 659.73 99.9376 617.568 100.523C596.978 100.807 576.266 100.654 555.672 100.095C498.181 98.5365 440.839 93.3692 383.578 87.7288C352.173 84.6338 320.6 81.8226 289.141 79.8268C246.215 77.0446 203.128 78.0622 160.382 82.8677C111.24 88.4451 61.6479 95.7343 12.6058 103.109C-30.7739 109.628 -76.1012 112.164 -118.381 99.5771C-153.799 89.0307 -184.239 69.5011 -212.385 45.5565C-238.241 23.5581 -262.067 -0.846041 -291.223 -18.6232"
        stroke="url(#paint73_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1180.85 -40.2609C1166.39 -10.9462 1158 20.7742 1140.22 48.4896C1125.25 71.8576 1104.94 91.2295 1078.91 101.686C1016 127 944.902 100.451 882.104 89.495C869.972 87.3821 857.646 85.4855 845.447 84.0889C811.034 80.2145 776.987 81.8363 742.696 87.4226C701.179 94.1803 660.003 99.9784 617.895 101.244C597.346 101.861 576.67 102.046 556.13 101.816C498.689 101.177 441.121 97.487 383.82 92.3557C352.712 89.5716 321.604 86.6432 290.496 83.9041C248.753 80.228 207.091 79.1377 165.384 82.2688C117.505 85.8729 68.9725 91.3195 21.1927 97.5005C-23.2149 103.245 -67.8118 106.213 -111.133 95.6985C-147.069 86.9586 -179.34 69.1814 -209.154 46.7731C-236.371 26.3154 -261.451 3.07357 -290.959 -14.1629"
        stroke="url(#paint74_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1181.27 -41.7978C1166.15 -12.1813 1157.8 20.2058 1140.06 48.5835C1125.6 71.7036 1105.89 91.4089 1080.55 102.23C1018.02 128.936 946.197 102.041 883.629 90.4493C871.38 88.1968 858.946 86.1199 846.624 84.5927C811.91 80.2408 777.588 81.2995 742.968 86.7777C701.491 93.3416 660.248 99.8785 618.18 101.825C597.676 102.775 577.037 103.307 556.506 103.406C499.01 103.69 441.348 100.527 383.893 96.8781C353.015 94.9183 322.191 91.7513 291.431 88.3995C250.855 83.9845 210.365 81.0381 169.664 82.5158C123.101 84.2052 75.4386 87.5209 28.9662 92.8775C-15.7389 97.9728 -60.408 101.153 -104.329 92.1702C-140.923 84.6827 -174.75 68.8698 -206.092 47.9481C-234.531 28.9591 -260.815 6.8571 -290.741 -9.89736"
        stroke="url(#paint75_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1181.73 -43.469C1166.14 -14.0822 1157.83 18.4176 1140.75 47.0835C1126.71 70.6272 1107.5 91.1479 1082.27 102.663C1020.24 130.968 947.701 103.789 885.39 91.5083C873.015 89.071 860.468 86.832 848.011 85.102C813.263 80.2961 777.999 80.5739 743.331 85.9265C701.831 92.2336 660.517 99.4417 618.485 102.082C598.021 103.361 577.423 104.235 556.923 104.668C499.396 105.884 441.671 103.433 384.126 100.5C353.324 98.9326 322.604 96.8963 292.029 93.085C252.526 88.1294 213.051 83.4936 173.287 83.4125C150.691 83.3675 127.941 84.3361 105.287 85.4849C82.2941 86.6472 58.8189 86.5391 35.9479 89.089C-8.73016 94.0446 -53.8816 96.6755 -97.924 89.0619C-135.177 82.6196 -170.324 68.5817 -203.123 49.1062C-232.734 31.5363 -260.154 10.502 -290.518 -5.82901"
        stroke="url(#paint76_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1182.24 -45.2026C1153.24 7.27727 1143.41 74.0474 1084.1 103.105C1022.69 133.199 949.393 105.759 887.398 92.7931C874.888 90.1756 862.21 87.7383 849.614 85.8056C814.313 80.3995 779.161 79.994 743.775 85.0443C702.23 90.9685 660.789 98.8479 618.784 102.164C598.361 103.772 577.803 104.988 557.335 105.768C499.804 107.926 442.042 106.218 384.434 104.02C353.705 102.857 322.863 101.488 292.436 97.9108C253.781 93.3651 215.208 86.648 176.228 85.1163C154.241 84.2513 132.046 84.3955 109.955 84.7784C87.476 85.1703 64.4202 84.2738 42.0586 86.3462C-2.70064 90.4954 -47.8115 92.9597 -92.0478 86.4498C-129.95 80.868 -166.211 68.3798 -200.353 50.2964C-231.01 34.0555 -259.503 14.0258 -290.326 -1.96281"
        stroke="url(#paint77_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1182.82 -47.0196C1152.81 4.96018 1144.14 72.7664 1086.08 103.487C1025.4 135.586 951.334 107.951 889.691 94.2738C877.036 91.4671 864.223 88.8181 851.478 86.6601C815.835 80.6233 780.191 79.3754 744.332 84.0652C702.692 89.5163 661.094 97.9589 619.093 101.959C598.716 103.897 578.189 105.455 557.752 106.559C500.243 109.668 442.469 108.69 384.815 107.289C354.185 106.546 323.425 105.627 293.083 102.333C254.938 98.2021 216.847 90.3317 178.525 87.4665C135.614 84.2588 90.5036 82.0604 47.3313 84.5652C2.44137 87.1781 -42.2502 89.9713 -86.6758 84.3264C-125.159 79.4339 -162.444 68.3243 -197.758 51.5067C-229.375 36.4507 -258.837 17.3761 -290.121 1.68035"
        stroke="url(#paint78_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1183.45 -48.9061C1152.3 2.5241 1144.67 71.2764 1088.14 103.785C1028.37 138.137 953.532 110.412 892.273 96.0094C879.46 92.9955 866.498 90.1212 853.595 87.7155C817.569 81.0029 781.393 78.8044 744.988 83.0257C703.186 87.8732 661.394 96.7752 619.367 101.461C599.029 103.713 578.543 105.628 558.134 107.069C500.665 111.124 442.9 110.921 385.21 110.327C354.689 110.007 324.041 109.552 293.794 106.587C255.874 102.871 218.053 94.5317 180.183 90.45C138.255 85.9449 94.0725 82.3409 51.6937 83.796C6.97505 85.3232 -37.6038 87.7425 -81.9032 82.7733C-121.022 78.4538 -159.282 68.356 -195.433 52.8099C-227.894 38.8081 -258.249 20.6029 -289.979 5.11441"
        stroke="url(#paint79_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1184.17 -50.8617C1151.71 -0.0351495 1145.09 69.5146 1090.32 103.961C1031.66 140.853 956.089 113.169 895.226 98.0319C882.242 94.8062 869.127 91.6887 856.057 89.0216C819.611 81.5702 782.823 78.304 745.809 81.9306C703.768 86.0528 661.741 95.2432 619.637 100.618C599.35 103.213 578.886 105.456 558.508 107.236C501.098 112.25 443.351 112.831 385.643 113.061C355.243 113.182 324.708 113.2 294.574 110.569C256.649 107.258 218.946 99.0905 181.233 93.9276C159.746 90.9858 137.953 89.0441 116.254 87.4808C96.0744 86.0302 75.3899 83.5389 55.1653 83.9353C10.7668 84.8093 -33.7806 86.2284 -77.7689 81.7593C-117.258 77.7453 -156.215 68.8342 -193.377 54.1295C-226.568 41.0062 -257.721 23.5895 -289.857 8.23609"
        stroke="url(#paint80_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1185.01 -52.8042C1151.12 -2.61288 1145.3 67.482 1092.58 103.973C1035.21 143.618 959 116.169 898.497 100.315C885.342 96.8687 872.069 93.5079 858.824 90.5615C821.905 82.3397 784.498 77.9473 746.813 80.8711C704.479 84.1598 662.173 93.4853 619.943 99.5492C599.677 102.464 579.263 105.045 558.904 107.167C501.566 113.137 443.854 114.524 386.133 115.596C355.864 116.159 325.455 116.669 295.42 114.38C257.509 111.497 219.652 104.469 182.003 98.067C141.648 91.2237 98.8952 85.295 57.9095 84.9256C13.8356 84.5292 -30.4097 85.4076 -74.1184 81.2539C-114.045 77.4426 -153.597 69.3515 -191.521 55.5118C-225.37 43.1723 -257.244 26.4494 -289.804 11.1726"
        stroke="url(#paint81_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1185.91 -54.6505C1150.48 -5.09441 1145.29 65.2617 1094.72 103.726C1038.79 146.277 962.082 119.242 901.877 102.722C888.546 99.0635 875.12 95.4594 861.721 92.2382C824.301 83.2551 786.278 77.7183 747.911 79.8807C705.23 82.282 662.617 91.5534 620.22 98.3516C599.987 101.591 579.6 104.515 559.267 106.974C502.011 113.899 444.339 116.097 386.596 118.03C356.471 119.035 326.184 120.044 296.275 118.115C258.405 115.683 220.584 108.844 182.979 102.618C142.404 95.9008 101.103 88.8458 59.9456 86.7464C16.3045 84.5209 -27.5531 85.2057 -70.9237 81.2232C-111.233 77.52 -151.259 70.0731 -189.841 57.0533C-224.254 45.4121 -256.809 29.3019 -289.747 14.0656"
        stroke="url(#paint82_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1186.87 -56.3851C1167.66 -31.0575 1155.64 -1.61662 1144.44 27.8828C1133.85 55.7513 1120.04 83.746 1096.77 103.249C1042.41 148.786 965.401 122.413 905.381 105.262C891.883 101.406 878.33 97.5721 864.756 94.0852C826.836 84.3451 788.185 77.6731 749.125 79.0246C706.074 80.5158 663.082 89.5575 620.505 97.0765C600.307 100.64 579.93 103.897 559.642 106.695C502.471 114.574 444.84 117.584 387.124 120.39C357.152 121.85 327.027 123.364 297.227 121.809C259.451 119.841 221.671 113.25 184.139 107.195C143.433 100.64 102.501 92.8958 61.3755 89.4133C18.2934 85.7687 -25.1854 85.6335 -68.0827 81.6826C-108.717 77.9433 -149.157 71.0415 -188.272 58.7065C-223.27 47.669 -256.411 32.1264 -289.71 16.8992"
        stroke="url(#paint83_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1187.91 -58.0157C1148.06 -10.2887 1144.94 59.7971 1098.67 102.456C1045.96 151.075 969.003 125.666 908.987 107.903C895.327 103.848 881.617 99.7934 867.912 96.0676C829.469 85.5888 790.214 77.804 750.455 78.295C707.066 78.8356 663.542 87.3773 620.78 95.7433C600.632 99.6807 580.295 103.235 560.011 106.371C502.935 115.201 445.35 119.035 387.647 122.724C357.847 124.63 327.879 126.666 298.21 125.495C260.547 124.013 222.839 117.661 185.405 111.822C144.379 105.425 103.353 98.0183 62.1282 92.9861C19.7088 87.8097 -23.2651 86.733 -65.6709 82.6829C-106.548 78.777 -147.349 72.3212 -186.879 60.5268C-222.355 49.9534 -256.051 34.9559 -289.67 19.6971"
        stroke="url(#paint84_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1189.01 -59.4897C1169.29 -37.2931 1156.7 -9.14076 1145.57 18.061C1133.48 47.6325 1123.4 78.0464 1100.44 101.405C1049.55 153.11 972.722 128.945 912.661 110.618C898.852 106.406 885.007 102.144 871.162 98.1707C832.178 86.9755 792.328 78.096 751.879 77.6995C708.058 77.249 664.096 85.3131 621.028 94.3684C600.911 98.5896 580.633 102.545 560.381 106.023C503.4 115.799 445.868 120.439 388.161 125.044C358.541 127.4 328.74 129.963 299.206 129.188C261.692 128.206 224.083 122.124 186.767 116.511C145.236 110.258 103.717 103.915 62.2093 97.4813C20.4798 90.9579 -21.7999 88.5432 -63.6917 84.2408C-104.759 80.0286 -145.789 73.8385 -185.652 62.5307C-221.557 52.3492 -255.758 37.8113 -289.71 22.4939"
        stroke="url(#paint85_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1190.17 -60.8546C1170.17 -39.7752 1157.29 -12.3437 1145.99 14.0877C1133.27 43.8214 1124.01 75.3571 1102.03 99.991C1053.11 154.823 976.693 132.234 916.389 113.385C902.445 109.028 888.469 104.577 874.497 100.378C834.968 88.4939 794.555 78.5511 753.411 77.2312C709.121 75.812 664.704 83.3896 621.316 92.9449C601.217 97.3689 581.002 101.779 560.773 105.595C503.895 116.322 446.418 121.791 388.755 127.296C359.32 130.107 329.696 133.202 300.305 132.833C262.967 132.383 225.466 126.58 188.308 121.205C104.813 109.123 21.6017 95.8191 -62.092 86.3359C-103.272 81.6731 -144.456 75.6182 -184.535 64.6844C-220.814 54.7731 -255.465 40.6406 -289.702 25.2422"
        stroke="url(#paint86_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1191.38 -62.0707C1171.12 -42.1131 1157.98 -15.4564 1146.5 10.1956C1133.19 39.9293 1124.2 72.5462 1103.41 98.2613C1056.58 156.174 980.867 135.564 920.13 116.205C906.06 111.723 891.966 107.096 877.878 102.69C837.785 90.1521 796.822 79.1777 755.015 76.9161C710.252 74.4969 665.348 81.5068 621.567 91.5577C601.487 96.1664 581.316 101.018 561.11 105.177C504.345 116.845 446.917 123.138 389.295 129.567C360.054 132.829 330.619 136.469 301.382 136.519C264.246 136.582 226.894 131.112 189.889 125.986C106.169 114.389 22.8043 99.7524 -60.957 89.0438C-102.168 83.7773 -143.352 77.6909 -183.585 67.0409C-220.198 57.3504 -255.25 43.5152 -289.735 28.0042"
        stroke="url(#paint87_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1192.67 -63.2064C1172.2 -44.3976 1158.79 -18.5384 1147.16 6.31177C1133.23 36.0454 1124.17 69.3832 1104.61 96.1029C1060.01 157.025 985.308 138.847 923.931 118.989C909.747 114.403 895.528 109.623 881.354 105.023C840.693 91.8636 799.202 79.898 756.773 76.6859C711.523 73.2575 666.114 79.6097 621.892 90.1246C601.816 94.8955 581.69 100.193 561.511 104.685C504.859 117.299 447.485 124.413 389.899 131.774C360.861 135.491 331.615 139.685 302.531 140.154C265.626 140.748 228.423 135.599 191.625 130.761C107.372 119.678 23.8141 104.685 -60.2266 92.3681C-101.357 86.3403 -142.505 80.1097 -182.773 69.6083C-219.643 59.9899 -255.043 46.4115 -289.735 30.7608"
        stroke="url(#paint88_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1193.99 -64.2065C1173.34 -46.5601 1159.68 -21.5342 1147.9 2.50948C1133.31 32.2927 1123.91 65.8602 1105.57 93.562C1089.42 117.944 1065.93 134.68 1036.81 139.181C999.49 144.952 962.661 133.202 927.707 121.782C913.42 117.115 899.124 112.204 884.846 107.438C843.617 93.6882 801.617 80.7675 758.544 76.5958C712.788 72.1673 666.861 77.7626 622.17 88.7145C602.094 93.6386 582.005 99.3646 561.848 104.194C505.308 117.736 447.993 125.679 390.452 133.991C361.599 138.153 332.578 142.902 303.653 143.812C266.999 144.961 229.972 140.149 193.355 135.595C108.512 125.044 23.1319 111.573 -60.0657 96.3461C-100.443 88.9577 -142.006 82.8668 -182.243 72.3925C-219.293 62.72 -254.986 49.3354 -289.844 33.5225"
        stroke="url(#paint89_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1195.35 -65.0884C1146.85 -26.6599 1136.48 40.1012 1106.31 90.6214C1090.98 116.255 1068.08 135.024 1038.23 140.452C1001.81 147.07 965.646 135.762 931.504 124.54C917.122 119.819 902.745 114.796 888.394 109.885C846.593 95.6085 804.11 81.7779 760.437 76.633C714.199 71.1864 667.682 75.9527 622.508 87.3191C602.414 92.3738 582.356 98.5143 562.222 103.677C505.804 118.152 448.548 126.923 391.057 136.19C362.442 140.804 333.611 146.102 304.843 147.453C268.465 149.165 231.622 144.71 195.222 140.457C109.63 130.455 24.2141 118.22 -60.2279 101.262C-100.803 93.1261 -141.712 85.945 -181.778 75.3715C-219.009 65.5549 -254.94 52.2603 -289.93 36.2537"
        stroke="url(#paint90_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1196.76 -65.8645C1176.27 -50.8805 1162.33 -28.2694 1150.44 -6.30259C1134.02 24.0167 1122.69 56.7102 1106.76 87.1872C1092.96 113.605 1070.58 134.491 1041 141.19C1005.1 149.335 969.052 138.347 935.257 127.224C920.803 122.476 906.358 117.367 891.949 112.335C849.57 97.5354 806.642 82.8668 762.401 76.7444C715.671 70.2976 668.581 74.1629 622.853 85.8987C602.727 91.0616 582.728 97.612 562.594 103.113C506.289 118.507 449.095 128.111 391.668 138.347C363.265 143.406 334.655 149.281 306.049 151.087C269.982 153.367 233.329 149.285 197.162 145.343C110.836 135.937 24.7663 123.769 -60.2663 106.298C-138.78 90.201 -216.842 72.933 -290.032 39.0591"
        stroke="url(#paint91_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1198.2 -66.535C1177.66 -52.7314 1163.48 -31.0439 1151.47 -9.8789C1134.58 19.8908 1121.23 51.5301 1106.89 82.5657C1094.18 110.092 1073.65 133.262 1043.57 141.583C1008.32 151.35 972.401 140.776 938.975 129.838C924.458 125.089 909.959 119.927 895.515 114.791C852.586 99.5454 809.215 84.0614 764.442 77.0154C717.208 69.582 669.514 72.4518 623.217 84.5344C603.051 89.7964 583.066 96.7297 562.958 102.555C506.779 118.877 449.645 129.315 392.28 140.524C364.089 146.065 335.686 152.498 307.283 154.773C271.514 157.63 235.095 153.931 199.172 150.309C112.12 141.56 25.4239 129.455 -60.1497 111.448C-138.596 94.9456 -217.235 76.0783 -290.172 41.8395"
        stroke="url(#paint92_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1199.68 -67.1125C1179.13 -54.4983 1164.72 -33.7748 1152.6 -13.4027C1135.26 15.7407 1121.61 46.9656 1107.1 77.5462C1093.7 105.838 1077.23 131.229 1045.95 141.645C1011.46 153.124 975.706 143.077 942.642 132.373C928.084 127.656 913.536 122.462 899.077 117.25C855.621 101.621 811.813 85.367 766.531 77.3885C718.805 68.9775 670.471 70.7885 623.579 83.1866C603.355 88.5341 583.4 95.8369 563.307 102.013C507.249 119.25 450.173 130.522 392.89 142.717C364.938 148.664 336.774 155.674 308.511 158.417C273.075 161.859 236.886 158.557 201.211 155.264C113.478 147.154 26.1913 135.171 -59.8917 116.632C-138.473 99.7112 -217.406 79.4292 -290.306 44.6183"
        stroke="url(#paint93_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1201.2 -67.6033C1180.66 -56.1558 1166.03 -36.4235 1153.81 -16.8668C1136.04 11.5829 1122.11 42.3121 1107.46 72.4242C1093.51 101.081 1079.59 128.86 1048.11 141.298C1014.52 154.566 978.96 145.177 946.251 134.761C931.662 130.116 917.091 124.895 902.633 119.656C858.658 103.708 814.449 86.7414 768.734 77.8393C720.535 68.4597 671.506 69.149 624.015 81.8353C603.727 87.2414 583.8 94.9001 563.711 101.423C507.775 119.57 450.753 131.68 393.542 144.866C365.802 151.259 337.886 158.832 309.794 162.062C274.71 166.09 238.764 163.197 203.351 160.26C114.946 152.908 27.1004 140.987 -59.4876 121.913C-138.434 104.518 -217.443 82.9931 -290.457 47.4298"
        stroke="url(#paint94_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1202.73 -68.0076C1182.26 -57.7314 1167.43 -39.0398 1155.1 -20.2535C1136.9 7.43934 1122.73 37.646 1107.95 67.2265C1093.65 95.8384 1080.81 126.243 1050.02 140.552C1017.47 155.671 982.154 147.102 949.775 137.024C935.181 132.483 920.597 127.279 906.161 122.027C861.685 105.808 817.098 88.1887 770.963 78.4081C722.273 68.0824 672.563 67.5959 624.418 80.5255C604.031 86.0037 584.154 93.9777 564.065 100.839C508.255 119.896 451.287 132.825 394.162 147.025C366.661 153.86 338.97 162.009 311.054 165.721C276.34 170.339 240.642 167.875 205.495 165.271C116.432 158.68 28.0585 146.836 -59.0299 127.225C-138.459 109.34 -217.409 86.729 -290.644 50.2422"
        stroke="url(#paint95_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1204.29 -68.3458C1183.93 -59.2275 1168.89 -41.6126 1156.45 -23.6417C1137.86 3.2312 1123.46 32.8883 1108.57 61.8876C1094.05 90.1526 1081.09 123.369 1051.68 139.308C1020.3 156.31 985.307 148.768 953.212 139.069C938.628 134.667 924.048 129.514 909.661 124.274C864.695 107.894 819.764 89.639 773.265 79.0069C724.123 67.7667 673.67 66.0367 624.858 79.1871C604.372 84.7059 584.53 92.9863 564.445 100.19C508.762 120.147 451.844 133.942 394.813 149.129C367.515 156.4 340.081 165.131 312.35 169.33C278.019 174.524 242.583 172.506 207.711 170.262C117.994 164.496 29.1291 152.751 -58.4372 132.604C-138.461 114.201 -217.358 90.612 -290.831 53.0665"
        stroke="url(#paint96_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1205.87 -68.6166C1185.65 -60.6425 1170.42 -44.1494 1157.87 -26.9579C1138.9 -0.963496 1124.29 28.0989 1109.31 56.481C1094.59 84.3856 1080.85 120.12 1053.06 137.6C1023 156.521 988.412 150.183 956.538 140.938C941.985 136.717 927.432 131.644 913.104 126.45C867.673 109.97 822.436 91.1568 775.599 79.7048C726.272 67.6401 674.768 64.3154 625.298 77.9027C604.618 83.5747 584.894 92.0172 564.787 99.5632C537.335 109.871 509.59 119.638 481.481 128.139C453.078 136.73 423.913 143.159 395.434 151.259C368.352 158.967 341.166 168.27 313.611 172.969C279.681 178.754 244.511 177.186 209.918 175.303C119.593 170.388 30.241 158.724 -57.7897 138.055C-138.458 119.133 -217.386 94.6391 -291.085 55.9268"
        stroke="url(#paint97_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1207.46 -68.8284C1187.43 -61.9716 1172.02 -46.6408 1159.35 -30.2197C1140.02 -5.16681 1125.22 23.2694 1110.18 50.9892C1102.1 65.8831 1094.07 80.8085 1085.5 95.4185C1076.79 110.249 1068.3 125.085 1054.14 135.383C1025.54 156.183 991.473 151.345 959.743 142.591C945.244 138.591 930.736 133.635 916.494 128.513C870.617 112.024 825.1 92.6974 778.014 80.4751C728.255 67.5545 675.954 62.8782 625.798 76.6052C604.974 82.3041 585.299 90.999 565.174 98.8919C537.772 109.646 510.081 119.895 481.999 128.869C453.663 137.924 424.503 144.74 396.1 153.345C369.235 161.481 342.306 171.365 314.949 176.564C281.421 182.939 246.535 181.826 212.222 180.313C121.297 176.289 31.4895 164.707 -56.992 143.51C-138.143 124.071 -217.711 99.0675 -291.288 58.7604"
        stroke="url(#paint98_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1209.06 -69.0088C1189.23 -63.2468 1173.69 -49.1143 1160.88 -33.459C1141.21 -9.41984 1126.24 18.3811 1111.16 45.4027C1102.87 60.2695 1094.66 75.1724 1085.96 89.805C1077.13 104.672 1068.33 121.341 1054.91 132.603C1027.91 155.246 994.498 152.183 962.817 143.988C948.39 140.258 933.963 135.428 919.816 130.432C873.542 114.024 827.764 94.256 780.462 81.2948C730.275 67.5543 677.161 61.4724 626.298 75.3391C605.334 81.047 585.637 89.9491 565.552 98.2159C538.263 109.438 510.55 120.142 482.503 129.598C454.24 139.122 425.084 146.33 396.763 155.435C370.104 163.995 343.433 174.442 316.234 180.164C283.12 187.129 248.518 186.471 214.489 185.34C122.968 182.254 32.7468 170.739 -56.1721 149.024C-137.977 129.049 -217.883 103.293 -291.573 61.6255"
        stroke="url(#paint99_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1210.67 -69.1433C1161.98 -57.4571 1134.82 0.433441 1112.25 39.7269C1103.75 54.5217 1095.37 69.393 1086.55 84.0121C1077.34 99.2934 1067.98 116.476 1055.32 129.239C1030.74 154.044 996.864 152.548 965.72 145.119C951.402 141.704 937.051 137.078 923.039 132.208C876.36 115.989 830.397 95.8019 782.937 82.1694C732.312 67.6315 678.383 60.162 626.802 74.0873C605.721 79.7772 585.83 88.8505 565.903 97.5138C538.894 109.268 511.018 120.386 482.994 130.297C454.803 140.294 425.657 147.867 397.416 157.499C370.965 166.509 344.551 177.493 317.532 183.737C284.833 191.296 250.569 191.116 216.783 190.355C124.609 188.246 34.0674 176.781 -55.2752 154.557C-98.9209 143.705 -142.287 130.824 -184.666 115.439C-222.194 101.816 -257.418 84.6472 -291.88 64.5229"
        stroke="url(#paint100_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1212.28 -69.2566C1163.77 -60.0166 1136.09 -4.27053 1113.44 33.9868C1095.27 64.698 1078.17 96.9771 1055.4 124.629C1033.08 151.732 1000.04 152.732 968.484 145.947C954.296 142.898 940.054 138.487 926.191 133.784C879.15 117.858 833.034 97.3194 785.466 83.0743C734.418 67.7885 679.654 58.8864 627.357 72.8477C606.168 78.5106 586.056 87.7461 566.291 96.7968C539.516 109.064 511.487 120.57 483.495 130.991C455.38 141.456 426.238 149.461 398.079 159.553C371.831 168.978 345.678 180.538 318.835 187.304C286.568 195.44 252.611 195.756 219.096 195.359C126.341 194.269 35.429 182.858 -54.3149 160.129C-98.4114 148.966 -142.228 135.658 -184.95 119.732C-222.698 105.667 -257.738 88.2731 -292.173 67.4055"
        stroke="url(#paint101_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1213.89 -69.3551C1165.65 -62.5073 1137.47 -9.12192 1114.73 28.1758C1095.92 59.0132 1078.33 91.2697 1055.54 119.431C1046.4 130.73 1036.81 139.704 1023.08 145.061C1006.25 151.634 988.094 149.665 971.043 146.453C957.018 143.808 942.92 139.668 929.219 135.19C881.881 119.71 835.621 98.8428 788.012 84.048C736.544 68.0459 680.938 57.7022 627.915 71.6635C606.59 77.2814 586.307 86.6384 566.655 96.0901C540.056 108.88 511.946 120.792 483.989 131.68C455.952 142.619 426.81 150.998 398.74 161.612C372.691 171.465 346.799 183.575 320.132 190.895C288.303 199.604 254.661 200.415 221.399 200.397C128.075 200.347 36.857 188.963 -53.3152 165.739C-97.867 154.269 -142.099 140.51 -185.185 124.058C-223.155 109.551 -258.131 91.9319 -292.516 70.3344"
        stroke="url(#paint102_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1215.49 -69.4502C1167.54 -64.9451 1139.01 -14.1276 1116.1 22.2962C1096.68 53.1696 1078.65 85.3676 1055.84 113.948C1046.77 125.314 1037.28 136.136 1023.94 142.506C1007.9 150.164 990.205 149.241 973.416 146.605C959.584 144.434 945.639 140.591 932.128 136.356C884.515 121.426 838.173 100.315 790.587 84.9981C738.7 68.3292 682.242 56.5394 628.502 70.4601C607.01 76.0239 586.736 85.327 566.998 95.2832C540.358 108.686 512.388 120.962 484.495 132.342C456.543 143.749 427.392 152.53 399.413 163.644C373.553 173.92 347.923 186.579 321.418 194.409C290.021 203.685 256.7 205 223.721 205.361C130.624 206.456 37.8026 195.012 -52.2369 171.338C-97.2486 159.58 -141.913 145.38 -185.356 128.373C-223.488 113.443 -258.599 95.6346 -292.854 73.2532"
        stroke="url(#paint103_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1217.08 -69.5487C1169.53 -67.2962 1140.65 -19.1682 1117.56 16.3726C1097.86 46.6784 1079.69 78.2186 1057.41 106.763C1048.24 118.508 1038.14 131.253 1025.12 138.961C1009.79 148.043 992.432 148.422 975.566 146.381C961.964 144.741 948.205 141.24 934.905 137.28C887.067 123.053 840.68 101.726 793.179 86.0124C740.882 68.7038 683.572 55.4813 629.11 69.3165C607.443 74.8217 587.182 84.0977 567.346 94.509C540.669 108.515 512.835 121.166 485.004 133.023C457.174 144.88 427.982 154.075 400.098 165.694C374.423 176.389 349.041 189.607 322.734 197.955C291.766 207.79 258.805 209.614 226.065 210.362C179.918 211.416 133.143 210.551 87.302 204.722C40.5455 198.775 -5.75103 189.035 -51.1279 176.966C-135.435 154.562 -220.571 125.689 -293.228 76.2047"
        stroke="url(#paint104_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1218.64 -69.67C1171.52 -69.5889 1142.48 -24.4839 1119.08 10.3721C1098.83 40.5247 1080.28 71.9523 1058.01 100.695C1048.43 113.061 1038 125.824 1025.21 135.069C1010.96 145.381 994.105 147.057 977.478 145.75C964.156 144.714 950.585 141.583 937.516 137.956C889.519 124.585 843.106 103.087 795.772 87.0488C743.024 69.1501 684.906 54.4995 629.728 68.213C607.872 73.6461 587.48 82.8906 567.666 93.7389C514.016 123.112 456.53 143.421 400.752 167.749C375.253 178.867 350.127 192.617 323.997 201.505C293.471 211.867 260.825 214.232 228.352 215.363C182.163 216.967 135.222 216.471 89.2949 210.736C42.2274 204.857 -4.37573 194.968 -50.0141 182.633C-134.537 159.797 -221.59 130.397 -293.625 79.1738"
        stroke="url(#paint105_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1220.19 -69.8284C1173.56 -71.8242 1144.45 -29.9357 1120.68 4.31202C1099.9 34.2304 1081 65.5093 1058.75 94.378C1048.76 107.321 1038.06 120.183 1025.23 130.419C1011.37 141.492 996.143 145.182 979.151 144.668C966.13 144.272 952.804 141.582 939.977 138.294C891.886 125.959 845.486 104.33 798.383 87.9897C745.206 69.5413 686.268 53.4896 630.378 67.041C608.332 72.384 587.76 81.7096 568.013 92.8687C541.585 107.803 513.692 121.463 485.961 134.271C458.23 147.079 429.101 157.075 401.406 169.694C376.078 181.236 351.205 195.517 325.25 204.947C295.161 215.871 262.832 218.732 230.619 220.264C184.408 222.431 137.296 222.305 91.3104 216.66C43.9183 210.848 -2.98243 200.784 -48.9003 188.224C-133.527 165.067 -222.784 135.149 -294.039 82.1555"
        stroke="url(#paint106_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1221.7 -70.0262C1175.63 -74.0042 1146.57 -35.5036 1122.33 -1.79648C1101.05 27.8065 1081.85 58.9053 1059.61 87.8235C1038.97 114.642 1015.43 142.268 980.549 143.16C967.894 143.484 954.819 141.272 942.255 138.371C894.146 127.257 847.777 105.52 800.984 88.9903C747.366 70.0689 687.639 52.6161 631.018 65.9647C608.774 71.2086 588.099 80.6198 568.325 92.0627C541.906 107.358 514.143 121.594 486.453 134.906C458.762 148.219 429.674 158.59 402.074 171.704C376.908 183.665 352.288 198.473 326.504 208.438C296.853 219.899 264.857 223.238 232.883 225.175C186.645 227.973 139.334 228.13 93.281 222.598C45.5778 216.867 -1.60693 206.654 -47.7457 193.86C-94.5653 180.867 -140.939 164.987 -185.765 145.8C-224.046 129.432 -261.556 110.804 -294.48 85.1563"
        stroke="url(#paint107_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1223.18 -70.2838C1177.78 -76.1404 1148.8 -41.1853 1124.05 -7.96924C1102.69 20.7147 1083.53 50.98 1061.83 79.4297C1041.26 106.397 1016.75 137.509 981.665 141.15C969.398 142.42 956.477 140.546 944.336 138.095C896.308 128.414 849.975 106.586 803.58 89.9536C749.511 70.5816 689.067 51.8179 631.725 64.9007C609.286 70.014 588.444 79.5242 568.693 91.2375C542.269 106.897 514.637 121.705 486.965 135.518C459.405 149.281 430.277 160.084 402.771 173.694C377.754 186.074 353.318 201.423 327.769 211.902C298.636 223.85 266.906 227.747 235.149 230.067C188.875 233.45 141.415 233.955 95.2856 228.526C47.2804 222.876 -0.188392 212.51 -46.5526 199.486C-93.8455 186.205 -140.629 169.883 -185.794 150.178C-223.957 133.509 -262.554 114.646 -294.942 88.1785"
        stroke="url(#paint108_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1224.64 -70.6296C1179.95 -78.2162 1151.12 -46.9463 1125.81 -14.1943C1104 14.0302 1084.61 44.0296 1062.95 72.3712C1042.27 99.4334 1016.52 133.019 982.488 138.596C970.766 140.515 958.211 139.448 946.227 137.447C898.439 129.487 852.083 107.529 806.175 90.8736C751.65 71.0917 690.471 51.0395 632.435 63.843C609.789 68.8347 588.843 78.4035 569.042 90.3871C542.623 106.389 515.122 121.779 487.463 136.1C459.962 150.332 430.856 161.55 403.445 175.655C378.577 188.454 354.385 204.307 328.994 215.327C300.289 227.779 268.874 232.208 237.356 234.906C191.073 238.875 143.447 239.713 97.2402 234.397C48.9554 228.842 1.20264 218.336 -45.387 205.082C-93.1217 191.522 -140.315 174.786 -185.801 154.549C-223.486 137.781 -264.246 118.774 -295.422 91.2114"
        stroke="url(#paint109_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1226.04 -71.0303C1209.49 -74.4947 1193.23 -74.4722 1177.8 -66.809C1157.35 -56.632 1141.62 -37.9088 1127.59 -20.4065C1105.35 7.28636 1085.76 36.9885 1064.16 65.1904C1042.75 93.122 1016.91 127.005 982.975 135.551C971.825 138.358 959.576 137.961 947.872 136.452C900.449 130.402 854.053 108.363 808.73 91.784C753.728 71.6642 691.855 50.3371 633.151 62.8297C610.289 67.6907 589.217 77.3046 569.376 89.5539C542.92 105.885 515.554 121.865 487.936 136.695C460.539 151.4 431.41 163.028 404.08 177.624C379.347 190.842 355.39 207.196 330.143 218.751C301.911 231.663 270.817 236.618 239.529 239.713C193.223 244.277 145.434 245.435 99.1558 240.222C50.5734 234.762 2.56819 224.121 -44.2108 210.664C-92.3783 196.816 -139.978 179.665 -185.774 158.905C-223.455 141.845 -265.013 122.328 -295.936 94.2572"
        stroke="url(#paint110_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1227.4 -71.5312C1211.27 -75.5273 1195.09 -76.7571 1179.51 -70.013C1159.16 -61.21 1143.35 -43.3473 1129.43 -26.692C1106.79 0.406189 1087.05 29.7749 1065.5 57.7336C1042.39 87.7285 1017.44 118.926 983.145 131.761C972.7 135.672 960.739 135.947 949.333 135.005C902.445 131.14 855.932 109.015 811.308 92.6075C755.824 72.1814 693.283 49.6559 633.922 61.8197C610.843 66.532 589.627 76.1774 569.745 88.6836C543.267 105.352 516.032 121.909 488.445 137.244C461.133 152.422 432.005 164.468 404.792 179.551C380.19 193.193 356.471 210.033 331.387 222.12C303.502 235.563 272.732 241.041 241.67 244.497C195.382 249.642 147.404 251.119 101.008 246.038C52.1734 240.672 3.91564 229.887 -43.0797 216.232C-131.106 190.665 -224.213 155.76 -296.388 97.1936"
        stroke="url(#paint111_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1228.71 -72.1898C1212.97 -76.6588 1196.91 -79.0781 1181.16 -73.28C1160.97 -65.8466 1145.1 -48.8714 1131.28 -33.0315C1108.26 -6.59555 1088.36 22.4083 1066.92 50.0921C1043.15 80.7763 1018.45 111.402 983.214 127.165C972.678 131.878 962.092 133.351 950.505 133.108C904.213 132.134 857.182 109.321 813.825 93.3545C757.844 72.7437 694.7 49.0649 634.698 60.8052C611.398 65.3644 590.033 75.0233 570.102 87.7772C543.592 104.739 516.488 121.912 488.951 137.752C461.725 153.403 432.596 165.873 405.478 181.452C381.002 195.508 357.509 212.83 332.573 225.453C305.072 239.365 274.631 245.37 243.758 249.195C197.475 254.934 149.303 256.718 102.848 251.754C53.7341 246.501 5.22393 235.607 -41.9518 221.75C-130.663 195.71 -224.677 159.908 -296.788 99.9139"
        stroke="url(#paint112_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1229.96 -72.9591C1214.58 -77.8561 1198.65 -81.4016 1182.78 -76.5632C1162.8 -70.4723 1146.83 -54.4296 1133.15 -39.3826C1109.78 -13.672 1089.77 14.9354 1068.42 42.2949C1043.99 73.6188 1018.93 104.965 983.417 122.229C973.048 127.27 963.003 130.293 951.407 130.766C906.175 132.6 858.575 109.592 816.3 94.0494C759.824 73.2944 696.089 48.5479 635.483 59.8377C611.958 64.2256 590.435 73.8891 570.445 86.8683C543.895 104.127 516.921 121.904 489.402 138.226C462.261 154.35 433.133 167.244 406.114 183.277C381.769 197.752 358.47 215.547 333.678 228.698C306.574 243.074 276.421 249.597 245.764 253.791C199.486 260.098 151.124 262.184 104.571 257.35C55.2222 252.228 6.49107 241.217 -40.8695 227.189C-130.239 200.757 -225.154 164.036 -297.167 102.627"
        stroke="url(#paint113_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1231.15 -73.887C1216.11 -79.1895 1200.33 -83.7711 1184.37 -79.9103C1164.64 -75.1439 1148.62 -60.0878 1135.04 -45.8022C1084.47 7.35799 1049.17 82.6607 983.762 116.909C973.843 122.098 963.253 126.635 952.063 127.896C908.206 132.852 859.822 109.633 818.791 94.6443C761.769 73.8217 697.538 48.021 636.332 58.8648C612.573 63.077 590.883 72.7179 570.83 85.9449C544.231 103.492 517.383 121.878 489.905 138.727C462.854 155.323 433.721 168.645 406.793 185.174C382.537 200.068 359.455 218.332 334.807 232C308.073 246.822 278.241 253.863 247.75 258.418C201.467 265.333 152.916 267.667 106.259 262.964C56.667 257.968 7.73301 246.853 -39.8124 232.653C-129.854 205.735 -225.634 168.109 -297.539 105.299"
        stroke="url(#paint114_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1232.28 -74.9918C1217.74 -80.5421 1202.45 -86.0158 1186.66 -83.4254C1166.87 -80.1772 1150.59 -65.9185 1136.92 -52.2275C1084.41 0.373997 1049.31 74.8163 984.239 111.226C974.267 116.804 963.108 121.985 952.396 124.485C933.628 128.864 911.172 123.584 891.723 118.453C867.783 112.146 844.475 103.554 821.216 95.1478C763.653 74.3387 698.971 47.5649 637.188 57.9086C613.199 61.9271 591.329 71.532 571.195 84.9797C544.523 102.815 517.829 121.791 490.386 139.149C463.417 156.223 434.284 169.973 407.432 186.984C383.276 202.302 360.401 221.011 335.866 235.189C309.487 250.443 279.962 257.998 249.63 262.909C203.347 270.405 154.597 272.982 107.823 268.418C58.0503 263.566 8.89545 252.385 -38.7762 238C-129.494 210.667 -226.136 172.135 -297.905 107.942"
        stroke="url(#paint115_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1233.31 -76.2884C1219.01 -82.145 1203.92 -88.4522 1188.1 -86.8394C1168.67 -84.8301 1152.41 -71.6932 1138.8 -58.714C1084.45 -6.9054 1049.45 66.8611 984.835 105.208C974.7 111.223 963.772 116.048 952.465 119.958C933.958 126.36 912.016 122.022 893.5 117.638C869.7 112.007 846.567 103.717 823.579 95.5315C765.507 74.8486 700.419 47.1648 638.068 56.9769C613.845 60.7882 591.785 70.339 571.57 84.0074C544.826 102.077 518.244 121.693 490.87 139.555C463.977 157.094 434.875 171.294 408.105 188.765C384.039 204.496 361.348 223.661 336.935 238.357C310.899 254.034 281.658 262.089 251.488 267.351C205.191 275.425 156.244 278.231 109.339 273.803C59.3497 269.09 10.0055 257.792 -37.8014 243.29C-129.159 215.561 -226.653 176.119 -298.264 110.565"
        stroke="url(#paint116_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1234.29 -77.7976C1220.17 -83.9561 1205.3 -91.0201 1189.5 -90.3263C1170.46 -89.4884 1154.27 -77.5183 1140.67 -65.2104C1084.59 -14.4379 1049.6 58.797 985.581 98.8653C975.187 105.363 964.16 110.789 952.67 115.061C912.46 129.991 864.346 109.205 825.885 95.7838C767.276 75.3217 701.828 46.8044 638.968 56.0578C614.501 59.6619 592.243 69.1226 571.937 83.0073C545.112 101.325 518.657 121.571 491.327 139.934C464.525 157.954 435.423 172.578 408.747 190.526C384.762 206.672 362.243 226.296 337.965 241.479C312.2 257.58 283.369 266.095 253.266 271.717C206.97 280.371 157.797 283.376 110.756 279.096C60.5737 274.533 11.0536 263.198 -36.8795 248.525C-128.887 220.399 -227.178 180.07 -298.618 113.178"
        stroke="url(#paint117_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1235.16 -79.5313C1221.18 -85.9465 1206.55 -93.6638 1190.81 -93.88C1172.2 -94.1368 1156.1 -83.3696 1142.51 -71.7104C1084.83 -22.2399 1049.76 50.5579 986.428 92.2256C975.909 99.2036 964.712 105.104 953.007 109.836C934.23 117.314 916.837 118.396 896.508 114.981C873.155 111.07 850.437 103.47 828.125 95.8882C768.867 75.7459 703.311 46.4763 639.905 55.1666C615.199 58.55 592.738 67.9431 572.324 82.008C545.418 100.555 519.08 121.432 491.813 140.295C465.092 158.766 435.982 173.858 409.4 192.266C385.506 208.818 363.343 228.946 338.943 244.57C313.245 261.018 284.933 270.06 254.957 276.025C157.052 295.505 58.4902 282.782 -36.0054 253.706C-128.639 225.216 -227.72 183.99 -298.966 115.765"
        stroke="url(#paint118_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1235.93 -81.5191C1222.08 -88.1596 1207.69 -96.4265 1192.03 -97.5167C1173.87 -98.7872 1157.92 -89.2409 1144.31 -78.2079C1085.17 -30.2916 1049.85 42.3891 987.389 85.2957C976.707 92.6907 965.346 99.055 953.459 104.303C935.16 112.268 918.01 115.669 897.709 113.115C874.666 110.213 852.174 103.041 830.272 95.8286C770.446 76.1503 704.727 46.1959 640.857 54.2916C615.912 57.4451 593.23 66.7436 572.717 81.0023C545.712 99.766 519.487 121.287 492.283 140.659C465.648 159.612 436.546 175.136 410.05 194.008C386.223 210.979 364.214 231.531 339.909 247.619C314.513 264.45 286.453 273.969 256.594 280.263C158.672 300.905 59.4469 288.097 -35.1886 258.828C-86.7465 242.888 -137.398 222.408 -185.417 197.337C-226.398 175.938 -266.144 150.804 -299.308 118.345"
        stroke="url(#paint119_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1236.6 -83.7762C1223.03 -90.4979 1209.14 -99.035 1193.88 -101.161C1176.04 -103.653 1159.86 -95.2643 1146.07 -84.7268C1120.04 -64.8548 1099.07 -38.3243 1078.11 -13.4336C1050.31 19.5617 1023.32 53.1923 988.456 78.101C977.612 85.9021 966.091 92.7185 954.03 98.4685C936.448 106.74 918.586 112.412 898.681 110.858C876.026 109.087 853.796 102.379 832.318 95.5987C771.905 76.5241 706.124 45.9525 641.83 53.4445C616.646 56.3773 593.748 65.5497 573.108 79.9931C545.99 98.964 519.909 121.129 492.755 140.974C466.196 160.396 437.108 176.371 410.703 195.702C386.939 213.092 365.078 234.054 340.854 250.664C315.738 267.874 287.921 277.857 258.166 284.452C160.244 306.221 60.3248 293.382 -34.4504 263.9C-86.2968 247.781 -137.196 226.985 -185.382 201.478C-226.602 179.664 -266.533 154.012 -299.647 120.904"
        stroke="url(#paint120_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1237.15 -86.3174C1223.63 -93.1967 1210 -102.054 1194.87 -104.941C1177.54 -108.248 1161.62 -101.094 1147.75 -91.1919C1121.65 -72.5543 1100.7 -46.4473 1080.04 -22.2639C1051.72 10.8846 1024.42 44.8891 989.621 70.6538C978.615 78.8517 966.937 86.1094 954.713 92.3503C937.896 100.833 918.83 108.632 899.417 108.181C877.241 107.668 855.235 101.478 834.262 95.175C773.273 76.8618 707.577 45.7811 642.823 52.6108C617.427 55.2913 594.245 64.3556 573.511 78.9746C546.271 98.1754 520.312 120.958 493.226 141.289C466.752 161.184 437.673 177.6 411.331 197.423C387.626 215.227 365.895 236.617 341.748 253.664C316.889 271.234 289.307 281.668 259.642 288.597C161.769 311.429 61.1287 298.535 -33.7412 268.914C-85.8761 252.633 -137.024 231.522 -185.358 205.626C-226.817 183.407 -266.933 157.228 -299.993 123.48"
        stroke="url(#paint121_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1237.59 -89.1685C1224.09 -96.1514 1210.7 -105.247 1195.74 -108.824C1178.96 -112.834 1163.28 -106.923 1149.37 -97.6246C1123.26 -80.1764 1102.35 -54.6234 1081.97 -31.1249C1053.15 2.11376 1025.58 36.4516 990.873 62.9821C979.709 71.5657 967.879 79.2486 955.496 85.9581C939.018 94.7971 919.19 103.979 899.921 105.105C878.488 106.335 856.379 100.262 836.092 94.5403C774.521 77.1912 708.928 45.6015 643.836 51.814C618.201 54.2602 594.78 63.1803 573.916 77.9435C546.559 97.3155 520.685 120.742 493.693 141.583C467.301 161.941 438.24 178.826 411.965 199.063C388.315 217.282 366.71 239.046 342.622 256.589C318.069 274.465 290.559 285.421 261.052 292.629C163.22 316.506 61.8446 303.635 -33.12 273.866C-85.5164 257.44 -136.894 236.045 -185.35 209.754C-227.053 187.125 -267.33 160.418 -300.314 126.035"
        stroke="url(#paint122_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <path
        d="M1237.91 -92.3493C1224.21 -99.4989 1210.86 -108.955 1195.76 -113.023C1179.76 -117.339 1164.59 -112.573 1150.89 -103.981C1125.44 -88.0424 1105.04 -63.9356 1085.37 -41.6579C1055.55 -7.89672 1027.36 27.4323 992.213 55.0981C965.059 76.4658 926.81 102.014 890.22 102.064C851.236 102.118 812.32 86.4536 775.653 74.9385C709.497 54.17 634.897 33.2168 574.327 76.9568C546.79 96.8423 521.074 120.598 494.163 141.902C467.852 162.729 438.8 180.074 412.602 200.761C388.987 219.408 367.311 241.519 343.462 259.548C319.337 277.785 291.827 289.133 262.378 296.625C164.614 321.503 62.4765 308.65 -32.5692 278.754C-85.2091 262.197 -136.789 240.528 -185.349 213.853C-227.277 190.823 -267.731 163.594 -300.633 128.585"
        stroke="url(#paint123_linear_1982_41565)"
        strokeWidth="0.272831"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1982_41565"
          x1="32694.5"
          y1="172831"
          x2="5.45047e+06"
          y2="172831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1982_41565"
          x1="34425.3"
          y1="168175"
          x2="5.43135e+06"
          y2="168175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1982_41565"
          x1="36117.9"
          y1="163570"
          x2="5.41226e+06"
          y2="163570"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1982_41565"
          x1="37773.8"
          y1="159005"
          x2="5.39335e+06"
          y2="159005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1982_41565"
          x1="39421.7"
          y1="154498"
          x2="5.37429e+06"
          y2="154498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1982_41565"
          x1="41033.7"
          y1="150070"
          x2="5.3555e+06"
          y2="150070"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1982_41565"
          x1="42606.4"
          y1="145662"
          x2="5.33649e+06"
          y2="145662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1982_41565"
          x1="44175.2"
          y1="141336"
          x2="5.31781e+06"
          y2="141336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1982_41565"
          x1="45705.2"
          y1="137046"
          x2="5.29899e+06"
          y2="137047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1982_41565"
          x1="47214.3"
          y1="132812"
          x2="5.28026e+06"
          y2="132813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1982_41565"
          x1="48687.3"
          y1="128646"
          x2="5.26165e+06"
          y2="128647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1982_41565"
          x1="50153.3"
          y1="124510"
          x2="5.24298e+06"
          y2="124510"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1982_41565"
          x1="51599.6"
          y1="120426"
          x2="5.22449e+06"
          y2="120427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1982_41565"
          x1="53023.8"
          y1="116386"
          x2="5.20594e+06"
          y2="116387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1982_41565"
          x1="54442.4"
          y1="112129"
          x2="5.18746e+06"
          y2="112129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1982_41565"
          x1="55855.9"
          y1="107814"
          x2="5.1691e+06"
          y2="107815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1982_41565"
          x1="57245.9"
          y1="103522"
          x2="5.15053e+06"
          y2="103523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1982_41565"
          x1="58633.3"
          y1="99436.2"
          x2="5.13231e+06"
          y2="99436.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1982_41565"
          x1="60013.3"
          y1="95749.4"
          x2="5.11397e+06"
          y2="95749.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1982_41565"
          x1="61403.6"
          y1="94295.8"
          x2="5.09576e+06"
          y2="94296.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1982_41565"
          x1="62816.5"
          y1="92657.5"
          x2="5.07746e+06"
          y2="92658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1982_41565"
          x1="64268.4"
          y1="91066.8"
          x2="5.05917e+06"
          y2="91067.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1982_41565"
          x1="65786.2"
          y1="89638.4"
          x2="5.04072e+06"
          y2="89638.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1982_41565"
          x1="67388.9"
          y1="88360"
          x2="5.02242e+06"
          y2="88360.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1982_41565"
          x1="69070.1"
          y1="87201.4"
          x2="5.00387e+06"
          y2="87201.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1982_41565"
          x1="70875.8"
          y1="86084.3"
          x2="4.98521e+06"
          y2="86084.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1982_41565"
          x1="73304.4"
          y1="85087.5"
          x2="4.96606e+06"
          y2="85087.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1982_41565"
          x1="75518.7"
          y1="84150.1"
          x2="4.94724e+06"
          y2="84150.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1982_41565"
          x1="77428.7"
          y1="83269.7"
          x2="4.92857e+06"
          y2="83270.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_1982_41565"
          x1="79026.1"
          y1="82375"
          x2="4.91031e+06"
          y2="82375.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_1982_41565"
          x1="80357.4"
          y1="81638.5"
          x2="4.89248e+06"
          y2="81638.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_1982_41565"
          x1="81434.5"
          y1="80856.9"
          x2="4.87476e+06"
          y2="80857.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_1982_41565"
          x1="82262.2"
          y1="80184.9"
          x2="4.85738e+06"
          y2="80185.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_1982_41565"
          x1="82899.5"
          y1="79565.1"
          x2="4.84024e+06"
          y2="79565.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_1982_41565"
          x1="83361.1"
          y1="78993.6"
          x2="4.8233e+06"
          y2="78994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_1982_41565"
          x1="83666.9"
          y1="78468.2"
          x2="4.80532e+06"
          y2="78468.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_1982_41565"
          x1="83871"
          y1="77960.6"
          x2="4.79077e+06"
          y2="77961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_1982_41565"
          x1="84049"
          y1="77454.6"
          x2="4.77988e+06"
          y2="77455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_1982_41565"
          x1="84211.4"
          y1="76953.9"
          x2="4.77225e+06"
          y2="76954.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_1982_41565"
          x1="84402.2"
          y1="76428.9"
          x2="4.76779e+06"
          y2="76429.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_1982_41565"
          x1="84646.5"
          y1="76718.9"
          x2="4.76612e+06"
          y2="76719.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_1982_41565"
          x1="85013.2"
          y1="80551.1"
          x2="4.76689e+06"
          y2="80551.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_1982_41565"
          x1="85487.1"
          y1="84054.2"
          x2="4.76991e+06"
          y2="84054.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_1982_41565"
          x1="86150"
          y1="87179.8"
          x2="4.77468e+06"
          y2="87180.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_1982_41565"
          x1="87057.6"
          y1="89890.3"
          x2="4.78091e+06"
          y2="89890.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_1982_41565"
          x1="89556.5"
          y1="92129.3"
          x2="4.78915e+06"
          y2="92129.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_1982_41565"
          x1="99877.4"
          y1="93813.7"
          x2="4.8034e+06"
          y2="93814.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_1982_41565"
          x1="110272"
          y1="94998.4"
          x2="4.81701e+06"
          y2="94998.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_1982_41565"
          x1="120856"
          y1="95684.2"
          x2="4.82929e+06"
          y2="95684.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_1982_41565"
          x1="131288"
          y1="95811.8"
          x2="4.84065e+06"
          y2="95812.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_1982_41565"
          x1="139000"
          y1="95305.8"
          x2="4.85345e+06"
          y2="95306.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_1982_41565"
          x1="147005"
          y1="94245.3"
          x2="4.86425e+06"
          y2="94245.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_1982_41565"
          x1="155425"
          y1="92393.5"
          x2="4.87282e+06"
          y2="92393.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_1982_41565"
          x1="154872"
          y1="93723"
          x2="4.88226e+06"
          y2="93723.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_1982_41565"
          x1="154367"
          y1="94870.4"
          x2="4.89102e+06"
          y2="94870.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_1982_41565"
          x1="153917"
          y1="95688.2"
          x2="4.89889e+06"
          y2="95688.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_1982_41565"
          x1="153525"
          y1="96178.8"
          x2="4.9059e+06"
          y2="96179.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_1982_41565"
          x1="153186"
          y1="96439.2"
          x2="4.91187e+06"
          y2="96439.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_1982_41565"
          x1="152892"
          y1="96397.8"
          x2="4.91695e+06"
          y2="96398.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_1982_41565"
          x1="152670"
          y1="96089.2"
          x2="4.9211e+06"
          y2="96089.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_1982_41565"
          x1="152501"
          y1="95482"
          x2="4.92413e+06"
          y2="95482.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_1982_41565"
          x1="152400"
          y1="94612.8"
          x2="4.92605e+06"
          y2="94613.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_1982_41565"
          x1="151609"
          y1="93199.9"
          x2="4.92805e+06"
          y2="93200.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_1982_41565"
          x1="149717"
          y1="90141.4"
          x2="4.93189e+06"
          y2="90141.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_1982_41565"
          x1="148265"
          y1="86405"
          x2="4.93526e+06"
          y2="86405.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_1982_41565"
          x1="147238"
          y1="82147.1"
          x2="4.9382e+06"
          y2="82147.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_1982_41565"
          x1="146554"
          y1="77534.6"
          x2="4.94082e+06"
          y2="77535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_1982_41565"
          x1="146228"
          y1="72703.2"
          x2="4.9432e+06"
          y2="72703.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_1982_41565"
          x1="146170"
          y1="67785.3"
          x2="4.9452e+06"
          y2="67785.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_1982_41565"
          x1="146373"
          y1="62862.6"
          x2="4.94707e+06"
          y2="62863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_1982_41565"
          x1="146761"
          y1="58382.5"
          x2="4.94878e+06"
          y2="58382.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_1982_41565"
          x1="147337"
          y1="54889"
          x2="4.95039e+06"
          y2="54889.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_1982_41565"
          x1="148025"
          y1="51746.5"
          x2="4.95185e+06"
          y2="51746.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_1982_41565"
          x1="148806"
          y1="50182.7"
          x2="4.95355e+06"
          y2="50183.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_1982_41565"
          x1="149598"
          y1="51034.8"
          x2="4.95519e+06"
          y2="51035.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_1982_41565"
          x1="150399"
          y1="51973.5"
          x2="4.95725e+06"
          y2="51973.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_1982_41565"
          x1="151159"
          y1="52976.5"
          x2="4.95951e+06"
          y2="52976.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_1982_41565"
          x1="151884"
          y1="54059.2"
          x2="4.96224e+06"
          y2="54059.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint78_linear_1982_41565"
          x1="152512"
          y1="55220.8"
          x2="4.96535e+06"
          y2="55221.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint79_linear_1982_41565"
          x1="153049"
          y1="56467.5"
          x2="4.96909e+06"
          y2="56468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_1982_41565"
          x1="153466"
          y1="57808.9"
          x2="4.9735e+06"
          y2="57809.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint81_linear_1982_41565"
          x1="153798"
          y1="59197.1"
          x2="4.97884e+06"
          y2="59197.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint82_linear_1982_41565"
          x1="154063"
          y1="60563.1"
          x2="4.98463e+06"
          y2="60563.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_1982_41565"
          x1="154279"
          y1="61904"
          x2="4.99094e+06"
          y2="61904.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_1982_41565"
          x1="154457"
          y1="63207.2"
          x2="4.99771e+06"
          y2="63207.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_1982_41565"
          x1="154577"
          y1="64454.4"
          x2="5.00517e+06"
          y2="64454.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_1982_41565"
          x1="154653"
          y1="65646.3"
          x2="5.01288e+06"
          y2="65646.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_1982_41565"
          x1="154679"
          y1="66942.1"
          x2="5.02105e+06"
          y2="66942.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_1982_41565"
          x1="154666"
          y1="69150.2"
          x2="5.02959e+06"
          y2="69150.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_1982_41565"
          x1="154592"
          y1="71420.1"
          x2="5.03877e+06"
          y2="71420.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_1982_41565"
          x1="154462"
          y1="73744.8"
          x2="5.04818e+06"
          y2="73745.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_1982_41565"
          x1="154277"
          y1="76131.9"
          x2="5.05794e+06"
          y2="76132.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_1982_41565"
          x1="154039"
          y1="78605.5"
          x2="5.06807e+06"
          y2="78605.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_1982_41565"
          x1="153730"
          y1="81140.5"
          x2="5.07846e+06"
          y2="81140.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_1982_41565"
          x1="153365"
          y1="83749"
          x2="5.08911e+06"
          y2="83749.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_1982_41565"
          x1="152930"
          y1="86444.9"
          x2="5.10006e+06"
          y2="86445.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_1982_41565"
          x1="152436"
          y1="89202"
          x2="5.11119e+06"
          y2="89202.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint97_linear_1982_41565"
          x1="151874"
          y1="92064.3"
          x2="5.12266e+06"
          y2="92064.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint98_linear_1982_41565"
          x1="151247"
          y1="95002.3"
          x2="5.13408e+06"
          y2="95002.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint99_linear_1982_41565"
          x1="150538"
          y1="98049"
          x2="5.14586e+06"
          y2="98049.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint100_linear_1982_41565"
          x1="149768"
          y1="101194"
          x2="5.15778e+06"
          y2="101194"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint101_linear_1982_41565"
          x1="148920"
          y1="104462"
          x2="5.16967e+06"
          y2="104462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint102_linear_1982_41565"
          x1="148013"
          y1="107885"
          x2="5.18169e+06"
          y2="107886"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint103_linear_1982_41565"
          x1="147012"
          y1="111456"
          x2="5.19368e+06"
          y2="111456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint104_linear_1982_41565"
          x1="145950"
          y1="115270"
          x2="5.20575e+06"
          y2="115270"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint105_linear_1982_41565"
          x1="144808"
          y1="119208"
          x2="5.21773e+06"
          y2="119209"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint106_linear_1982_41565"
          x1="143588"
          y1="123250"
          x2="5.22971e+06"
          y2="123251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint107_linear_1982_41565"
          x1="142287"
          y1="127441"
          x2="5.24159e+06"
          y2="127441"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint108_linear_1982_41565"
          x1="140906"
          y1="131734"
          x2="5.25332e+06"
          y2="131734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint109_linear_1982_41565"
          x1="139459"
          y1="136113"
          x2="5.26492e+06"
          y2="136114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint110_linear_1982_41565"
          x1="137930"
          y1="140606"
          x2="5.27633e+06"
          y2="140606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint111_linear_1982_41565"
          x1="136509"
          y1="145161"
          x2="5.28722e+06"
          y2="145162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint112_linear_1982_41565"
          x1="135335"
          y1="149784"
          x2="5.29759e+06"
          y2="149784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint113_linear_1982_41565"
          x1="134181"
          y1="154413"
          x2="5.30748e+06"
          y2="154413"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint114_linear_1982_41565"
          x1="133050"
          y1="159113"
          x2="5.31699e+06"
          y2="159113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint115_linear_1982_41565"
          x1="131940"
          y1="163781"
          x2="5.32599e+06"
          y2="163782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint116_linear_1982_41565"
          x1="130835"
          y1="168453"
          x2="5.3344e+06"
          y2="168454"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint117_linear_1982_41565"
          x1="129738"
          y1="173119"
          x2="5.34232e+06"
          y2="173120"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint118_linear_1982_41565"
          x1="128659"
          y1="177119"
          x2="5.34953e+06"
          y2="177119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint119_linear_1982_41565"
          x1="127586"
          y1="181770"
          x2="5.35607e+06"
          y2="181770"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint120_linear_1982_41565"
          x1="126517"
          y1="186401"
          x2="5.36187e+06"
          y2="186402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint121_linear_1982_41565"
          x1="125454"
          y1="190981"
          x2="5.36692e+06"
          y2="190982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint122_linear_1982_41565"
          x1="124393"
          y1="195490"
          x2="5.37106e+06"
          y2="195491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
        <linearGradient
          id="paint123_linear_1982_41565"
          x1="123321"
          y1="199950"
          x2="5.37436e+06"
          y2="199951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93FFC8" />
          <stop offset="0.2" stopColor="#97FDC9" />
          <stop offset="0.39" stopColor="#A2F7CC" />
          <stop offset="0.58" stopColor="#B6EED1" />
          <stop offset="0.77" stopColor="#D1E1D8" />
          <stop offset="0.83" stopColor="#DBDCDB" />
        </linearGradient>
      </defs>
    </SvgWave>
  );
};

export default Icon;
