import * as React from 'react';
import {
  ExpandableSection,
  GridFullRow,
  GridRelay,
  getRoomIconById,
  ButtonColorType,
  Button,
  DeleteSceneModal,
  showLoader,
  ModalLoader,
  hideLoader,
  SceneTile,
} from '@streda/web_components';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { deleteScene, onSceneInvoke } from './scenes-list.logic';
import { AppContext } from '../../../../contexts/app-context/app-context';
import { AppStateActionType } from '../../../../contexts/app-context/app-context.types';

const Title = styled(GridFullRow)`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 24px;
`;

const Header = styled(GridFullRow)`
  display: flex;
  margin-top: 56px;
  justify-content: space-between;
  align-items: center;
`;

const ScenesList = function() {
  const [appContext, dispatch] = React.useContext(AppContext);
  const { rooms, docks, locationScenes, currentLocationId } = appContext;
  const history = useHistory();
  const [scenesInvokingProgress, setScenesInvokingProgress] = React.useState([]);
  const [deleteSceneModalState, setDeleteSceneModalState] = React.useState({
    visible: false,
    sceneToDeleteId: null,
    version: null,
  });

  return (
    <>
      <Header>
        <Title>Scenes</Title>
        <Button
          type="button"
          text="Add scene"
          onClick={() => {
            history.push(`/scenes/create-scene`);
          }}
          testId="save-scene-button"
          colorType={ButtonColorType.WHITE}
        />
      </Header>

      {[
        {
          name: 'Global',
          id: '',
          typeId: '',
        },
        ...rooms,
      ].map(room => (
        <ExpandableSection
          key={`room_${room.id}`}
          title={room.name}
          icon={getRoomIconById(room.typeId)}
          grid
        >
          <GridRelay>
            {locationScenes
              .filter(scene =>
                room.id ? scene.roomIds.includes(room.id) : scene.roomIds.length === 0,
              )
              .map(scene => (
                <SceneTile
                  scene={scene}
                  docks={docks}
                  pending={scenesInvokingProgress.includes(scene.id)}
                  onSceneEdit={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    history.push(`/scenes/edit-scene`, {
                      scene,
                    });
                  }}
                  onSceneDelete={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setDeleteSceneModalState({
                      visible: true,
                      sceneToDeleteId: scene.id,
                      version: scene.version,
                    });
                  }}
                  onSceneInvoke={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setScenesInvokingProgress([...scenesInvokingProgress, scene.id]);
                    onSceneInvoke(appContext.currentLocationId, scene.id).finally(() =>
                      setScenesInvokingProgress(progressIds => [
                        ...progressIds.filter(sceneId => sceneId !== scene.id),
                      ]),
                    );
                  }}
                />
              ))}
          </GridRelay>
        </ExpandableSection>
      ))}
      <DeleteSceneModal
        show={deleteSceneModalState.visible}
        onHide={() =>
          setDeleteSceneModalState({ visible: false, sceneToDeleteId: null, version: null })
        }
        onConfirm={() => {
          const { sceneToDeleteId, version } = deleteSceneModalState;
          showLoader(<ModalLoader />);
          deleteScene(sceneToDeleteId, version, currentLocationId)
            .then(sceneId =>
              dispatch({
                type: AppStateActionType.UPDATE_SCENES,
                locationScenes: locationScenes.filter(scene => scene.id !== sceneId),
              }),
            )
            .finally(() => hideLoader());
          setDeleteSceneModalState({ visible: false, sceneToDeleteId: null, version: null });
        }}
      />
    </>
  );
};

export default ScenesList;
