import styled from 'styled-components';

export const LedModeSelectionHeader = styled.p`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 14px;
  margin-bottom: 36px;
`;

export const LedModeOptionLabel = styled.p`
  color: ${({ theme }) => theme.colors.font3};
  padding-left: 35px;
`;

export const Footer = styled.div`
  padding: 24px 0 0 0;
  position: relative;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;

  :after {
    content: '';
    position: absolute;
    border-top: 1px solid ${({ theme }) => theme.colors.font3};
    left: -49px;
    top: 0;
    right: -49px;
    opacity: 0.5;
  }
`;
