import * as React from 'react';
import { CountdownTimer } from '@streda/web_components';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { GrayedDescription, StepHeader } from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import { useZigbeeOpening } from '../../../../../../utils/hooks/signalR.hooks';

const ZigbeeCountdown = function(props: ISnapInReplaceStepComponent) {
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const { onNextStep } = props;

  useZigbeeOpening(currentLocationId, () => {
    onNextStep();
  });

  return (
    <>
      <StepHeader>Opening Zigbee</StepHeader>
      <CountdownTimer time={120} size={145} onCountdownFinish={() => onNextStep(0)} />
      <GrayedDescription>
        Opening Zigbee network <br /> can take up to 2 minutes <br />
        <br />
        Please wait ...
      </GrayedDescription>
    </>
  );
};

export default ZigbeeCountdown;
