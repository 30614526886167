import * as React from 'react';
import {
  BarsIcon,
  HomeIcon,
  ImageIcon,
  IntersectIcon,
  SvgImage,
} from '@streda/web_components';
import { NavLink } from 'react-router-dom';
import { getAssetPath } from '../../../utils/images.utils';
import SettingsDropdown from '../settings-dropdown/settings-dropdown';
import UserDropdown from '../user-dropdown/user-dropdown';
import { DropdownsContainer } from './navigation-top-bar.components';

const NavigationTopBar = function() {
  return (
    <div className="navigation-top-bar">
      <div className="navigation-top-bar-left">
        <div className="navigation-top-bar-logo-container">
          <SvgImage inAssetsPath={getAssetPath('img/logo-icon.svg')} />
        </div>

        <div className="navigation-top-bar-nav-items-container">
          <NavLink
            className="navigation-top-bar-nav-item"
            to="/dashboard"
            activeClassName="navigation-top-bar-nav-item--active"
          >
            <HomeIcon className="navigation-top-bar-nav-item__stroke-icon" />
            <span className="navigation-top-bar-nav-item__text">HOME</span>
          </NavLink>

          <NavLink
            className="navigation-top-bar-nav-item"
            to="/scenes"
            activeClassName="navigation-top-bar-nav-item--active"
          >
            <ImageIcon className="navigation-top-bar-nav-item__stroke-icon" />
            <span className="navigation-top-bar-nav-item__text">SCENES</span>
          </NavLink>
          <NavLink
            className="navigation-top-bar-nav-item"
            to="/analytics"
            activeClassName="navigation-top-bar-nav-item--active"
          >
            <BarsIcon className="navigation-top-bar-nav-item__stroke-icon" />
            <span className="navigation-top-bar-nav-item__text">ANALYTICS</span>
          </NavLink>
          <NavLink
            className="navigation-top-bar-nav-item"
            to="/external-devices"
            activeClassName="navigation-top-bar-nav-item--active"
          >
            <IntersectIcon className="navigation-top-bar-nav-item__stroke-icon" />
            <span className="navigation-top-bar-nav-item__text">EXTERNAL DEVICES</span>
          </NavLink>
        </div>
      </div>
      <DropdownsContainer>
        <SettingsDropdown />
        <UserDropdown />
      </DropdownsContainer>
    </div>
  );
};

export default NavigationTopBar;
