import * as React from 'react';
import styled from 'styled-components';
import _ = require('lodash');
import { space, SpaceProps } from 'styled-system';
import { Text } from '@streda/web_components';
import { Asset } from 'contentful';
import { EnvelopeIcon, PhoneIcon } from './components/icons';
import { AppContext } from '../../../contexts/app-context/app-context';

const Flexibility = require('./components/icons/Flexibility.png');
const Integration = require('./components/icons/Integration.png');
const Simplicity = require('./components/icons/Simplicity.png');

const MAIN_ICON_HEIGHT = 63;
const MAIN_ICON_WIDTH = 61;

export interface IContactPageFields {
  contactPageTitle?: string | undefined;
  companyLocationInfo?: Record<string, any> | undefined;
  memberIcon?: Asset | undefined;
  memberDescription?: string | undefined;
  companyContactInfo?: Record<string, any> | undefined;
  supportDescription?: string | undefined;
  supportHours?: string | undefined;
  supportContactInfo?: Record<string, any> | undefined;
}

const StyledContactIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 140px;
  grid-column: span 8;
  ${({ theme }) => theme.mediaQueries.medium} {
    grid-column: span 12;
  }
`;

const IconWrapper = styled.div<SpaceProps>`
  margin-right: 32px;
  ${space};
`;

const ContactHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 0 90px;
  grid-column: span 8;
  ${({ theme }) => theme.mediaQueries.medium} {
    grid-column: span 12;
  }
`;

const ContactHeaderText = styled(Text)`
  font-weight: 500;
  font-size: 40px;
  ${({ theme }) => theme.mediaQueries.medium} {
    font-size: 48px;
  }
  line-height: 32px;
  letter-spacing: 0.18px;
  color: rgba(255, 255, 255, 0.87);
`;

const ContactTale = styled.div<{ lighter?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 291px;
  grid-column: span 8;
  padding: 48px 56px;
  background: ${({ lighter }) => (lighter ? '#5A5B61' : '#45464c')};
`;

const TaleTitle = styled(Text)`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: rgba(255, 255, 255, 0.87);
`;

const TaleCellWrapper = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-top: 24px;
`;

const TaleCell = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  ${space};
`;

const TaleInfoDetails = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 8px;
`;

const TaleContactDetails = styled(Text)<{ column?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 16px;
  ${({ column }) =>
    column &&
    `
      p {
        margin-bottom: 6px;
      };
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  `}
  a {
    color: rgba(255, 255, 255, 0.87) !important;
    text-decoration: none;
  }
`;

interface IContactProps {}

const supportPageData = {
  companyLocationInfo: {
    city: '3072 AP Rotterdam',
    companyName: 'Isolectra B.V',
    projectName: '© Streda ',
    street: 'Wilhelminakade 957',
  },
  companyContactInfo: {
    email: 'info@streda.eu',
    phone: '+31 10 285 54 44',
    website: 'www.streda.eu',
  },
  contactPageTitle: 'A flexible smart home system',
};
const Contact: React.FunctionComponent<IContactProps> = function() {
  const [appContext] = React.useContext(AppContext);
  const { companyDetails, locationDetails } = appContext;

  if (_.isUndefined(companyDetails)) {
    return null;
  }

  const {
    name = supportPageData?.companyLocationInfo?.companyName,
    city = supportPageData?.companyLocationInfo?.city,
    street = supportPageData?.companyLocationInfo?.street,
    email = supportPageData?.companyContactInfo?.email,
    country,
    zipCode,
    phoneNumber = supportPageData?.companyContactInfo?.phone,
  } = companyDetails || {};
  const emailAddress = locationDetails?.contactDetailsEmail || email;
  const phone = locationDetails?.contactDetailsPhoneNumber || phoneNumber;

  return (
    <>
      <StyledContactIcons>
        <IconWrapper>
          <img
            width={MAIN_ICON_WIDTH}
            height={MAIN_ICON_HEIGHT}
            src={Flexibility.default}
            alt="Flexibility"
          />
        </IconWrapper>
        <IconWrapper>
          <img
            width={MAIN_ICON_WIDTH}
            height={MAIN_ICON_HEIGHT}
            src={Integration.default}
            alt="Integration"
          />
        </IconWrapper>
        <IconWrapper>
          <img
            width={MAIN_ICON_WIDTH}
            height={MAIN_ICON_HEIGHT}
            src={Simplicity.default}
            alt="Integration"
          />
        </IconWrapper>
      </StyledContactIcons>
      <ContactHeaderWrapper>
        <ContactHeaderText>{supportPageData?.contactPageTitle}</ContactHeaderText>
      </ContactHeaderWrapper>
      <ContactTale>
        <TaleTitle>Contact us</TaleTitle>
        <TaleCellWrapper>
          <TaleCell>
            <TaleInfoDetails>{name}</TaleInfoDetails>
            <TaleInfoDetails>{street}</TaleInfoDetails>
            <TaleInfoDetails>{`${zipCode} ${city}`}</TaleInfoDetails>
            <TaleInfoDetails>{country}</TaleInfoDetails>
          </TaleCell>
          <TaleCell ml={40}>
            <TaleContactDetails>
              <IconWrapper mr={16}>
                <PhoneIcon />
              </IconWrapper>
              <a href={`tel:${phone}`}>{phone}</a>
            </TaleContactDetails>
            <TaleContactDetails>
              <IconWrapper mr={16}>
                <EnvelopeIcon />
              </IconWrapper>
              <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </TaleContactDetails>
          </TaleCell>
        </TaleCellWrapper>
      </ContactTale>
    </>
  );
};

export default Contact;
