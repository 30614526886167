import * as React from 'react';
import {
  ButtonColorType,
  getProfileNameBySymbol,
  getSnapInIcon,
  ProfilesEnums,
  usePromise,
} from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { ActionBtn, ActionButtonsContainer, StepHeader } from '../../../snap-in-replace.components';
import {
  SnapInDetailContainer,
  SnapInDetailLabel,
  SnapInDetailValue,
} from './snap-in-details.components';
import { getDocksPositions, getSnapInTypes } from '../../../../../../utils/api/dictionary';
import { INextStepFunction, ISnapInDetails } from './snap-in-details.types';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import NewSnapInContext from '../../add-new-snap-in.context';

const SnapInDetails: React.FC<ISnapInDetails<INextStepFunction>> = function({
  dock,
  room,
  onNextStep,
  actionBtnText = 'Next',
}) {
  const { number, cableType, positionId } = dock;
  const appContext = React.useContext(AppContext);
  const newSnapInContext = React.useContext(NewSnapInContext);
  const { newSnapIn } = newSnapInContext;
  const { snapInCode } = newSnapIn;
  const SnapInImg = getSnapInIcon(snapInCode);
  const getSnapInTypesPromise = React.useMemo(getSnapInTypes, []);
  const [snapInTypes] = usePromise(getSnapInTypesPromise, []);
  const getDockPositions = React.useMemo(getDocksPositions, []);
  const [dockPositions] = usePromise(getDockPositions, []);
  const snapInType = snapInTypes.find(({ symbol }) => symbol === snapInCode) || {};
  const dockPosition = dockPositions[positionId];
  const snapInProfileSymbol = snapInCode.charAt(0) as ProfilesEnums;
  const profileName = getProfileNameBySymbol(snapInProfileSymbol);
  const history = useHistory();

  return (
    <>
      <StepHeader>Add new snap-in</StepHeader>
      <SnapInImg height={112} width="100%" />
      <div>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Snap-in type</SnapInDetailLabel>
          <SnapInDetailValue>{snapInType.name}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Profile</SnapInDetailLabel>
          <SnapInDetailValue>{profileName}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Dock type & ID</SnapInDetailLabel>
          <SnapInDetailValue>
            {snapInCode} - {number}
          </SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Cable type</SnapInDetailLabel>
          <SnapInDetailValue>{cableType}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Room</SnapInDetailLabel>
          <SnapInDetailValue>{room.name}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Position</SnapInDetailLabel>
          <SnapInDetailValue>{dockPosition}</SnapInDetailValue>
        </SnapInDetailContainer>
      </div>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text={actionBtnText}
          onClick={() => onNextStep(dock, room, appContext, history)}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default SnapInDetails;
