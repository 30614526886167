import * as React from 'react';
import {
  ActionModal,
  TimelineOptions,
  getAssetPath,
  getLocationsSnapshots,
  GridFullRow,
} from '@streda/web_components';
import { AppContext } from '../../../contexts/app-context/app-context';
import { prepareBackupsChainOption, onBackupRestore } from './settings.logic';
import { getAccount } from '../../../utils/auth.utils';

const Settings = () => {
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const [locationBackups, setLocationBackups] = React.useState([]);
  const [showRestoreModal, setShowRestoreModal] = React.useState({
    show: false,
    snapshotId: null,
  });

  React.useEffect(() => {
    if (currentLocationId) {
      getLocationsSnapshots(getAccount, currentLocationId).then((snapshots: any) =>
        setLocationBackups(snapshots.data),
      );
    }
  }, [currentLocationId]);

  return (
    <GridFullRow>
      <div className="settings-title-container">
        <p className="settings-title">System settings</p>
      </div>
      <div className="settings-section">
        <div className="settings-param-wrapper">
          <div className="settings-param-description">
            <span className="settings-param-description-primary">Configuration history</span>
            <span className="settings-param-description-secondary">
              Restore previous configuration and settings
            </span>
          </div>

          <TimelineOptions
            items={prepareBackupsChainOption(locationBackups, setShowRestoreModal)}
          />
        </div>
      </div>

      <ActionModal
        onHide={() => setShowRestoreModal({ ...showRestoreModal, show: false })}
        onConfirm={() => {
          setShowRestoreModal({ ...showRestoreModal, show: false });
          onBackupRestore(currentLocationId, showRestoreModal.snapshotId);
        }}
        show={showRestoreModal.show}
        icon={getAssetPath('icons/configuration-icon.svg')}
        title=""
        header="Restore backup?"
        description="All connections & configured elements will be sent to SCB"
      />
    </GridFullRow>
  );
};

export default Settings;
