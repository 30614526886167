import * as React from 'react';
import { ButtonColorType, SnapInRegistrationImg } from '@streda/web_components';
import {
  ActionBtn,
  ActionButtonsContainer,
  Description,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import LabeledInput from './put-serial-number.components';
import NewSnapInContext from '../../add-new-snap-in.context';

const PutSerialNumber: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  const [serialNumber, setSerialNumber] = React.useState<string>('');
  const { setNewSnapIn } = React.useContext(NewSnapInContext);

  return (
    <>
      <StepHeader>Add new snap-in</StepHeader>
      <Description>
        Each snap-in has a unique serial number near QR code needed for registering
      </Description>
      <SnapInRegistrationImg />
      <LabeledInput
        placeholder="Type serial number here"
        value={serialNumber}
        onChange={val => setSerialNumber(val)}
        label="Serial number"
      />
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Add new snap-in"
          onClick={() => {
            setNewSnapIn({ serialNumber });
            onNextStep();
          }}
          disabled={!serialNumber}
          colorType={ButtonColorType.PRIMARY}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default PutSerialNumber;
