import * as React from 'react';
import { Toast } from '@streda/web_components';
import { ISnapInReplaceStepComponent, IStep } from '../../snap-in-replace.types';
import MountInstruction from './mount-instruction/mount-instruction';
import { initAppState } from '../../../../main-view.logic';
import { StepContentWrapper } from '../../snap-in-replace.components';

const stepsDefinition: IStep<ISnapInReplaceStepComponent>[] = [
  {
    Component: MountInstruction,
    props: {
      onNextStep: (dock, room, appContext, history) => {
        const [globalContext, dispatchGlobalAction] = appContext;
        const { currentLocationId } = globalContext;

        initAppState(currentLocationId, dispatchGlobalAction).then(() => {
          history.push(`/dashboard/room/${room.id}`);
          Toast.showToast({
            message: 'Success! Snap-ins have been swapped',
            colorType: 'Success',
          });
        });
      },
    },
  },
];

const MountSnapIn: React.FC<ISnapInReplaceStepComponent> = function({
  onNextStep,
  dock,
  snapIn,
  room,
}) {
  const [step, setStep] = React.useState(0);
  const { Component, props } = stepsDefinition[step];

  return (
    <StepContentWrapper>
      <Component
        onNextStep={(stepNumber = step + 1) => {
          if (!stepsDefinition[stepNumber]) {
            onNextStep();
            return;
          }
          setStep(stepNumber);
        }}
        dock={dock}
        snapIn={snapIn}
        room={room}
        {...props}
      />
    </StepContentWrapper>
  );
};

export default MountSnapIn;
