import * as React from 'react';
import { ArrowsLoopIcon, ButtonColorType } from '@streda/web_components';
import {
  ActionBtn,
  Container,
  Content,
  Description,
  DetailsContainer,
  Title,
} from './ContinueReplacing.components';
import { IContinueReplacing } from './ContinueReplacing.types';
import {
  SnapInDetailContainer,
  SnapInDetailLabel,
  SnapInDetailValue,
} from '../registration/snap-in-details/snap-in-details.components';

const ContinueReplacing: React.FC<IContinueReplacing> = function({ onConfirm, dock, room }) {
  return (
    <Container>
      <Content>
        <Title>Continue Process</Title>
        <ArrowsLoopIcon width={116} height={116} />
        <div>
          <Description>
            We have an ongoing replacing process that requires your actions. Please take a moment to
            review and proceed next steps.
          </Description>
        </div>
        <DetailsContainer>
          <SnapInDetailContainer>
            <SnapInDetailLabel>Room: </SnapInDetailLabel>
            <SnapInDetailValue>{room.name}</SnapInDetailValue>
          </SnapInDetailContainer>
          <SnapInDetailContainer>
            <SnapInDetailLabel>Dock type & number: </SnapInDetailLabel>
            <SnapInDetailValue>
              {dock.dockCode} - {dock.number}
            </SnapInDetailValue>
          </SnapInDetailContainer>
        </DetailsContainer>
        <ActionBtn
          type="button"
          text="Continue"
          onClick={() => onConfirm()}
          colorType={ButtonColorType.WHITE}
        />
      </Content>
    </Container>
  );
};

export default ContinueReplacing;
