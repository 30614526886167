import * as React from 'react';
import { RowOptions, SettingsIcon, updateQueryParams } from '@streda/web_components';
import { useHistory, Location, History, useLocation } from 'react-router-dom';

const SettingsDropdown = function() {
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <RowOptions
        alignRight
        toggleComponent={
          <div>
            <SettingsIcon />
          </div>
        }
        options={[
          {
            value: 'Configuration panel',
            textClass: 'settings-option-text',
            onClick: () => history.push('/connections'),
          },
          {
            textClass: 'settings-option-text',
            value: "LED's Home Settings",
            onClick: () =>
              updateQueryParams(
                {
                  ledsHomeSettings: '',
                },
                history as History,
                location as Location,
              ),
          },
          {
            textClass: 'settings-option-text',
            value: 'System settings',
            onClick: () => history.push('/settings'),
          },
        ]}
      />
    </div>
  );
};

export default SettingsDropdown;
