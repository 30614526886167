import { createContext } from 'react';
import { AppState } from './app-context.types';

export const initAppState: AppState = {
  // assignedLocations: [],
  currentLocationId: '',
  rooms: [],
  docks: [],
  locationScenes: [],
  externalDevices: [],
  locationDetails: {},
  companyDetails: undefined,
};

export const AppContext = createContext<[AppState, any]>([initAppState, null]);

export default {
  AppContext,
  initAppState,
};
