import * as React from 'react';
import {
  ColorSliderPicker,
  DeviceStateType,
  ExternalClusterNameEnums,
  getExternalDeviceCluster,
  ledBrightnessPercentConvert,
  useDebounce,
} from '@streda/web_components';
import { performDeviceAction } from '../../external-device-tile.logic';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { IActionComponent } from '../../external-device-tile.types';

const BrightnessSlider: React.FC<IActionComponent> = function({
  externalDevice,
  deviceStates,
  disabled,
  setPending,
}) {
  const brightnessCluster = getExternalDeviceCluster(
    externalDevice,
    ExternalClusterNameEnums.BRIGHTNESS,
  );

  const brightnessState = deviceStates.find(
    state => state.type === DeviceStateType.BRIGHTNESS_STATE,
  )?.data.value;
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const [brightness, setBrightness] = React.useState<number>(brightnessState || 0);
  const debouncedBrightness = useDebounce<number>(brightness, 500);

  React.useEffect(() => {
    if (brightnessState === brightness) {
      return;
    }

    setBrightness(brightnessState);
  }, [brightnessState]);

  React.useEffect(() => {
    if (!debouncedBrightness || debouncedBrightness === brightnessState) {
      return;
    }

    setPending(true);
    performDeviceAction<number>(
      externalDevice,
      currentLocationId,
      ExternalClusterNameEnums.BRIGHTNESS,
      debouncedBrightness,
    )
      .catch(() => setBrightness(brightnessState))
      .finally(() => setPending(false));
  }, [debouncedBrightness]);

  if (!brightnessCluster) {
    return null;
  }

  return (
    <ColorSliderPicker
      gradientColors={['#afafb0', '#FFF']}
      onChange={value => setBrightness(value)}
      value={brightness}
      label={`${ledBrightnessPercentConvert(brightnessState)}%`}
      min={brightnessCluster.valueMin}
      max={brightnessCluster.valueMax}
      disabled={disabled}
    />
  );
};

export default BrightnessSlider;
