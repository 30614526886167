import * as React from 'react';
import { getAccount, getUserApi, logout, LogoutIcon, RowOptions } from '@streda/web_components';
import { IMsalContext, useMsal } from '@azure/msal-react';
import {
  Email,
  UserAvatar,
  UserInfo,
  UserInfoToggleContainer,
  UserName,
} from './user-dropdown.components';
import { IUserData } from './user-dropdown.types';

const UserDropdown = function() {
  const account = getAccount();
  const { userId } = account;
  const [userData, setUserData] = React.useState<IUserData>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const msalContext: IMsalContext = useMsal();
  const { email = '', lastName = '', firstName = '' } = userData;
  const getUserInitials = () => `${firstName && firstName[0]}${lastName && lastName[0]}`;

  React.useEffect(() => {
    getUserApi(account)
      .userGetCurrentUserInformation(userId)
      .then(result => {
        const { firstName, lastName, email } = result.data;

        setUserData({ firstName, lastName, email });
      });
  }, []);

  return (
    <RowOptions
      alignRight
      toggleComponent={<UserAvatar>{getUserInitials()}</UserAvatar>}
      options={[
        {
          value: (
            <UserInfoToggleContainer>
              <UserAvatar width={40} height={40}>
                {getUserInitials()}
              </UserAvatar>
              <UserInfo>
                <UserName>
                  {firstName} {lastName}
                </UserName>
                <Email>{email}</Email>
              </UserInfo>
            </UserInfoToggleContainer>
          ),
        },
        {
          value: 'Log out',
          icon: <LogoutIcon width={16} height={16} />,
          onClick: () => logout(msalContext),
        },
      ]}
    />
  );
};

export default UserDropdown;
