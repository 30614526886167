import * as React from 'react';
import {
  ButtonColorType,
  Checkbox,
  SnapInStatusEnum2,
  SnapInTestingImg,
} from '@streda/web_components';
import {
  ActionBtn,
  ActionButtonsContainer,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import { updateSnapInStatus } from '../../../../../../utils/api/snap-in.api';
import NewSnapInContext from '../../add-new-snap-in.context';

const TestingInstruction: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  const [snapInGreenLight, setSnapInGreenLight] = React.useState<boolean>(false);
  const { newSnapIn, setNewSnapIn } = React.useContext(NewSnapInContext);
  const { serialNumber } = newSnapIn;

  React.useEffect(() => {
    if (!serialNumber) {
      return;
    }

    updateSnapInStatus(serialNumber, SnapInStatusEnum2.UnderTesting);
  }, [serialNumber]);

  return (
    <>
      <StepHeader>Testing snap-in</StepHeader>
      <SnapInTestingImg />
      <GrayedDescription>
        Confirm in the application that the selected Snap-in is blinking.
      </GrayedDescription>
      <Checkbox
        label="Confirm that light is blinking"
        onChange={event => setSnapInGreenLight(event.target.checked)}
      />
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Confirm"
          onClick={() =>
            updateSnapInStatus(serialNumber, SnapInStatusEnum2.Tested).finally(() => {
              setNewSnapIn({ snapInStatus: SnapInStatusEnum2.Tested });
              onNextStep();
            })
          }
          disabled={!snapInGreenLight}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default TestingInstruction;
