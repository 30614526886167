import * as React from 'react';
import {
  AnalyticsHeaderRow,
  ConsumptionBox,
  LocationAnalyticsTable,
  LocationPowerConsumptionParams,
  RoomLevelPowerConsumptionDto,
  RoomAnalyticsModal,
  GridFullRow,
} from '@streda/web_components';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import _ = require('lodash');
import {
  roomModalHandler,
  useAnalyticsDispatch,
  useAnalyticsState,
  useLocationPowerConsumption,
  useRoomPowerConsumption,
} from '../../../contexts/Analytics/Analytics';
import { AppContext } from '../../../contexts/app-context/app-context';
import { loadData } from './analytics-logic';

export const ConsuptionsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  height: 133px;
  margin-top: 30px;
`;

export const StyledConsumptionSpacer = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.white05};
  opacity: 0.5;
  width: 100%;
  margin: 32px 0px;
`;

const HeaderTooltipContainer = styled.div`
  width: 300px;
`;

const AnalyticsPage = styled(GridFullRow)`
  margin-top: 57px;
`;

export const getLocationConsumptions = (
  rooms: RoomLevelPowerConsumptionDto[],
): { power: number; sockets: number; relays: number } => {
  if (_.isEmpty(rooms)) {
    return { power: 0, sockets: 0, relays: 0 };
  }
  const power = _.sum(rooms.map(room => room?.roomPowerConsumption?.power || 0));
  const sockets = _.sum(rooms.map(room => room?.smartPowerConsumption?.power || 0));
  const relays = _.sum(rooms.map(room => room?.relayPowerConsumption?.power || 0));
  return { power, sockets, relays };
};

const refreshPowerConsumptionInterval = 10000;
const Analytics: React.FunctionComponent = function() {
  const { roomId } = useParams() as {
    roomId: string | undefined;
  };

  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const [refreshPowerConsumption, setRefreshPowerConsumption] = React.useState(0);
  const [locationParams, setLocationParams] = React.useState({
    pageNumber: 0,
    locationId: currentLocationId,
  } as LocationPowerConsumptionParams);

  React.useEffect(() => {
    setLocationParams({ ...locationParams, locationId: currentLocationId });
  }, [currentLocationId]);

  React.useEffect(() => {
    const interval = setInterval(
      () => setRefreshPowerConsumption(refreshPowerConsumption + 1),
      refreshPowerConsumptionInterval,
    );

    return () => clearInterval(interval);
  }, [refreshPowerConsumption]);

  const history = useHistory();
  const dispatch = useAnalyticsDispatch();
  const { roomModalOpen } = useAnalyticsState();
  const { locationData } = useLocationPowerConsumption(locationParams, refreshPowerConsumption);
  const { roomData } = useRoomPowerConsumption(
    { locationId: currentLocationId, roomId, pageNumber: 0 },
    history,
  );
  const { power, sockets, relays } = getLocationConsumptions(locationData.tableData);

  React.useEffect(() => {
    loadData(locationData.loadingLocationData);
  }, [locationData.loadingLocationData]);

  return (
    <AnalyticsPage>
      <AnalyticsHeaderRow
        headerTitle="Power consumption"
        tooltipMessage={
          <HeaderTooltipContainer>
            <p>
              <b>Power Consumption</b>
            </p>
            <p>
              Streda System is measuring total current energy consumption in your home from all
              relays & smart power sockets.
            </p>
            <p>Measuring is only from Smart and Ultimate Snap-ins</p>
          </HeaderTooltipContainer>
        }
      />
      <ConsuptionsRow>
        <ConsumptionBox currentConsumption={power} description="Power Consumption" />
        <ConsumptionBox currentConsumption={sockets} description="Sockets Consumption" />
        <ConsumptionBox currentConsumption={relays} description="Relays Consumption" />
      </ConsuptionsRow>
      <StyledConsumptionSpacer />
      <LocationAnalyticsTable
        tableData={locationData.tableData}
        onRowClick={async id => {
          history.push(`analytics/${id}`);
          roomModalHandler(dispatch, true);
        }}
        onSortChange={sortEvent =>
          setLocationParams({
            ...locationParams,
            orderBy: sortEvent.key,
            descending: sortEvent.sortDirection === 'desc',
          })
        }
      />
      <RoomAnalyticsModal
        roomModalOpen={roomModalOpen}
        roomModalHandler={open => {
          history.goBack();
          roomModalHandler(dispatch, open);
        }}
        roomData={roomData.roomPowerConsumption}
      />
    </AnalyticsPage>
  );
};

export default Analytics;
