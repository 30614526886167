import * as React from 'react';
import { SignalRListenersEnums, SignalRMethodEnums, SignalR } from '@streda/web_components';
import { ZigbeeNetworkStateEnums } from '../../components/pages/snap-in-replace/add-new-snap-in/pairing/zigbee-countdown/zigbee-countdown.types';

export const useZigbeeOpening = (currentLocationId, onSuccess) => {
  const signalR = SignalR.getInstance();

  React.useEffect(() => {
    signalR.listenOnMethod(SignalRListenersEnums.ZIGBEE_NETWORK_STATE_NOTIFICATION, data => {
      const { state } = data;

      if (state === ZigbeeNetworkStateEnums.OPENED) {
        onSuccess();
      }
    });
    signalR.invokeMethod(SignalRMethodEnums.SUBSCRIBE_ZIGBEE_NETWORK_STATE, currentLocationId);

    return () => {
      signalR.invokeMethod(SignalRMethodEnums.UNSUBSCRIBE_ZIGBEE_NETWORK_STATE, currentLocationId);
      signalR.stopListenOnMethod(SignalRListenersEnums.ZIGBEE_NETWORK_STATE_NOTIFICATION);
    };
  }, []);
};

export default { useZigbeeOpening };
