export enum DeviceClusterTypeEnums {
  BRIGHTNESS_CLUSTER = 'BrightnessCluster',
  STATE_CLUSTER = 'StateCluster',
  COLOR_TEMP_CLUSTER = 'ColorTemperatureCluster',
  COLOR_XY_CLUSTER = 'ColorXYCluster',
}

export enum DeviceStatusEnums {
  UNRESOLVED = 'Unresolved',
  ACCEPTED = 'Accepted',
}
