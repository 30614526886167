import {
  getExternalProductsApi,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { getAccount } from '../../../../../../utils/auth.utils';
import { DeviceStatusEnums } from './captured-device-step.types';

export const onDeviceAccept = (locationId: string, zigbeeId: string) =>
  getExternalProductsApi(getAccount())
    .externalProductChangeExternalProductStatus(locationId, zigbeeId, DeviceStatusEnums.ACCEPTED)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during changing external device state. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const onDeviceReject = (locationId: string, zigbeeId: string) =>
  getExternalProductsApi(getAccount())
    .externalProductRemoveExternalProductWithZigbeeId(locationId, zigbeeId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during removing external device. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const getExternalDeviceByZigbeeId = (locationId: string, zigbeeId: string) =>
  getExternalProductsApi(getAccount())
    .externalProductGetExternalProductWithZigbeeId(locationId, zigbeeId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during getting external device. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export default {
  getExternalDeviceByZigbeeId,
};
