/* eslint-disable no-unused-expressions */
import * as React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import DashboardMainView from './dashbaord-main-view/dashboard-main-view';
import DashboardRoomView from './dashboard-room-view/dashboard-room-view';
import Wave from './wave';

const Dashboard = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Wave />
      <Route exact path={url}>
        <DashboardMainView />
      </Route>
      <Route path={`${url}/room/:roomId`}>
        <DashboardRoomView />
      </Route>
    </>
  );
};

export default Dashboard;
