import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { SuccessIcon } from '@streda/web_components';
import { StepHeader, StepParagraph } from '../../../../../common/overlay-modal-steps/overlay-modal-steps';

const OpeningSuccess = function(props) {
  const { colors } = React.useContext(ThemeContext);
  const { onNextStep } = props;

  React.useEffect(() => {
    setTimeout(onNextStep, 3000);
  }, []);

  return (
    <>
      <StepHeader>Zigbee opened</StepHeader>
      <SuccessIcon width={145} height={145} fill={colors.input} />
      <StepParagraph>Success!</StepParagraph>
    </>
  );
};

export default OpeningSuccess;
