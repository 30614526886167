import * as React from 'react';
import {
  Button,
  ButtonColorType,
  LedStripModeEnum,
  RadioInput,
  SnapInConfigurationEnum,
  ToastHelper,
  ToastType,
  LedStripModeLabels,
  LedStripModeDescriptions,
} from '@streda/web_components';
import {
  Footer,
  LedModeOptionLabel,
  LedModeSelectionHeader,
} from './leds-mode-selection.components';
import { AppContext } from '../../../contexts/app-context/app-context';
import { ILedsConfigHandling } from '../leds-settings.types';

const LedsModeSelection: React.FC<ILedsConfigHandling> = function({
  configurationProvider,
  onConfigurationChange,
  ledsLevel,
}) {
  const [ledMode, setLedMode] = React.useState<LedStripModeEnum>();
  const [pending, setPending] = React.useState<boolean>(false);
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;

  React.useEffect(() => {
    if (!currentLocationId) {
      return;
    }

    configurationProvider().then(result => {
      const mode = result.snapInConfiguration.find(
        ({ Type }) => Type === SnapInConfigurationEnum.LED_STRIP_MODE_OPTION,
      )?.value;

      if (mode) {
        setLedMode(mode);
      }
    });
  }, [currentLocationId]);

  const onSave = () => {
    const configuration = {
      snapInConfiguration: [
        {
          Type: SnapInConfigurationEnum.LED_STRIP_MODE_OPTION,
          Value: ledMode,
        },
      ],
    };

    setPending(true);
    onConfigurationChange(configuration)
      .then(() => {
        setPending(false);
        ToastHelper(ToastType.SUCCESS, 'Success!. LED strip mode has been changed');
      })
      .catch(() => {
        setPending(false);
      });
  };

  return (
    <div>
      <LedModeSelectionHeader>
        LED mode for all devices located in {ledsLevel}
      </LedModeSelectionHeader>
      {ledsLevel === 'Room' && (
        <>
          <RadioInput
            name="led-mode-select"
            label={LedStripModeLabels[LedStripModeEnum.NONE]}
            value={LedStripModeEnum.NONE}
            checked={ledMode === LedStripModeEnum.NONE}
            onChange={event => setLedMode(event.currentTarget.value as LedStripModeEnum)}
          />
          <LedModeOptionLabel>{LedStripModeDescriptions[LedStripModeEnum.NONE]}</LedModeOptionLabel>
        </>
      )}
      <RadioInput
        name="led-mode-select"
        label={LedStripModeLabels[LedStripModeEnum.ALWAYS_OFF]}
        value={LedStripModeEnum.ALWAYS_OFF}
        checked={ledMode === LedStripModeEnum.ALWAYS_OFF}
        onChange={event => setLedMode(event.currentTarget.value as LedStripModeEnum)}
      />
      <LedModeOptionLabel>
        {LedStripModeDescriptions[LedStripModeEnum.ALWAYS_OFF]}
      </LedModeOptionLabel>
      <RadioInput
        name="led-mode-select"
        label={LedStripModeLabels[LedStripModeEnum.FULL_CONTROL]}
        value={LedStripModeEnum.FULL_CONTROL}
        checked={ledMode === LedStripModeEnum.FULL_CONTROL}
        onChange={event => setLedMode(event.currentTarget.value as LedStripModeEnum)}
      />
      <LedModeOptionLabel>
        {LedStripModeDescriptions[LedStripModeEnum.FULL_CONTROL]}
      </LedModeOptionLabel>
      <RadioInput
        name="led-mode-select"
        label={LedStripModeLabels[LedStripModeEnum.FEEDBACK]}
        value={LedStripModeEnum.FEEDBACK}
        checked={ledMode === LedStripModeEnum.FEEDBACK}
        onChange={event => setLedMode(event.currentTarget.value as LedStripModeEnum)}
      />
      <LedModeOptionLabel>{LedStripModeDescriptions[LedStripModeEnum.FEEDBACK]}</LedModeOptionLabel>
      <Footer>
        <Button
          type="button"
          text="Save"
          pending={pending}
          onClick={onSave}
          testId="save-led-home-settings"
          colorType={ButtonColorType.PRIMARY}
        />
      </Footer>
    </div>
  );
};

export default LedsModeSelection;
