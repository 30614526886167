import * as React from 'react';
import { AppContext, initAppState } from './app-context';
import { AppContextReducer } from './app-context.reducers';

const AppContextProvider = props => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(AppContextReducer, initAppState);

  return <AppContext.Provider value={[state, dispatch]}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
