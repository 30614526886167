import styled from 'styled-components';
import { Modal } from '@streda/web_components';

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 16px 32px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const StyledHorizontalLine = styled.hr`
  width: 100%;
  background: ${({ theme }) => theme.colors.background3};
  height: 2px;
`;
