import * as React from 'react';
import { SuccessIcon } from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import { GrayedDescription, StepHeader } from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';

const ZigbeeOpenStatus: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { colors } = React.useContext(ThemeContext);
  const { onNextStep } = props;

  React.useEffect(() => {
    setTimeout(() => onNextStep(), 3000);
  }, []);

  return (
    <>
      <StepHeader>Zigbee opened</StepHeader>
      <SuccessIcon fill={colors.secondary} width={145} height={145} />
      <GrayedDescription>Success!</GrayedDescription>
    </>
  );
};

export default ZigbeeOpenStatus;
