import styled from 'styled-components';

export const SnapInDetailLabel = styled.span`
  color: ${({ theme }) => theme.colors.font3};
  margin-right: 8px;
`;
export const SnapInDetailValue = styled.span`
  color: ${({ theme }) => theme.colors.font1};
  text-transform: capitalize;
`;

export const SnapInDetailContainer = styled.p`
  text-align: left;
`;
