import * as React from 'react';
import {
  ActionModal,
  Button,
  ButtonColorType,
  ConfigSyncIndicator,
  DashboardDocks,
  DeviceConfigurationsContext,
  FrameLedIcon,
  getConnectionsConfigurationSyncStatus,
  updateQueryParams,
} from '@streda/web_components';
import { withRouter, History, Location, useLocation, useHistory } from 'react-router-dom';
import DashboardScenes from '../dashboard-scenes/dashboard-scenes';
import { filterRoomScenes } from './dashboard-room-view.logic';
import { AppContext } from '../../../../contexts/app-context/app-context';
import { Header, SectionLabel } from './dashboard-room-view.components';
import { ExternalDeviceTile } from '../../external-devices/external-device-tile/external-device-tile';
import LedsRoomSettings from './leds-room-settings/leds-room-settings';
import { initAppState } from '../../../main-view.logic';
import { activateLocation } from '../../connections/connections.api';

const DashboardRoomView = function(props) {
  const { match } = props;
  const [appContext, dispatchGlobalAction] = React.useContext(AppContext);
  const deviceConfigurations = React.useContext(DeviceConfigurationsContext);
  const [isConfigurationSynced, setIsConfigurationSynced] = React.useState<boolean>(false);
  const [showConfigActivationModal, setShowConfigActivationModal] = React.useState<boolean>(false);
  const {
    docks,
    currentLocationId,
    rooms,
    locationScenes,
    externalDevices,
    locationDetails,
  } = appContext;
  const { params } = match;
  const { roomId } = params;
  const history = useHistory();
  const location = useLocation();
  const room = rooms.find(room => room.id === roomId) || {};

  React.useEffect(() => {
    if (!appContext.currentLocationId) {
      return;
    }

    getConnectionsConfigurationSyncStatus(appContext.currentLocationId).then(result =>
      setIsConfigurationSynced(result),
    );
  }, [appContext.currentLocationId]);

  React.useEffect(() => setIsConfigurationSynced(false), [deviceConfigurations]);

  return (
    <>
      <Header>
        <ConfigSyncIndicator synchronized={isConfigurationSynced} />
        <Button
          type="button"
          text="Activate changes"
          onClick={() => setShowConfigActivationModal(true)}
          testId="activate-changes-button"
          colorType={ButtonColorType.WHITE}
          disabled={isConfigurationSynced}
        />
        <Button
          type="button"
          text="LED's Room Settings"
          onClick={() =>
            updateQueryParams(
              {
                ledsRoomSettings: '',
              },
              history as History,
              location as Location,
            )
          }
          testId="save-scene-button"
          startIcon={<FrameLedIcon />}
          colorType={ButtonColorType.WHITE}
        />
      </Header>
      <SectionLabel>Room scenes:</SectionLabel>
      <DashboardScenes docks={docks} scenes={filterRoomScenes(locationScenes, roomId)} />
      <SectionLabel>External devices:</SectionLabel>

      {externalDevices[roomId] &&
        externalDevices[roomId].map(externalDevice => (
          <ExternalDeviceTile externalDevice={externalDevice} manageable />
        ))}

      <SectionLabel>Devices:</SectionLabel>
      <DashboardDocks
        room={room}
        locationDetails={locationDetails}
        locationId={currentLocationId}
        onDataChange={() => initAppState(currentLocationId, dispatchGlobalAction)}
        supportSnapInReplace
      />
      <LedsRoomSettings />
      <ActionModal
        onHide={() => {
          setShowConfigActivationModal(false);
        }}
        onConfirm={() => {
          activateLocation(appContext.currentLocationId);
          setShowConfigActivationModal(false);
          setIsConfigurationSynced(true);
        }}
        show={showConfigActivationModal}
        title=""
        header="Activate configuration?"
        description="All connections & configured elements will be send to Gateway."
        altButton2Text="Activate"
      />
    </>
  );
};

export default withRouter(DashboardRoomView);
