import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { CheckIcon } from '@streda/web_components';

export const Checklist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ListItem = styled.div`
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.colors.font1};
`;
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.font1};
`;

export const ChecklistItem: React.FC<{ checked?: boolean; label: string }> = function({
  checked,
  label,
}) {
  const { colors } = React.useContext(ThemeContext);

  return (
    <ListItem>
      <CheckIcon fill={checked ? colors.secondary : colors.font1} />
      <span> {label}</span>
    </ListItem>
  );
};
