import { ToastType, ToastHelper, getScenesApi, getAccount } from '@streda/web_components';
import { getScenesForLocation, invokeScene } from '../../../../utils/api/scenes.api';

export const getScenesList = locationId =>
  getScenesForLocation(locationId).catch(error => {
    ToastHelper(
      ToastType.DANGER,
      'Error during getting location scenes. Please try again or contact with system administrator',
      error,
    );
  });
export const onSceneInvoke = (locationId, sceneId) =>
  invokeScene(locationId, sceneId)
    .then(() => {
      ToastHelper(ToastType.SUCCESS, 'Success! Scene has been invoked');
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during invoking scene. Please try again or contact with system administrator',
        error,
      );
    });
export const deleteScene = (sceneId, version, locationId) => {
  return getScenesApi(getAccount())
    .sceneDeleteScene(locationId, sceneId, version, true)
    .then(() => {
      ToastHelper(ToastType.SUCCESS, 'Success! Scene has been deleted');

      return sceneId;
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during deleting scene. Please try again or contact with system administrator',
        error,
      );

      return error;
    });
};

export default {
  getScenesList,
};
