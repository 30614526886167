import * as React from 'react';
import {
  OverlayModalWrapper,
  OverlayModalContent,
  PlusSquareIcon,
  MinimizeIcon,
  CloseIcon,
  MinusSquareIcon,
} from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  StepsWrapper,
  StepName,
  StepWrapper,
  StyledCloseIcon,
} from '../../../common/overlay-modal-steps/overlay-modal-steps';
import SnapInToSwapSelect from './snap-in-to-swap-select/snap-in-to-swap-select';
import { ISnapInReplace, ISnapInReplaceStepComponent } from '../snap-in-replace.types';
import { IStepDefinition } from '../../../common/overlay-modal-steps/overlay-modal-steps.types';
import RemoveSnapIn from './remove-snap-in/remove-snap-in';
import MountSnapIn from './mount-snap-in/mount-snap-in';
import { StepContentWrapper } from '../snap-in-replace.components';

const stepsDefinition: IStepDefinition<ISnapInReplaceStepComponent>[] = [
  {
    name: 'Swap snap-in',
    Icon: MinusSquareIcon,
    Component: SnapInToSwapSelect,
  },
  {
    name: 'Remove snap-ins',
    Icon: PlusSquareIcon,
    Component: RemoveSnapIn,
  },
  {
    name: 'Mount snap-ins',
    Icon: MinimizeIcon,
    Component: MountSnapIn,
  },
];
const SwapSnapIn: React.FC<ISnapInReplace> = function({ dock, room, snapIn }) {
  const [step, setStep] = React.useState(0);
  const { colors } = React.useContext(ThemeContext);
  const history = useHistory();
  const ContentComponent = stepsDefinition[step].Component;

  return (
    <OverlayModalWrapper>
      <StyledCloseIcon
        icon={<CloseIcon />}
        type="button"
        onClick={() => {
          history.goBack();
        }}
      />
      <OverlayModalContent>
        <StepsWrapper>
          {stepsDefinition.map(({ Icon, name }, stepIndex) => {
            const isStepActive = stepIndex <= step;

            return (
              <StepWrapper key={name}>
                <Icon width={16} height={16} fill={isStepActive ? colors.font1 : colors.d3gray} />
                <StepName active={isStepActive}>{name}</StepName>
              </StepWrapper>
            );
          })}
        </StepsWrapper>

        <StepContentWrapper>
          <ContentComponent
            onNextStep={() => setStep(step + 1)}
            onPreviousStep={() => setStep(step - 1)}
            dock={dock}
            room={room}
            snapIn={snapIn}
          />
        </StepContentWrapper>
      </OverlayModalContent>
    </OverlayModalWrapper>
  );
};

export default SwapSnapIn;
