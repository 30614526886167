import * as React from 'react';
import {
  ColorIcon,
  ColorStateModeEnums,
  DeviceStateType,
  DeviceStateValues,
  ExternalClusterNameEnums,
  getExternalDeviceCluster,
  IntersectIcon,
  PulseSwitchIcon,
  RadioSelector,
  TemperatureIcon,
  useDeviceState,
} from '@streda/web_components';
import { ExternalDeviceTypeEnums } from '../external-devices-list/external-devices-list.types';
import {
  DeviceHeaderContainer,
  DeviceLabel,
  DeviceTale,
  DeviceTitle,
} from '../external-devices-list/external-devices-list.components';
import StateSwitch from './actions/state/state';
import BrightnessSlider from './actions/brightness/brightness';
import ColorTempSlider from './actions/colorTemp/colorTemp';
import XyColorSlider from './actions/xyColor/xyColor';
import { IExternalDeviceTile } from './external-device-tile.types';

const externalDeviceTypeIcons = {
  [ExternalDeviceTypeEnums.LIGHT]: <PulseSwitchIcon />,
  default: <IntersectIcon />,
};

export const ExternalDeviceTile: React.FC<IExternalDeviceTile> = function(props) {
  const {
    externalDevice,
    onDragStart,
    onDragEnd,
    draggable = false,
    manageable = false,
    onClick,
  } = props;
  const { devicesState } = useDeviceState();
  const [pending, setPending] = React.useState<boolean>(false);
  const typeIcon = externalDeviceTypeIcons[externalDevice.type] || externalDeviceTypeIcons.default;
  const xyColorCluster = getExternalDeviceCluster(
    externalDevice,
    ExternalClusterNameEnums.XY_COLOR,
  );
  const colorTempCluster = getExternalDeviceCluster(
    externalDevice,
    ExternalClusterNameEnums.COLOR_TEMPERATURE,
  );
  const deviceStates =
    devicesState
      .find(state => state.zigbeeId === externalDevice.zigbeeId)
      ?.endpoints.find(endpoint => endpoint.endpointName === 'default')?.states || [];
  const colorState = deviceStates.find(state => state.type === DeviceStateType.COLOR_STATE)?.data;
  const colorTempState = colorState && colorState.mode === ColorStateModeEnums.COLOR_TEMP;
  const xyColorState = colorState && colorState.mode === ColorStateModeEnums.XY_COLOR;
  const getInitialColorCluster = () =>
    (xyColorState && (xyColorCluster?.clusterType as ExternalClusterNameEnums)) ||
    (colorTempState && (colorTempCluster?.clusterType as ExternalClusterNameEnums));
  const [colorCluster, setColorCluster] = React.useState<ExternalClusterNameEnums>(
    getInitialColorCluster(),
  );
  const powerState = deviceStates.find(state => state.type === DeviceStateType.POWER_STATE)?.data
    .state;
  const isPowerNotActive = powerState === DeviceStateValues.OFF;

  React.useEffect(() => setColorCluster(getInitialColorCluster()), [
    xyColorState,
    xyColorCluster,
    colorTempState,
    colorTempCluster,
  ]);

  return (
    <DeviceTale
      key={externalDevice.zigbeeId}
      tabIndex={0}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();

        if (onClick) {
          onClick(event);
        }
      }}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <DeviceHeaderContainer>
        {typeIcon}
        {manageable && (
          <StateSwitch
            externalDevice={externalDevice}
            deviceStates={deviceStates}
            disabled={pending}
            setPending={setPending}
          />
        )}
      </DeviceHeaderContainer>
      <DeviceTitle>{externalDevice.model}</DeviceTitle>
      <DeviceLabel>{externalDevice.vendor}</DeviceLabel>
      {manageable && (
        <BrightnessSlider
          externalDevice={externalDevice}
          deviceStates={deviceStates}
          disabled={pending || isPowerNotActive}
          setPending={setPending}
        />
      )}
      {manageable && xyColorCluster && colorTempCluster && (
        <RadioSelector
          options={[
            {
              value: ExternalClusterNameEnums.XY_COLOR,
              icon: ColorIcon,
              name: 'Hue',
            },
            {
              value: ExternalClusterNameEnums.COLOR_TEMPERATURE,
              icon: TemperatureIcon,
              name: 'Color temperature',
            },
          ]}
          value={colorCluster}
          onChange={value => setColorCluster(value)}
        />
      )}
      {manageable && colorCluster === ExternalClusterNameEnums.XY_COLOR && (
        <XyColorSlider
          externalDevice={externalDevice}
          deviceStates={deviceStates}
          disabled={pending || isPowerNotActive}
          setPending={setPending}
        />
      )}
      {manageable && colorCluster === ExternalClusterNameEnums.COLOR_TEMPERATURE && (
        <ColorTempSlider
          externalDevice={externalDevice}
          deviceStates={deviceStates}
          disabled={pending || isPowerNotActive}
          setPending={setPending}
        />
      )}
    </DeviceTale>
  );
};

export default ExternalDeviceTile;
