import styled from 'styled-components';

export const UserAvatar = styled.div<{ width?: number; height?: number }>`
  width: ${({ width = 36 }) => width}px;
  height: ${({ height = 36 }) => height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.d4};
  color: ${({ theme }) => theme.colors.gray};
  text-transform: uppercase;
`;

export const UserInfoToggleContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  user-select: none;
`;

export const UserInfo = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
`;

export const UserName = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

export const Email = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.background};
`;
