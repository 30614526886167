import { parseDockDevices, DockWithZigbeeIdDto } from '@streda/web_components';
import { AxiosResponse } from 'axios';
import { AppState, AppStateActionType, AppStateDispatch } from '../contexts/app-context/app-context.types';
import {
  getDeviceTypes,
  getLocationDetails,
  getLocationDocks,
  getLocationRooms,
  getMyCompany,
} from './main-view.api';
import { getScenesList } from './pages/dashboard/dashboard.logic';
import { SystemErrors } from '../utils/constants/system.constants';

export const initAppState = (locationId: string, dispatch: AppStateDispatch) => {
  const state: AppState = {
    docks: [],
    rooms: [],
    locationScenes: [],
    externalDevices: [],
    currentLocationId: '',
    locationDetails: {},
    companyDetails: undefined,
  };

  return Promise.resolve(locationId)
    .then(assignedLocation => {
      if (!assignedLocation) {
        throw new Error(SystemErrors.NO_ASSIGNED_LOCATIONS);
      }

      state.currentLocationId = assignedLocation;

      return getLocationDetails(assignedLocation);
    })
    .then(locationDetails => {
      const { rooms } = locationDetails.data;

      state.locationDetails = locationDetails.data;
      state.rooms = rooms;
      rooms.forEach(room => {
        state.docks[room.id] = room.docks;
        state.externalDevices[room.id] = room.externalDevices;
      });

      getScenesList(state.currentLocationId).then((locationScenes: any[]) => {
        state.locationScenes = locationScenes;
        dispatch({
          type: AppStateActionType.UPDATE_SCENES,
          locationScenes,
        });
      });

      return getLocationRooms(state.currentLocationId);
    })
    .then((result: any) => {
      const { data } = result;

      return Promise.all(data.map(room => getLocationDocks(state.currentLocationId, room.id)));
    })
    .then(docksResponse => {
      docksResponse.forEach((docks: AxiosResponse<DockWithZigbeeIdDto[]>, index) => {
        const roomId = state.rooms[index].id;

        docks.data.forEach((dock, dockIndex) => {
          const dockCurrentData = state.docks[roomId][dockIndex];

          state.docks[roomId][dockIndex] = { ...dockCurrentData, ...dock };
        });
      });

      return getDeviceTypes();
    })
    .then((deviceTypes: any) => {
      state.rooms.forEach(room => {
        state.docks[room.id].forEach((dock, index) => {
          state.docks[room.id][index].devices = parseDockDevices(dock, deviceTypes.data);
        });
      });

      return getMyCompany();
    })
    .then(companyDetails => {
      if (companyDetails) {
        state.companyDetails = companyDetails;
      }

      dispatch({
        type: AppStateActionType.UPDATE_WHOLE_STATE,
        state,
      });

      return Promise.resolve();
    });
};

export default {
  initAppState,
};
