import * as React from 'react';
import { ButtonColorType, FailureRoundIcon, SuccessIcon } from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import {
  ActionButtonsContainer,
  Description,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import NewSnapInContext from '../../add-new-snap-in.context';
import ActionBtn from './registration-status.components';

const RegistrationSuccess: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  const { colors } = React.useContext(ThemeContext);

  React.useEffect(() => {
    setTimeout(() => onNextStep(), 3000);
  }, []);

  return (
    <>
      <StepHeader>Snap-in registered</StepHeader>
      <SuccessIcon fill={colors.secondary} width={145} height={145} />
      <GrayedDescription>Success!</GrayedDescription>
    </>
  );
};

const RegistrationFailure: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { colors } = React.useContext(ThemeContext);
  const { onPreviousStep } = props;

  return (
    <>
      <StepHeader>Snap-in not registered</StepHeader>
      <FailureRoundIcon fill={colors.alert} width={145} height={145} />
      <Description>Ensure that serial number is correct and try again!</Description>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Try again"
          onClick={() => onPreviousStep()}
          colorType={ButtonColorType.PRIMARY}
        />
      </ActionButtonsContainer>
    </>
  );
};

const RegistrationStatus: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { newSnapIn } = React.useContext(NewSnapInContext);
  const { zigbeeId } = newSnapIn;

  return <>{zigbeeId ? <RegistrationSuccess {...props} /> : <RegistrationFailure {...props} />}</>;
};

export default RegistrationStatus;
