/* eslint-disable no-prototype-builtins */
import * as contentful from 'contentful';

export const getContentfulData = async (contentType?: string) => {
  const client = contentful.createClient({
    space: process.env.REACT_APP_CF_SPACE,
    environment: process.env.REACT_APP_CF_ENVIRONMENT,
    accessToken: process.env.REACT_APP_CF_ACCESS_TOKEN,
  });
  const data = await client.getEntries({
    locale: 'en-US',
    content_type: contentType,
  });

  return data;
};

export const getMappedSingleCfData = async <ResultType>(
  contentType?: string,
): Promise<ResultType> => {
  const contentTypeData = await getContentfulData(contentType);
  return contentTypeData.items[0].fields as ResultType;
};

export const getMappedCfData = async <ResultType>(contentType?: string): Promise<ResultType[]> => {
  const contentTypeData = await getContentfulData(contentType);
  return contentTypeData.items.map(res => res.fields) as ResultType[];
};
