import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AccountRoles, Footer, LoginPageContainer } from '@streda/web_components';
import styled from 'styled-components';
import { ILoginPageProps } from './login-page.types';
import { useAuth } from '../../../utils/hooks/useAuth';

const waveGraphic = require('../../../assets/bitmap/wave.png');

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
`;

const WaveGraphic = styled.img`
  width: 100%;
  height: 100%;
  object-fit: none;
  z-index: 0;
  position: fixed;
  left: 0;
  overflow: hidden;
  top: 0;
  vertical-align: middle;
`;

const LoginPage: React.FC<ILoginPageProps> = (props: ILoginPageProps) => {
  const { loginCallback, history, location } = props;
  const { state = {} } = location;
  const { systemError = '' } = state;
  const [errorState, setErrorState] = React.useState(systemError);
  const [additionalError, setAdditionalError] = React.useState(undefined);
  const currentYear = new Date().getFullYear();

  const onExternalLoginSuccess = () => {
    loginCallback();
    history.push('/');
  };

  const onLoggingInError = (error: string, additionalInfo?: string) => {
    setErrorState(error);
    setAdditionalError(additionalInfo);
  };

  const { login, inProgress } = useAuth(
    onExternalLoginSuccess,
    onLoggingInError,
    AccountRoles.HOME_OWNER,
  );

  return (
    <>
      <BackgroundContainer>
        <WaveGraphic alt="" src={waveGraphic.default} />
        <Footer companyName={`Copyrights ${currentYear} Streda®, Isolectra B.V`} />
      </BackgroundContainer>
      <LoginPageContainer
        onLoginHandler={login}
        loginInProgress={inProgress}
        errorMsg={errorState}
        additionalErrorMsg={additionalError}
      />
    </>
  );
};

export default withRouter(LoginPage);
