import * as React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  CreateScene,
  showLoader,
  ModalLoader,
  hideLoader,
} from '@streda/web_components';
import { AppContext } from '../../../../contexts/app-context/app-context';
import { saveScene } from './scenes-creator.logic';
import { getScenesList } from '../../dashboard/dashboard.logic';
import { AppStateActionType } from '../../../../contexts/app-context/app-context.types';

const clearScene = {
  actions: [],
  locationId: '',
  name: '',
  enabled: true,
  roomIds: null,
  id: null,
};

const ScenesCreator = function(props) {
  const [appContext, dispatch] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const { location } = props;
  const [docks, setDocks] = React.useState({});
  const [rooms, setRooms] = React.useState([]);
  const sceneInEditState = React.useState({ ...clearScene });
  const [sceneInEdit, setSceneInEdit] = sceneInEditState;
  const history = useHistory();

  React.useEffect(() => {
    setDocks(appContext.docks);
    setRooms(appContext.rooms);
  }, [appContext]);

  return (
    <CreateScene
      rooms={rooms}
      docks={docks}
      sceneInEditState={sceneInEditState}
      location={location}
      onCreateSceneModalCancel={() => history.push(`/scenes`)}
      onSceneSave={() => {
        showLoader(<ModalLoader />);
        saveScene(currentLocationId, sceneInEdit)
          .then(() => getScenesList(currentLocationId))
          .then((scenes: any[]) => {
            dispatch({
              type: AppStateActionType.UPDATE_SCENES,
              locationScenes: scenes,
            });
            setSceneInEdit(clearScene);
            history.push(`/scenes`);
          })
          .finally(() => hideLoader());
      }}
    />
  );
};

export default withRouter(ScenesCreator);
