import * as React from 'react';
import { ISnapInReplaceStepComponent, IStep } from '../../snap-in-replace.types';
import RemoveInstruction from './remove-instruction/remove-instruction';
import { StepContentWrapper } from '../../snap-in-replace.components';

const stepsDefinition: IStep<ISnapInReplaceStepComponent>[] = [
  {
    Component: RemoveInstruction,
  },
];

const RemoveSnapIn: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { onNextStep, dock, snapIn, room } = props;
  const [step, setStep] = React.useState(0);
  const StepComponent = stepsDefinition[step].Component;

  return (
    <StepContentWrapper>
      <StepComponent
        onNextStep={(stepNumber = step + 1) => {
          if (step >= stepsDefinition.length - 1) {
            onNextStep();
            return;
          }
          setStep(stepNumber);
        }}
        dock={dock}
        snapIn={snapIn}
        room={room}
      />
    </StepContentWrapper>
  );
};

export default RemoveSnapIn;
