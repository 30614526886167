import * as React from 'react';
import {
  ExpandableSection,
  getDockSizeByDockCode,
  getProfileNameBySymbol,
  getRoomIconById,
  ProfilesEnums,
  usePromise,
  SnapInInDockStateEnum,
  getSnapInIconByState,
} from '@streda/web_components';
import { ISnapInSelectModal } from './snap-in-select-modal.types';
import {
  Dock,
  NoDocksInfo,
  RoomDocksWrapper,
  SnapInDetailLabel,
  SnapInDetailValue,
  StyledModal,
  SnapInDetailContainer,
} from './snap-in-select-modal.components';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { getDocksPositions } from '../../../../../../utils/api/dictionary';
import { snapInStatusesTranslations } from './snap-in-select-modal.translations';

const SnapInSelectModal: React.FC<ISnapInSelectModal> = function({
  show,
  onHide,
  dock,
  onDockSelect,
}) {
  const [appContext] = React.useContext(AppContext);
  const { docks, rooms } = appContext;
  const dockSize = getDockSizeByDockCode(dock.dockCode);
  const getDockPositions = React.useMemo(getDocksPositions, []);
  const [dockPositions] = usePromise(getDockPositions, []);

  return (
    <StyledModal show={show} title="Available snap-ins" onHide={onHide}>
      {rooms.map(room => (
        <ExpandableSection
          key={`room_${room.id}`}
          title={room.name}
          icon={getRoomIconById(room.typeId)}
          grid
        >
          <RoomDocksWrapper>
            {(() => {
              const availableDocks = docks[room.id].filter(dockToCheck => {
                const dockToCheckSize = getDockSizeByDockCode(dockToCheck.dockCode);
                const isEmptyDock = dockToCheck.snapInState === SnapInInDockStateEnum.Empty;
                const isSnapInAvailable = dockToCheck.zigbeeId || isEmptyDock;

                return (
                  isSnapInAvailable &&
                  dockToCheck.number !== dock.number &&
                  dockToCheckSize === dockSize
                );
              });

              if (availableDocks.length === 0) {
                return <NoDocksInfo>No available docks for this room</NoDocksInfo>;
              }

              return availableDocks.map(dock => {
                const SnapInImg = getSnapInIconByState(dock.snapInState, dock.dockCode);
                const dockPosition = dockPositions[dock.positionId];
                const snapInProfileSymbol = dock.dockCode.charAt(0) as ProfilesEnums;
                const profileName = getProfileNameBySymbol(snapInProfileSymbol);

                return (
                  <Dock
                    onClick={() =>
                      onDockSelect({
                        dock,
                        room,
                      })
                    }
                  >
                    <SnapInImg height={48} width="auto" />
                    <div>
                      <SnapInDetailContainer>
                        <SnapInDetailLabel>Profile: </SnapInDetailLabel>
                        <SnapInDetailValue>{profileName}</SnapInDetailValue>
                      </SnapInDetailContainer>
                      <SnapInDetailContainer>
                        <SnapInDetailLabel>Position: </SnapInDetailLabel>
                        <SnapInDetailValue>{dockPosition}</SnapInDetailValue>
                      </SnapInDetailContainer>
                      <SnapInDetailContainer>
                        <SnapInDetailLabel>Status: </SnapInDetailLabel>
                        <SnapInDetailValue>{snapInStatusesTranslations[dock.snapInState]}</SnapInDetailValue>
                      </SnapInDetailContainer>
                    </div>
                  </Dock>
                );
              });
            })()}
          </RoomDocksWrapper>
        </ExpandableSection>
      ))}
    </StyledModal>
  );
};

export default SnapInSelectModal;
