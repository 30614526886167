import * as React from 'react';
import { ButtonColorType, SnapInsPlugInImg } from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import {
  ActionBtn,
  ActionButtonsContainer,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import { AppContext } from '../../../../../../contexts/app-context/app-context';

const MountInstruction: React.FC<ISnapInReplaceStepComponent> = function({
  onNextStep,
  dock,
  room,
}) {
  const appContext = React.useContext(AppContext);
  const history = useHistory();

  return (
    <>
      <StepHeader>Mount snap-ins</StepHeader>
      <SnapInsPlugInImg />
      <GrayedDescription>Insert snap-ins into the docks</GrayedDescription>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Snap-ins mounted"
          onClick={() => onNextStep(dock, room, appContext, history)}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default MountInstruction;
