import styled from 'styled-components';
import { Modal } from '@streda/web_components';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 849px;
    max-width: none;
  }

  .modal-header {
    margin: 0;
  }

  .modal-header,
  .modal-body {
    padding: 29px 49px;
  }
`;

export default StyledModal;
