import styled from 'styled-components';
import { Button } from '@streda/web_components';

export const ConnectionsPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelActionBtnsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
