import { SnapInProductDto, SnapInStatusEnum2 } from '@streda/web_components';

export const getInitStep: (snapIn: SnapInProductDto) => number = ({ snapInStatus, zigbeeId }) => {
  if (!zigbeeId) {
    // Add new snap-in
    return 1;
  }

  if (snapInStatus === SnapInStatusEnum2.InstalledNotDetected) {
    // Pairing
    return 3;
  }

  if (snapInStatus !== SnapInStatusEnum2.Tested) {
    // Testing
    return 4;
  }

  // Remove snap-in
  return 0;
};

export default {
  getInitStep,
};
