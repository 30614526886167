import * as React from 'react';
import UnregisterSnapIn from './unregister-snap-in/unregister-snap-in';
import RemoveInstruction from './remove-instruction/remove-instruction';
import { ISnapInReplaceStepComponent, IStep } from '../../snap-in-replace.types';
import RemovingStatus from './removing-status/removing-status';

const stepsDefinition: IStep<ISnapInReplaceStepComponent>[] = [
  {
    Component: UnregisterSnapIn,
  },
  {
    Component: RemovingStatus,
  },
  {
    Component: RemoveInstruction,
  },
];

const RemoveSnapIn: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { onNextStep, onPreviousStep, dock, snapIn, room } = props;
  const [step, setStep] = React.useState(0);
  const StepComponent = stepsDefinition[step].Component;

  return (
    <StepComponent
      onNextStep={(stepNumber = step + 1) => {
        if (step >= stepsDefinition.length - 1) {
          onNextStep();
          return;
        }
        setStep(stepNumber);
      }}
      onPreviousStep={(stepNumber = step - 1) => {
        if (step < 0) {
          onPreviousStep();
          return;
        }
        setStep(stepNumber);
      }}
      dock={dock}
      snapIn={snapIn}
      room={room}
    />
  );
};

export default RemoveSnapIn;
