import * as React from 'react';
import {
  OverlayModalWrapper,
  OverlayModalContent,
  PlusSquareIcon,
  MinimizeIcon,
  CheckSquareIcon,
  CloseIcon,
  MinusSquareIcon,
  PairingIcon,
  SnapInProductDto,
  PlayIcon,
} from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  StepsWrapper,
  StepName,
  StepWrapper,
  StepContentWrapper,
  StyledCloseIcon,
} from '../../../common/overlay-modal-steps/overlay-modal-steps';
import { IStepDefinition } from '../../../common/overlay-modal-steps/overlay-modal-steps.types';
import RemoveSnapIn from './remove-snap-in/remove-snap-in';
import AddSnapIn from './add-snap-in/add-snap-in';
import NewSnapInContext from './add-new-snap-in.context';
import Registration from './registration/registration';
import Pairing from './pairing/pairing';
import { closeZigbeeNetwork } from '../../../../utils/api/configuration.api';
import { AppContext } from '../../../../contexts/app-context/app-context';
import Finish from './finish/finish';
import { ISnapInReplace, ISnapInReplaceStepComponent } from '../snap-in-replace.types';
import Testing from './testing/testing';
import { getInitStep } from './add-new-snap-in.logic';
import ContinueReplacing from './continue-replacing/ContinueReplacing';
import { initAppState } from '../../../main-view.logic';

const stepsDefinition: IStepDefinition<ISnapInReplaceStepComponent>[] = [
  {
    name: 'Remove snap-in',
    Icon: MinusSquareIcon,
    Component: RemoveSnapIn,
  },
  {
    name: 'Add new snap-in',
    Icon: PlusSquareIcon,
    Component: AddSnapIn,
  },
  {
    name: 'Registration',
    Icon: MinimizeIcon,
    Component: Registration,
  },
  {
    name: 'Pairing',
    Icon: PairingIcon,
    Component: Pairing,
  },
  {
    name: 'Testing',
    Icon: PlayIcon,
    Component: Testing,
  },
  {
    name: 'Finish',
    Icon: CheckSquareIcon,
    Component: Finish,
  },
];
const AddNewSnapIn: React.FC<ISnapInReplace> = function({ dock, room, snapIn }) {
  const [step, setStep] = React.useState(0);
  const [, dispatchGlobalAction] = React.useContext(AppContext);
  const [showContinueReplacingInfo, setShowContinueReplacingInfo] = React.useState<boolean>(false);
  const { colors } = React.useContext(ThemeContext);
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const history = useHistory();
  const ContentComponent = stepsDefinition[step].Component;
  const [newSnapIn, setNewSnapIn] = React.useState<SnapInProductDto>(snapIn);
  const newSnapInContextValue = {
    newSnapIn,
    setNewSnapIn: (snapInData: SnapInProductDto) =>
      setNewSnapIn({
        ...newSnapIn,
        ...snapInData,
      }),
  };

  React.useEffect(() => {
    const initStep = getInitStep(snapIn);

    if (initStep !== 0) {
      setShowContinueReplacingInfo(true);
    }

    setStep(initStep);
  }, [snapIn.snapInStatus]);

  React.useEffect(
    () => () => {
      closeZigbeeNetwork(currentLocationId);
    },
    [],
  );

  return (
    <OverlayModalWrapper>
      <StyledCloseIcon
        icon={<CloseIcon />}
        type="button"
        onClick={() => {
          initAppState(currentLocationId, dispatchGlobalAction);
          history.goBack();
        }}
      />
      <OverlayModalContent>
        <StepsWrapper>
          {stepsDefinition.map(({ Icon, name }, stepIndex) => {
            const isStepActive = stepIndex <= step;

            return (
              <StepWrapper key={name}>
                <Icon width={16} height={16} fill={isStepActive ? colors.font1 : colors.d3gray} />
                <StepName active={isStepActive}>{name}</StepName>
              </StepWrapper>
            );
          })}
        </StepsWrapper>

        <StepContentWrapper>
          <NewSnapInContext.Provider value={newSnapInContextValue}>
            <ContentComponent
              onNextStep={() => setStep(step + 1)}
              onPreviousStep={() => setStep(step - 1)}
              dock={dock}
              room={room}
              snapIn={snapIn}
            />
          </NewSnapInContext.Provider>
        </StepContentWrapper>
      </OverlayModalContent>

      {showContinueReplacingInfo && (
        <ContinueReplacing
          dock={dock}
          room={room}
          onConfirm={() => setShowContinueReplacingInfo(false)}
        />
      )}
    </OverlayModalWrapper>
  );
};

export default AddNewSnapIn;
