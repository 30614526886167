import * as React from 'react';
import {
  DeviceStateType,
  DeviceStateValues,
  ExternalClusterNameEnums,
  getExternalDeviceCluster,
  SwitchButton,
} from '@streda/web_components';
import { performDeviceAction } from '../../external-device-tile.logic';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { IActionComponent } from '../../external-device-tile.types';

const StateSwitch: React.FC<IActionComponent> = function({
  externalDevice,
  deviceStates,
  disabled,
  setPending,
}) {
  const stateCluster = getExternalDeviceCluster(externalDevice, ExternalClusterNameEnums.STATE);
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const powerState = deviceStates.find(state => state.type === DeviceStateType.POWER_STATE)?.data
    .state;

  if (!stateCluster) {
    return null;
  }

  return (
    <SwitchButton
      disabled={disabled}
      isActive={powerState === DeviceStateValues.ON}
      onClick={() => {
        setPending(true);
        performDeviceAction<string>(
          externalDevice,
          currentLocationId,
          ExternalClusterNameEnums.STATE,
        ).finally(() => setPending(false));
      }}
    />
  );
};

export default StateSwitch;
