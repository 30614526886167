import {
  hideLoader,
  ModalLoader,
  showLoader,
  TimelineOptionsItemsType,
  Toast,
  restoreLocationConfigurationBySnapshot,
} from '@streda/web_components';
import * as React from 'react';
import { getAccount } from '../../../utils/auth.utils';

const bcbRestartTimeout = 15000;

export const prepareBackupsChainOption = (
  backups,
  setShowRestoreModal,
): TimelineOptionsItemsType[] =>
  backups.map(({ name, creationDateTime, id }) => {
    const restoreAction = {
      name: 'restore',
      triggerFunction: () =>
        setShowRestoreModal({
          show: true,
          snapshotId: id,
        }),
    };
    const actions = [restoreAction];

    return {
      name,
      creationDateTime,
      actions,
    };
  });

export const onBackupRestore = (locationId, backupId) => {
  showLoader(<ModalLoader />);

  restoreLocationConfigurationBySnapshot(getAccount, locationId, backupId)
    .then(() => {
      setTimeout(() => {
        hideLoader();
        Toast.showToast({
          message: 'Location has been restored',
          colorType: 'Success',
        });
      }, bcbRestartTimeout);
    })
    .catch(() => hideLoader());
};

export default {
  prepareBackupsChainOption,
  onBackupRestore,
};
