import styled from 'styled-components';
import { IconButton } from '@streda/web_components';

export const StepsWrapper = styled.div`
  display: flex;
  margin: 0 -64px;
  gap: 40px;
  margin-bottom: 90px;
`;

export const StepName = styled.span<{ active }>`
  font-size: 12px;
  color: ${({ theme, active }) => (active ? theme.colors.font1 : theme.colors.d3gray)};
`;

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
`;

export const StepHeader = styled.p`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.font1};
  font-weight: 500;
`;

export const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  align-items: center;
  width: 316px;
`;

export const StyledCloseIcon = styled(IconButton)`
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 99;
`;

export const StepParagraph = styled.p`
  color: ${({ theme }) => theme.colors.font3};
  font-size: 16px;
  line-height: 24px;
`;
