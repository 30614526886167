import * as React from 'react';
import {
  OverlayModalWrapper,
  OverlayModalContent,
  PlusSquareIcon,
  MinimizeIcon,
  CheckSquareIcon,
  CloseIcon,
  ExternalDeviceFullDto,
} from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  StepsWrapper,
  StepName,
  StepWrapper,
  StepContentWrapper,
  StyledCloseIcon,
} from '../../../common/overlay-modal-steps/overlay-modal-steps';
import ZigbeeNetworkOpen from './zigbee-network-open/zigbee-network-open';
import DevicePairing from './device-pairing/device-pairing';
import { AppContext } from '../../../../contexts/app-context/app-context';
import RoomAssigning from './room-assigning/room-assigning';
import { IStepDefinition } from '../../../common/overlay-modal-steps/overlay-modal-steps.types';
import { IAddDeviceStepComponent } from './add-external-device.types';
import { closeZigbeeNetwork } from '../../../../utils/api/configuration.api';

const stepsDefinition: IStepDefinition<IAddDeviceStepComponent>[] = [
  {
    name: 'Add new device',
    Icon: PlusSquareIcon,
    Component: ZigbeeNetworkOpen,
  },
  {
    name: 'Pairing process',
    Icon: MinimizeIcon,
    Component: DevicePairing,
  },
  {
    name: 'Assignee to room',
    Icon: CheckSquareIcon,
    Component: RoomAssigning,
  },
];

const AddExternalDevice = function() {
  const [step, setStep] = React.useState(0);
  const [device, setDevice] = React.useState<ExternalDeviceFullDto>();
  const { colors } = React.useContext(ThemeContext);
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const history = useHistory();
  const ContentComponent = stepsDefinition[step].Component;

  React.useEffect(
    () => () => {
      closeZigbeeNetwork(currentLocationId);
    },
    [],
  );

  return (
    <OverlayModalWrapper>
      <StyledCloseIcon
        icon={<CloseIcon />}
        type="button"
        onClick={() => {
          history.push(`/external-devices`);
        }}
      />
      <OverlayModalContent>
        <StepsWrapper>
          {stepsDefinition.map(({ Icon, name }, stepIndex) => {
            const isStepActive = stepIndex <= step;

            return (
              <StepWrapper>
                <Icon width={16} height={16} fill={isStepActive ? colors.font1 : colors.d3gray} />
                <StepName active={isStepActive}>{name}</StepName>
              </StepWrapper>
            );
          })}
        </StepsWrapper>

        <StepContentWrapper>
          <ContentComponent
            onNextStep={() => setStep(step + 1)}
            onDeviceUpdate={(externalDevice) => setDevice(externalDevice)}
            device={device}
          />
        </StepContentWrapper>
      </OverlayModalContent>
    </OverlayModalWrapper>
  );
};

export default AddExternalDevice;
