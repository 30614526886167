import {
  DeviceActionDto,
  getDeviceStatesApi,
  PagedResultOfLocationLevelPowerConsumptionDto,
  SnapInStateDataDto,
} from '@streda/web_components';
import { getAccount } from '../auth.utils';

export const getDevicesStatesForLocation = (locationId: string): Promise<SnapInStateDataDto[]> =>
  getDeviceStatesApi(getAccount())
    .deviceStateGetDeviceStatesForLocation(locationId)
    .then(result => result.data);

export const setDevicesStatesForLocation = (locationId: string, deviceAction: DeviceActionDto) =>
  getDeviceStatesApi(getAccount())
    .deviceStateSetDeviceAction(locationId, deviceAction)
    .then(result => result.data);

export const getPowerConsumptionForLocation = (
  locationId: string,
): Promise<PagedResultOfLocationLevelPowerConsumptionDto> =>
  getDeviceStatesApi(getAccount())
    .deviceStateGetLocationPowerConsumption(locationId)
    .then(result => {
      return result.data;
    });

export default {
  getDevicesStatesForLocation,
  setDevicesStatesForLocation,
  getPowerConsumptionForLocation,
};
