import {
  ExternalDeviceFullDto,
  getExternalProductsApi,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { getAccount } from '../../../../utils/auth.utils';
import { AppStateActionType } from '../../../../contexts/app-context/app-context.types';

export const onDeviceRoomChange = (locationId: string, zigbeeId: string, roomId: string) =>
  getExternalProductsApi(getAccount())
    .externalProductMoveExternalProductToRoom(locationId, zigbeeId, roomId)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during assigning device to room. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const onDeviceRoomChangeSuccess = (
  externalDevices: ExternalDeviceFullDto[],
  zigbeeId: string,
  dragFromRoom: string,
  dragToRoom: string,
  dispatch,
) => {
  const deviceInOldRoomIndex = externalDevices[dragFromRoom].findIndex(
    device => device.zigbeeId === zigbeeId,
  );

  externalDevices[dragToRoom].push({ ...externalDevices[dragFromRoom][deviceInOldRoomIndex] });
  externalDevices[dragFromRoom].splice(deviceInOldRoomIndex, 1);
  dispatch({
    type: AppStateActionType.UPDATE_EXTERNAL_DEVICES,
    externalDevices,
  });
  ToastHelper(ToastType.SUCCESS, 'Success!. Device room assigning has been changed');
};
