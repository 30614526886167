import * as React from 'react';
import styled from 'styled-components';
import { DashboardRooms, GridFullRow, useDeviceState } from '@streda/web_components';
import { AppContext } from '../../../../contexts/app-context/app-context';
import DashboardScenes from '../dashboard-scenes/dashboard-scenes';
import { filterGlobalScenes } from '../dashboard.logic';

const SectionLabel = styled(GridFullRow)`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 16px;
`;

const DashboardMainView = () => {
  const [appContext] = React.useContext(AppContext);
  const { docks, rooms } = appContext;
  const { locationScenes } = appContext;
  const { devicesState } = useDeviceState();

  return (
    <>
      <SectionLabel>Global scenes:</SectionLabel>
      <DashboardScenes docks={docks} scenes={filterGlobalScenes(locationScenes)} />

      <SectionLabel>Rooms:</SectionLabel>
      <DashboardRooms rooms={rooms} devicesStates={devicesState} />
    </>
  );
};

export default DashboardMainView;
