import * as React from 'react';
import {
  BasicConnectionsPanel,
  AdvancedConnectionsPanel,
  TabSwitch,
  Button,
  variants,
  ActionModal,
  Scene,
  GridFullRow,
  ConfigSyncIndicator,
  getConnectionsConfigurationSyncStatus,
} from '@streda/web_components';
import { AppContext } from '../../../contexts/app-context/app-context';
import { mapConnectionToClassConnections } from './connections.logic';
import { activateLocation, getLocationConnections, saveConfigurations } from './connections.api';
import { ConnectionsPanelHeader, PanelActionBtnsContainer } from './connections.components';

const Connections = function() {
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId, locationDetails } = appContext;
  const [configurationPanelMode, setConfigurationPanelMode] = React.useState('basic');
  const [docks, setDocks] = React.useState({});
  const [rooms, setRooms] = React.useState([]);
  const [scenes, setScenes] = React.useState([] as Scene[]);
  const [isPreviewMode, setPreviewMode] = React.useState<boolean>(true);
  const [lastSavedDevicesConnections, setLastSavedDevicesConnections] = React.useState([]);
  const [devicesConnections, setDevicesConnections] = React.useState([]);
  const [showDiscardModal, setShowDiscardModal] = React.useState(false);
  const [showSaveModal, setShowSaveModal] = React.useState<boolean>(false);
  const [isConfigurationSynced, setIsConfigurationSynced] = React.useState<boolean>(false);
  const [showConfigActivationModal, setShowConfigActivationModal] = React.useState(false);

  React.useEffect(() => {
    if (appContext.currentLocationId) {
      getLocationConnections(appContext.currentLocationId).then((fetchedConnections: any) => {
        const connections = mapConnectionToClassConnections(fetchedConnections.data);
        setLastSavedDevicesConnections(mapConnectionToClassConnections(fetchedConnections.data));
        setDevicesConnections(connections);
      });

      getConnectionsConfigurationSyncStatus(appContext.currentLocationId).then(result =>
        setIsConfigurationSynced(result),
      );
    }
  }, [currentLocationId]);
  React.useEffect(() => {
    setDocks(appContext.docks);
    setRooms(appContext.rooms);
    setScenes(appContext.locationScenes);
  }, [appContext]);

  return (
    <GridFullRow>
      <div className="connections-header-container">
        <div className="connections-title-container">
          <p className="connections-title">Configuration Panel</p>
          {!isPreviewMode ? (
            <PanelActionBtnsContainer>
              <Button
                type="button"
                text="Discard changes"
                onClick={() => setShowDiscardModal(true)}
                testId="save-configuration-button"
                colorType={variants.WHITE}
              />
              <Button
                type="button"
                text="Save changes"
                onClick={() => setShowSaveModal(true)}
                testId="save-configuration-button"
              />
              <Button
                type="button"
                text="Activate changes"
                onClick={() => setShowConfigActivationModal(true)}
                testId="save-configuration-button"
              />
            </PanelActionBtnsContainer>
          ) : (
            <Button
              type="button"
              text="Edit"
              onClick={() => setPreviewMode(false)}
              testId="save-configuration-button"
              colorType={variants.WHITE}
            />
          )}
        </div>
        <ConnectionsPanelHeader>
          <TabSwitch
            options={[
              { key: 'basic', title: 'Basic mode' },
              { key: 'advanced', title: 'Advanced mode' },
            ]}
            onChange={({ key }) => setConfigurationPanelMode(key)}
          />
          <ConfigSyncIndicator synchronized={isConfigurationSynced} />
        </ConnectionsPanelHeader>
      </div>

      <div className="connections-panel-container">
        {configurationPanelMode === 'basic' ? (
          <BasicConnectionsPanel
            rooms={rooms}
            docks={docks}
            updateConnectionsHandler={connections => setDevicesConnections(connections)}
            deviceConnections={devicesConnections}
            location={locationDetails}
            previewMode={isPreviewMode}
          />
        ) : (
          <AdvancedConnectionsPanel
            rooms={rooms}
            docks={docks}
            updateConnectionsHandler={connections => setDevicesConnections(connections)}
            deviceConnections={devicesConnections}
            previewMode={isPreviewMode}
            location={locationDetails}
            scenes={scenes}
          />
        )}
      </div>

      <ActionModal
        onHide={() => setShowDiscardModal(false)}
        onConfirm={() => {
          setDevicesConnections([...lastSavedDevicesConnections]);
          setShowDiscardModal(false);
          setPreviewMode(true);
        }}
        show={showDiscardModal}
        title=""
        header="Are you sure to discard?"
        description="All new connections & configured elements will be lost."
        altButton2Text="Discard"
      />
      <ActionModal
        onHide={() => setShowSaveModal(false)}
        onConfirm={() => {
          saveConfigurations(
            appContext.currentLocationId,
            devicesConnections,
            setLastSavedDevicesConnections,
          ).then(() => setIsConfigurationSynced(false));
          setShowSaveModal(false);
        }}
        show={showSaveModal}
        title=""
        header="Save configuration?"
        description="Configuration will be saved but not activated."
        altButton2Text="Save"
      />
      <ActionModal
        onHide={() => {
          setShowConfigActivationModal(false);
        }}
        onConfirm={() => {
          activateLocation(appContext.currentLocationId);
          setShowConfigActivationModal(false);
          setIsConfigurationSynced(true);
          setPreviewMode(true);
        }}
        show={showConfigActivationModal}
        title=""
        header="Activate configuration?"
        description="All connections & configured elements will be send to Gateway."
        altButton2Text="Activate"
      />
    </GridFullRow>
  );
};

export default Connections;
