import * as React from 'react';
import { IAddDeviceStepComponent, IStep } from '../add-external-device.types';
import CapturedDeviceStep from './captured-device-step/captured-device-step';
import InfoStep from './info-step/info-step';

const stepsDefinition: IStep[] = [
  {
    Component: InfoStep,
  },
  {
    Component: CapturedDeviceStep,
  },
];
const DevicePairing = function(props: IAddDeviceStepComponent) {
  const [step, setStep] = React.useState(0);
  const StepComponent = stepsDefinition[step].Component;
  const { onDeviceUpdate, onNextStep, device } = props;

  return (
    <StepComponent
      onNextStep={() => {
        if (step >= stepsDefinition.length - 1) {
          onNextStep();
          return;
        }
        setStep(step + 1);
      }}
      device={device}
      onDeviceUpdate={onDeviceUpdate}
    />
  );
};

export default DevicePairing;
