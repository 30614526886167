/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import { InteractionStatus, AccountInfo, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import _ = require('lodash');
import * as React from 'react';
import { AccountRoles, getAdminAuthApi, processTokenResult } from '@streda/web_components';
import {
  LoginErrorsStatuses,
  LoginErrorsCodes,
  LoginErrorsMessages,
} from '../../components/pages/login-page/login-page.types';
import { AppContext } from '../../contexts/app-context/app-context';
import { AppStateActionType } from '../../contexts/app-context/app-context.types';

export const useAuth = (
  onSuccessCallback?: () => void,
  onErrorCallback?: (response: string, additionalInfo?: string) => void,
  authorizationRole?: AccountRoles | AccountRoles[],
): {
  login: () => void;
  logout: () => void;
  accessToken: string;
  inProgress?: boolean;
  currAccount?: AccountInfo;
} => {
  const [_appContext, dispatch] = React.useContext(AppContext);
  const [accessToken, _setAccessToken] = React.useState('');
  const [authInProgress, setAuthInProgress] = React.useState(false);
  document.cookie = 'msal.interaction.status=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie =
    'x-ms-cpim-sso:devbokita.onmicrosoft.com_0=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  const { instance, inProgress } = useMsal();
  const clearStorage = () => {
    localStorage.removeItem('POST');
    localStorage.removeItem('DELETE');
    localStorage.removeItem('PUT');
  };

  const logout = () => {
    clearStorage();
    instance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI,
    });
  };

  React.useEffect(() => {
    const callbackId = instance.addEventCallback(event => {
      if (
        event?.eventType === EventType.LOGIN_SUCCESS ||
        event?.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload?.idToken) {
          getAdminAuthApi()
            .userAuthLoginWithExternalToken(event?.payload?.idToken)
            .then(result => {
              const { token } = result.data;
              const partialToken = token.split('.')[1];
              const decodedToken = JSON.parse(window.atob(partialToken));
              const roleKey = Object.keys(decodedToken).find(key => key.includes('role'));
              dispatch({
                type: AppStateActionType.UPDATE_CURRENT_LOCATION_ID,
                currentLocationId: decodedToken?.assignedlocations,
              });
              const roleValue = Array.isArray(decodedToken[roleKey])
                ? decodedToken[roleKey]
                : [decodedToken[roleKey]];

              if (
                _.isArray(authorizationRole)
                  ? authorizationRole.find(role => role.includes(roleValue))
                  : authorizationRole.includes(roleValue)
              ) {
                throw Object({ message: 'Insufficient Privileges' });
              } else {
                processTokenResult(
                  result.data.userId,
                  result,
                  onSuccessCallback,
                  null,
                  decodedToken?.assignedlocations,
                );
              }
              setAuthInProgress(false);
            })
            .catch(({ code, response = {}, message }) => {
              localStorage.clear();
              sessionStorage.clear();
              const { status } = response;
              if (LoginErrorsStatuses[status]) {
                onErrorCallback(LoginErrorsStatuses[status]);
              } else if (LoginErrorsCodes[code]) {
                onErrorCallback(LoginErrorsCodes[code]);
              } else if (LoginErrorsMessages[message]) {
                onErrorCallback(
                  LoginErrorsMessages[message],
                  'Ensure user which you want to use has correct role.',
                );
              } else {
                onErrorCallback(
                  'Error during logging in.',
                  'Please try again or contact with system administrator.',
                );
              }
            });
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  const login = () => {
    instance.loginRedirect();
  };

  const isInProgress = (): boolean => {
    return inProgress !== InteractionStatus.None || authInProgress;
  };

  return {
    login,
    logout,
    accessToken,
    inProgress: isInProgress(),
  };
};
