import * as React from 'react';
import {
  ExpandableSection,
  getRoomIconById,
  ButtonColorType,
  Button,
} from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../contexts/app-context/app-context';
import { Title, Header, StyledSubGrid } from './external-devices-list.components';
import { onDeviceRoomChange, onDeviceRoomChangeSuccess } from './external-devices-list.logic';
import { IDragDropContext } from './external-devices-list.types';
import { ExternalDeviceTile } from '../external-device-tile/external-device-tile';
import ExternalDeviceModal from './external-device-modal/external-device-modal';
import { IExternalDeviceModal } from './external-device-modal/external-device-modal.types';

const ExternalDevicesList = function() {
  const [appContext, dispatch] = React.useContext(AppContext);
  const [dragDropContext, setDragDropContext] = React.useState<IDragDropContext>({
    isDragging: false,
    dragFromRoom: undefined,
    device: undefined,
  });
  const history = useHistory();
  const { rooms, externalDevices, currentLocationId } = appContext;
  const [externalDeviceModalState, setExternalDeviceModalState] = React.useState<
    IExternalDeviceModal
  >();

  return (
    <>
      <Header>
        <Title>External devices</Title>
        <Button
          type="button"
          text="Add device"
          onClick={() => {
            history.push(`/external-devices/add-device`);
          }}
          testId="add-external-device-button"
          colorType={ButtonColorType.WHITE}
        />
      </Header>

      {rooms.map(room => (
        <ExpandableSection
          key={`room_${room.id}`}
          title={room.name}
          icon={getRoomIconById(room.typeId)}
          grid
        >
          <StyledSubGrid
            onDrop={() => {
              const { dragFromRoom, device } = dragDropContext;

              if (dragFromRoom === room.id) {
                return;
              }

              onDeviceRoomChange(currentLocationId, device.zigbeeId, room.id).then(() =>
                onDeviceRoomChangeSuccess(
                  externalDevices,
                  device.zigbeeId,
                  dragFromRoom,
                  room.id,
                  dispatch,
                ),
              );
            }}
            onDragOver={event => {
              event.preventDefault();
            }}
            dragDropHighlight={
              dragDropContext.isDragging && dragDropContext.dragFromRoom === room.id
            }
          >
            {externalDevices[room.id]?.map(externalDevice => (
              <ExternalDeviceTile
                key={externalDevice.zigbeeId}
                externalDevice={externalDevice}
                onDragStart={() =>
                  setDragDropContext({
                    isDragging: true,
                    dragFromRoom: room.id,
                    device: externalDevice,
                  })
                }
                onDragEnd={() =>
                  setDragDropContext({
                    isDragging: false,
                    dragFromRoom: undefined,
                    device: undefined,
                  })
                }
                onClick={() =>
                  setExternalDeviceModalState({
                    show: true,
                    externalDevice,
                  })
                }
                draggable
              />
            ))}
          </StyledSubGrid>
        </ExpandableSection>
      ))}
      <ExternalDeviceModal
        show={externalDeviceModalState?.show}
        externalDevice={externalDeviceModalState?.externalDevice}
        onHide={() => setExternalDeviceModalState(undefined)}
      />
    </>
  );
};

export default ExternalDevicesList;
