import * as React from 'react';
import {
  ButtonColorType,
  getInitialDeviceState,
  getRoomIconById,
  RadioInput,
  useDeviceStateDispatch,
} from '@streda/web_components';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { IAddDeviceStepComponent } from '../add-external-device.types';
import { StepHeader } from '../../../../common/overlay-modal-steps/overlay-modal-steps';
import {
  ActionButton,
  DevicePropertyLabel,
  DevicePropertyValue,
  SectionContainer,
} from '../device-pairing/captured-device-step/captured-device-step.components';
import { AppContext } from '../../../../../contexts/app-context/app-context';
import { onDeviceRoomChange } from '../../external-devices-list/external-devices-list.logic';
import { AppStateActionType } from '../../../../../contexts/app-context/app-context.types';

export const RadioInputLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const RadioInputsContainer = styled.div`
  width: 100%;
  max-height: 262px;
  overflow: auto;

  label {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

const RoomAssigning = function(props: IAddDeviceStepComponent) {
  const [selectedRoom, setSelectedRoom] = React.useState<string>();
  const { device } = props;
  const { zigbeeId } = device;
  const [appContext, dispatch] = React.useContext(AppContext);
  const deviceStateDispatch = useDeviceStateDispatch();
  const { rooms, currentLocationId, externalDevices } = appContext;
  const history = useHistory();
  const onAddClick = () => {
    onDeviceRoomChange(currentLocationId, zigbeeId, selectedRoom).finally(() => {
      const updatedRooms = rooms.map(room => {
        if (room.id !== selectedRoom) {
          return room;
        }

        const { externalDevices } = room;

        return {
          ...room,
          externalDevices: [externalDevices, device],
        };
      });
      dispatch({
        type: AppStateActionType.UPDATE_EXTERNAL_DEVICES,
        externalDevices: {
          ...externalDevices,
          [selectedRoom]: [...externalDevices[selectedRoom], device],
        },
      });
      dispatch({
        type: AppStateActionType.UPDATE_ROOMS,
        rooms: updatedRooms,
      });
      getInitialDeviceState(deviceStateDispatch, updatedRooms, currentLocationId);
      history.push(`/external-devices`);
    });
  };

  return (
    <>
      <StepHeader>Assignee to room</StepHeader>
      <SectionContainer>
        <p>
          <DevicePropertyLabel>Name: </DevicePropertyLabel>
          <DevicePropertyValue>{device.description}</DevicePropertyValue>
        </p>
        <p>
          <DevicePropertyLabel>Model: </DevicePropertyLabel>
          <DevicePropertyValue>{device.model}</DevicePropertyValue>
        </p>
        <p>
          <DevicePropertyLabel>Producer: </DevicePropertyLabel>
          <DevicePropertyValue>{device.vendor}</DevicePropertyValue>
        </p>
      </SectionContainer>
      <RadioInputsContainer>
        {rooms.map(room => (
          <RadioInput
            name="radio-group"
            value={room.id}
            onChange={changeEvent => setSelectedRoom(changeEvent.currentTarget.value)}
            checked={selectedRoom === room.id}
            label={
              <RadioInputLabel>
                {getRoomIconById(room.id)}
                {room.name}
              </RadioInputLabel>
            }
          />
        ))}
      </RadioInputsContainer>
      <ActionButton
        type="button"
        text="Add"
        disabled={!selectedRoom}
        onClick={() => onAddClick()}
        testId="assign-external-device-to-room-button"
        colorType={ButtonColorType.PRIMARY}
      />
    </>
  );
};

export default RoomAssigning;
