import styled from 'styled-components';
import { Button, CheckIcon } from '@streda/web_components';

export const DevicePropertyLabel = styled.span`
  color: ${({ theme }) => theme.colors.font3};
  font-size: 16px;
`;

export const DevicePropertyValue = styled.span`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 16px;
`;

export const FunctionsDescriptionLabel = styled.p`
  color: ${({ theme }) => theme.colors.font3};
  font-size: 16px;
`;

export const SectionContainer = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  margin-right: 8px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
