import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as React from 'react';
import { render } from 'react-dom';
import {
  DeviceConfigurationsProvider,
  DeviceStateProvider,
  ModalProvider,
} from '@streda/web_components';
import { msalConfig } from './authConfig';
import App from './components/App';
import { AnalyticsProvider } from './contexts/Analytics/Analytics';
import AppContextProvider from './contexts/app-context/app-context.provider';
import { ThemeContextProvider } from './ThemeContext';

const rootEl = document.getElementById('root');

const msalInstance = new PublicClientApplication(msalConfig);

render(
  <div>
    <DeviceStateProvider>
      <DeviceConfigurationsProvider>
        <AppContextProvider>
          <ThemeContextProvider>
            <AnalyticsProvider>
              <ModalProvider>
                <MsalProvider instance={msalInstance}>
                  <App />
                </MsalProvider>
              </ModalProvider>
            </AnalyticsProvider>
          </ThemeContextProvider>
        </AppContextProvider>
      </DeviceConfigurationsProvider>
    </DeviceStateProvider>
  </div>,
  rootEl,
);
