import * as React from 'react';
import { Location, History, useLocation, useHistory } from 'react-router-dom';
import { updateQueryParams, hasQueryParam } from '@streda/web_components';
import LedsModeSelection from './leds-mode-selection/leds-mode-selection';
import { ILedsSettings } from './leds-settings.types';
import { StyledModal } from './leds-settings.components';

const LedsSettings: React.FC<ILedsSettings> = function({
  triggerQueryParam,
  configurationProvider,
  onConfigurationChange,
  ledsLevel,
}) {
  const location = useLocation();
  const history = useHistory();
  const [showSettings, setShowSettings] = React.useState<boolean>(false);

  React.useEffect(() => {
    const hasShowSettingsParam = hasQueryParam(triggerQueryParam, location as Location);

    setShowSettings(hasShowSettingsParam);
  }, [location]);

  return (
    <StyledModal
      title={`LED's ${ledsLevel} Settings`}
      show={showSettings}
      onHide={() =>
        updateQueryParams(
          {
            [triggerQueryParam]: undefined,
          },
          history as History,
          location as Location,
        )
      }
    >
      <LedsModeSelection
        configurationProvider={configurationProvider}
        onConfigurationChange={onConfigurationChange}
        ledsLevel={ledsLevel}
      />
    </StyledModal>
  );
};

export default LedsSettings;
