import styled, { css } from 'styled-components';
import {
  ExternalClusterNameEnums,
  GridFullRow,
  GridTale,
  IntersectIcon,
  PulseSwitchIcon,
  SubGrid,
  SwitchButton,
} from '@streda/web_components';
import * as React from 'react';

export const DeviceTale = styled(GridTale)`
  width: 100%;
  background-color: ${({ theme }) => `${theme.colors.background3}90`};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.background3}dd`};

    .action-btn {
      display: flex;
    }
  }
`;

export const Title = styled(GridFullRow)`
  color: ${({ theme }) => theme.colors.font1};
  font-size: 24px;
`;

export const DeviceTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.font1};
`;

export const DeviceLabel = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.font1};
`;

export const DeviceHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Header = styled(GridFullRow)`
  display: flex;
  margin-top: 56px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSubGrid = styled(SubGrid)<{ dragDropHighlight: boolean }>`
  min-height: 40px;
  ${({ dragDropHighlight, theme }) =>
    dragDropHighlight &&
    css`
      background-color: ${theme.colors.elevation1};
      border: 1px dashed ${theme.colors.font3};
      margin: -16px;
      padding: 16px;
    `}}
`;
