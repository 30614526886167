import * as React from 'react';
import {
  ActionXYColorType,
  clusterActionsMap,
  ColorStateModeEnums,
  DeviceStateType,
  ExternalClusterNameEnums,
  DeviceFunctionsEnum,
  externalDevicesActions,
  useDebounce,
  XYColorAction,
} from '@streda/web_components';
import { performDeviceAction } from '../../external-device-tile.logic';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { IActionComponent } from '../../external-device-tile.types';

const XyColorSlider: React.FC<IActionComponent> = function({
  externalDevice,
  deviceStates,
  setPending,
  disabled,
}) {
  const [appContext] = React.useContext(AppContext);
  const colorState = deviceStates.find(state => state.type === DeviceStateType.COLOR_STATE)?.data;
  const xyColorState =
    colorState && colorState.mode === ColorStateModeEnums.XY_COLOR
      ? colorState.value
      : externalDevicesActions[ExternalClusterNameEnums.XY_COLOR][
          DeviceFunctionsEnum.default
        ]().Color;

  const { currentLocationId } = appContext;
  const [xyColor, setXyColor] = React.useState<{ x: number; y: number }>(xyColorState);
  const debouncedXyColor = useDebounce<{ x: number; y: number }>(xyColor, 500);

  React.useEffect(() => {
    if (!debouncedXyColor || xyColorState === xyColor) {
      return;
    }

    setPending(true);
    performDeviceAction<ActionXYColorType>(
      externalDevice,
      currentLocationId,
      ExternalClusterNameEnums.XY_COLOR,
      {
        Color: debouncedXyColor,
      },
    )
      .catch(() => setXyColor(xyColorState))
      .finally(() => setPending(false));
  }, [debouncedXyColor]);

  return (
    <XYColorAction
      disabled={disabled}
      onChange={value => setXyColor(value.targetActionParameters.Color)}
      targetAction={{
        targetAction:
          clusterActionsMap[ExternalClusterNameEnums.XY_COLOR][DeviceFunctionsEnum.default],
        targetActionParameters: {
          Color: xyColor,
        },
      }}
    />
  );
};

export default XyColorSlider;
