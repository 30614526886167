import * as React from 'react';
import {
  ButtonColorType,
  FailureRoundIcon,
  SnapInStatusEnum,
  SuccessIcon,
} from '@streda/web_components';
import { ThemeContext } from 'styled-components';
import {
  ActionButtonsContainer,
  Description,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import NewSnapInContext from '../../add-new-snap-in.context';
import ActionBtn from '../../registration/registration-status/registration-status.components';
import { Checklist, ChecklistItem } from '../pairing-countdown/pairing-countdown.components';

const PairingSuccess: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  const { colors } = React.useContext(ThemeContext);

  React.useEffect(() => {
    setTimeout(() => onNextStep(), 3000);
  }, []);

  return (
    <>
      <StepHeader>Snap-in paired</StepHeader>
      <SuccessIcon fill={colors.secondary} width={145} height={145} />
      <Checklist>
        <ChecklistItem checked label="Interview started" />
        <ChecklistItem checked label="Device joined the network" />
      </Checklist>
    </>
  );
};

const PairingFailure: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { colors } = React.useContext(ThemeContext);
  const { onNextStep } = props;

  return (
    <>
      <StepHeader>No response</StepHeader>
      <FailureRoundIcon fill={colors.alert} width={145} height={145} />
      <Description>
        Check all points and retry. Make sure that snap-in is in pairing mode. Wait for fast blue
        blinking!
      </Description>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Try again"
          onClick={() => onNextStep(3)}
          colorType={ButtonColorType.PRIMARY}
        />
      </ActionButtonsContainer>
    </>
  );
};

const PairingStatus: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { newSnapIn } = React.useContext(NewSnapInContext);
  const { snapInStatus } = newSnapIn;

  return (
    <>
      {snapInStatus === SnapInStatusEnum.Detected ? (
        <PairingSuccess {...props} />
      ) : (
        <PairingFailure {...props} />
      )}
    </>
  );
};

export default PairingStatus;
