import * as React from 'react';
import {
  ButtonColorType,
  getDockSizeByDockCode,
  SinglePlaceholderSnapIn,
  TriplePlaceholderSnapIn,
  usePromise,
  getSnapInIconByState,
} from '@streda/web_components';
import { StepHeader } from '../../snap-in-replace.components';
import {
  SnapInDetailLabel,
  SnapInDetailValue,
} from '../../add-new-snap-in/registration/snap-in-details/snap-in-details.components';
import {
  AddSnapInToSwapBtn,
  ArrowsIcon,
  ClearSnapInBtn,
  SelectedSnapInWrapper,
  SnapInDetailContainer,
  SnapInDetailsWrapper,
  SnapInPlaceholderDescription,
  SnapInPlaceholderWrapper, SnapInRenderWrapper,
  SnapInWrapper,
  SwapButton,
} from './snap-in-to-swap-select.components';
import { getSnapInTypes } from '../../../../../utils/api/dictionary';
import { ISnapInReplaceStepComponent } from '../../snap-in-replace.types';
import SnapInSelectModal from './snap-in-select-modal/snap-in-select-modal';
import { ISelectedSnapIn } from './snap-in-select-modal/snap-in-select-modal.types';
import { swapSnapIns } from '../../../../../utils/api/snap-in.api';
import { AppContext } from '../../../../../contexts/app-context/app-context';
import { snapInStatusesTranslations } from './snap-in-select-modal/snap-in-select-modal.translations';

const SnapIn = function({ dock, room }) {
  const { dockCode, number, cableType, snapInState } = dock;
  const SnapInImg = getSnapInIconByState(snapInState, dockCode);
  const getSnapInTypesPromise = React.useMemo(getSnapInTypes, []);
  const [snapInTypes] = usePromise(getSnapInTypesPromise, []);
  const snapInToSwapType = snapInTypes.find(({ symbol }) => symbol === dockCode) || {};

  return (
    <SnapInWrapper>
      <SnapInRenderWrapper>
        <SnapInImg height={112} width="100%" />
      </SnapInRenderWrapper>
      <SnapInDetailsWrapper>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Room</SnapInDetailLabel>
          <SnapInDetailValue>{room.name}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Snap-in type</SnapInDetailLabel>
          <SnapInDetailValue>{snapInToSwapType.name}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Dock type & ID</SnapInDetailLabel>
          <SnapInDetailValue>
            {dockCode} - {number}
          </SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Cable type</SnapInDetailLabel>
          <SnapInDetailValue>{cableType}</SnapInDetailValue>
        </SnapInDetailContainer>
        <SnapInDetailContainer>
          <SnapInDetailLabel>Snap-in state</SnapInDetailLabel>
          <SnapInDetailValue>{snapInStatusesTranslations[snapInState]}</SnapInDetailValue>
        </SnapInDetailContainer>
      </SnapInDetailsWrapper>
    </SnapInWrapper>
  );
};

const SnapInSelectPlaceholder = function({ dock, setSelectedSnapIn }) {
  const { dockCode } = dock;
  const dockSize = getDockSizeByDockCode(dockCode);
  const SnapInPlaceholderImg = dockSize === 1 ? SinglePlaceholderSnapIn : TriplePlaceholderSnapIn;
  const [showSnapInSelectModal, setShowSnapInSelectModal] = React.useState<boolean>(false);

  return (
    <>
      <SnapInPlaceholderWrapper>
        <div>
          <SnapInPlaceholderImg height={112} width="100%" />
        </div>
        <SnapInDetailsWrapper>
          <SnapInPlaceholderDescription>
            Find snap-in to swap from list of rooms. You will see only available & compatible
            snap-ins
          </SnapInPlaceholderDescription>
          <AddSnapInToSwapBtn onClick={() => setShowSnapInSelectModal(true)}>
            Add snap-in to swap
          </AddSnapInToSwapBtn>
        </SnapInDetailsWrapper>
      </SnapInPlaceholderWrapper>
      <SnapInSelectModal
        show={showSnapInSelectModal}
        dock={dock}
        onHide={() => setShowSnapInSelectModal(false)}
        onDockSelect={setSelectedSnapIn}
      />
    </>
  );
};

const SnapInToSwapSelect: React.FC<ISnapInReplaceStepComponent> = function({
  dock,
  room,
  onNextStep,
}) {
  const [selectedSnapIn, setSelectedSnapIn] = React.useState<ISelectedSnapIn>();
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;

  return (
    <>
      <StepHeader>Swap snap-in</StepHeader>
      <SnapIn dock={dock} room={room} />
      <ArrowsIcon width={54} height={54} />
      {selectedSnapIn ? (
        <SelectedSnapInWrapper>
          <SnapIn dock={selectedSnapIn.dock} room={selectedSnapIn.room} />
          <ClearSnapInBtn onClick={() => setSelectedSnapIn(undefined)}>Clear</ClearSnapInBtn>
        </SelectedSnapInWrapper>
      ) : (
        <SnapInSelectPlaceholder dock={dock} setSelectedSnapIn={setSelectedSnapIn} />
      )}
      {selectedSnapIn && (
        <SwapButton
          type="button"
          text="Start swapping"
          onClick={() =>
            swapSnapIns(currentLocationId, dock.number, selectedSnapIn.dock.number).then(() =>
              onNextStep(),
            )
          }
          colorType={ButtonColorType.WHITE}
        />
      )}
    </>
  );
};

export default SnapInToSwapSelect;
