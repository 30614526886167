import * as React from 'react';
import { showLoader, ModalLoader, LoaderMode, hideLoader } from '@streda/web_components';

export const loadData = (fetchingData: boolean) => {
  if (fetchingData) {
    return showLoader(<ModalLoader />, {
      mode: LoaderMode.MODAL,
      loaderContainerSelector: 'location_analytics_table',
    });
  }
  return hideLoader();
};

export default loadData;
