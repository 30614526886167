import * as React from 'react';
import {
  getLocationPowerConsumption,
  getRoomPowerConsumption,
  LocationAnalytics,
  LocationLevelPowerConsumptionDto,
  LocationPowerConsumptionParams,
  PagedResultOfLocationLevelPowerConsumptionDto,
  RoomAnalytics,
  RoomLevelPowerConsumptionDto,
  RoomPowerConsumptionParams,
} from '@streda/web_components';
import { getAccount } from '../../utils/auth.utils';

export enum AnalyticsActionType {
  GET_LOCATION_DATA = 'GET_LOCATION_DATA',
  GET_LOCATION_DATA_FAILURE = 'GET_LOCATION_DATA_FAILURE',
  GET_ROOM_DATA = 'GET_ROOM_DATA',
  CLEAR_ROOM_DATA = 'CLEAR_ROOM_DATA',
  GET_ROOM_DATA_FAILURE = 'GET_ROOM_DATA_FAILURE',
  REMOVE_ROOM_DATA_FAILURE = 'REMOVE_ROOM_DATA_FAILURE',
  HANDLE_ROOM_MODAL = 'HANDLE_ROOM_MODAL',
}

export type AnalyticsState = {
  locationData: LocationAnalytics;
  roomData: RoomAnalytics;
  roomModalOpen: boolean;
};

export const AnalyticsStateContext = React.createContext<AnalyticsState | undefined>(undefined);

type Action =
  | {
      type: AnalyticsActionType.GET_LOCATION_DATA;
      locationPowerConsumption: LocationLevelPowerConsumptionDto;
      tableData: RoomLevelPowerConsumptionDto[];
    }
  | {
      type: AnalyticsActionType.GET_LOCATION_DATA_FAILURE;
    }
  | {
      type: AnalyticsActionType.GET_ROOM_DATA;
      roomPowerConsumption: RoomLevelPowerConsumptionDto;
    }
  | {
      type: AnalyticsActionType.CLEAR_ROOM_DATA;
    }
  | {
      type: AnalyticsActionType.GET_ROOM_DATA_FAILURE;
    }
  | {
      type: AnalyticsActionType.REMOVE_ROOM_DATA_FAILURE;
    }
  | {
      type: AnalyticsActionType.HANDLE_ROOM_MODAL;
      roomModalOpen: boolean;
    };

type AnalyticsDispatch = React.Dispatch<Action>;

export const AnalyticsDispatchContext = React.createContext<AnalyticsDispatch | undefined>(
  undefined,
);

const initialAnalyticsState: AnalyticsState = {
  locationData: {
    locationPowerConsumption: undefined,
    loadingLocationData: true,
    error: false,
    tableData: undefined,
  },
  roomData: {
    roomPowerConsumption: undefined,
    loadingRoomData: true,
    error: false,
  },
  roomModalOpen: false,
};

function AnalyticsReducer(state: AnalyticsState, action: Action): AnalyticsState {
  switch (action.type) {
    case AnalyticsActionType.GET_LOCATION_DATA:
      return {
        ...state,
        locationData: {
          ...state.locationData,
          locationPowerConsumption: action.locationPowerConsumption,
          tableData: action.tableData,
          error: false,
          loadingLocationData: false,
        },
      };
    case AnalyticsActionType.GET_LOCATION_DATA_FAILURE:
      return {
        ...state,
        locationData: {
          ...state.locationData,
          error: true,
          loadingLocationData: false,
        },
      };
    case AnalyticsActionType.GET_ROOM_DATA:
      return {
        ...state,
        roomData: {
          ...state.locationData,
          roomPowerConsumption: action.roomPowerConsumption,
          error: false,
          loadingRoomData: false,
        },
      };
    case AnalyticsActionType.CLEAR_ROOM_DATA:
      return {
        ...state,
        roomData: {
          ...state.roomData,
          roomPowerConsumption: undefined,
          error: false,
        },
      };
    case AnalyticsActionType.GET_ROOM_DATA_FAILURE:
      return {
        ...state,
        roomModalOpen: false,
        roomData: {
          ...state.roomData,
          error: true,
          loadingRoomData: false,
        },
      };
    case AnalyticsActionType.REMOVE_ROOM_DATA_FAILURE:
      return {
        ...state,
        roomData: {
          ...state.roomData,
          error: false,
        },
      };
    case AnalyticsActionType.HANDLE_ROOM_MODAL:
      return {
        ...state,
        roomModalOpen: action.roomModalOpen,
      };
    default:
      throw new Error('Unhandled action');
  }
}

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(AnalyticsReducer, initialAnalyticsState);

  return (
    <AnalyticsDispatchContext.Provider value={dispatch}>
      <AnalyticsStateContext.Provider value={React.useMemo(() => state, [state])}>
        {children}
      </AnalyticsStateContext.Provider>
    </AnalyticsDispatchContext.Provider>
  );
}

export function useAnalyticsState() {
  const state = React.useContext(AnalyticsStateContext);
  if (!state) throw new Error('AnalyticsProvider not found');
  return state;
}

export function useAnalyticsDispatch() {
  const dispatch = React.useContext(AnalyticsDispatchContext);
  if (!dispatch) throw new Error('AnalyticsProvider not found');
  return dispatch;
}

export const getLocationAnalyticsData = (
  params: LocationPowerConsumptionParams,
  dispatch: AnalyticsDispatch,
) => {
  if (!params.locationId) {
    return;
  }
  getLocationPowerConsumption(
    params,
    powerConsumptionRes => {
      const { data }: { data: PagedResultOfLocationLevelPowerConsumptionDto } = powerConsumptionRes;
      return dispatch({
        type: AnalyticsActionType.GET_LOCATION_DATA,
        locationPowerConsumption: data.data[0],
        tableData: data.data[0].rooms,
      });
    },
    getAccount,
    () => {
      return dispatch({
        type: AnalyticsActionType.GET_LOCATION_DATA_FAILURE,
      });
    },
  );
};

export const getRoomAnalyticsData = (
  params: RoomPowerConsumptionParams,
  dispatch: AnalyticsDispatch,
) => {
  if (!params.locationId || !params.roomId) {
    return;
  }
  getRoomPowerConsumption(
    params,
    powerConsumptionRes => {
      const { data }: { data: RoomLevelPowerConsumptionDto } = powerConsumptionRes;
      return dispatch({
        type: AnalyticsActionType.GET_ROOM_DATA,
        roomPowerConsumption: data,
      });
    },
    getAccount,
    () => {
      return dispatch({
        type: AnalyticsActionType.GET_ROOM_DATA_FAILURE,
      });
    },
  );
};

export const roomModalHandler = (dispatch: AnalyticsDispatch, open: boolean) => {
  if (!open)
    setTimeout(() => {
      dispatch({
        type: AnalyticsActionType.CLEAR_ROOM_DATA,
      });
    }, 500);
  return dispatch({
    type: AnalyticsActionType.HANDLE_ROOM_MODAL,
    roomModalOpen: open,
  });
};

export const useLocationPowerConsumption = (
  params: LocationPowerConsumptionParams,
  refresh?: number,
): {
  locationData: LocationAnalytics;
} | null => {
  const dispatch = useAnalyticsDispatch();
  const { locationData } = useAnalyticsState();
  const { locationId } = params;
  React.useEffect(() => {
    async function getConsumption() {
      await getLocationAnalyticsData({ ...params }, dispatch);
    }
    getConsumption();
  }, [locationId, refresh, params]);
  return {
    locationData,
  };
};

export const useRoomPowerConsumption = (
  params: RoomPowerConsumptionParams,
  history?: any,
): { roomData: RoomAnalytics } => {
  const dispatch = useAnalyticsDispatch();
  const { roomId } = params;
  const { roomData } = useAnalyticsState();
  React.useEffect(() => {
    if (!roomId) {
      return;
    }
    async function getConsumption() {
      await getRoomAnalyticsData(params, dispatch);
    }
    getConsumption();
  }, [roomId]);
  if (roomData.error) {
    setTimeout(() => {
      history.replace(`/analytics`);
      dispatch({
        type: AnalyticsActionType.REMOVE_ROOM_DATA_FAILURE,
      });
    }, 500);
  }
  return { roomData };
};
