import * as React from 'react';
import {
  getLocationSnapInConfiguration,
  setLocationSnapInConfiguration,
} from '@streda/web_components';
import LedsSettings from '../leds-settings/leds-settings';
import { AppContext } from '../../contexts/app-context/app-context';
import { initAppState } from '../main-view.logic';

const LedsHomeSettings = function() {
  const [appContext, dispatchGlobalAction] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const configurationProvider = () => getLocationSnapInConfiguration(currentLocationId);
  const onConfigurationChange = configuration =>
    setLocationSnapInConfiguration(currentLocationId, configuration).then(() =>
      initAppState(currentLocationId, dispatchGlobalAction),
    );

  return (
    <LedsSettings
      triggerQueryParam="ledsHomeSettings"
      configurationProvider={configurationProvider}
      onConfigurationChange={onConfigurationChange}
      ledsLevel="Home"
    />
  );
};

export default LedsHomeSettings;
