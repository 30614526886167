import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonColorType, getSnapInIcon } from '@streda/web_components';
import {
  ActionBtn,
  ActionButtonsContainer,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';

const UnregisterSnapIn: React.FC<ISnapInReplaceStepComponent> = function({
  dock,
  room,
  onNextStep,
}) {
  const { dockCode, number } = dock;
  const history = useHistory();
  const SnapInImg = getSnapInIcon(dockCode);

  return (
    <>
      <StepHeader>Remove snap-in</StepHeader>
      <SnapInImg height={112} width="100%" />
      <GrayedDescription>
        Are you sure to remove snap-in from Dock ID: <span className="highlight">{number}</span> in{' '}
        <span className="highlight">{room.name}</span>
      </GrayedDescription>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Cancel"
          onClick={() => history.goBack()}
          colorType={ButtonColorType.WHITE}
        />
        <ActionBtn
          type="button"
          text="Remove"
          onClick={() => onNextStep()}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default UnregisterSnapIn;
