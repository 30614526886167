import {
  CountdownTimer,
  SignalRListenersEnums,
  SignalRMethodEnums,
  SnapInStatusEnum,
  SignalR,
} from '@streda/web_components';
import * as React from 'react';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { StepHeader } from '../../../../../common/overlay-modal-steps/overlay-modal-steps';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import { GrayedDescription } from '../../../snap-in-replace.components';
import {
  IPairingMessage,
  PairingStatusesEnums,
} from '../../../../external-devices/add-external-device/device-pairing/device-pairing.types';
import NewSnapInContext from '../../add-new-snap-in.context';
import { getSnapInById } from '../../../../../../utils/api/snap-in.api';
import { Checklist, ChecklistItem, InfoContainer } from './pairing-countdown.components';

const checkingSnapInStatusIntervalTime = 10000;
const PairingCountdown = function(props: ISnapInReplaceStepComponent) {
  const { onNextStep } = props;
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const { setNewSnapIn, newSnapIn } = React.useContext(NewSnapInContext);
  const [isInterviewStarted, setIsInterviewStarted] = React.useState<boolean>(false);
  const signalR = SignalR.getInstance();

  React.useEffect(() => {
    signalR.listenOnMethod(
      SignalRListenersEnums.ZIGBEE_PAIRING_NOTIFICATION,
      (data: IPairingMessage) => {
        const { pairingStatus } = data;

        if (pairingStatus === PairingStatusesEnums.INTERVIEW_STARTED) {
          setIsInterviewStarted(true);
        }

        if (pairingStatus === PairingStatusesEnums.INTERVIEW_SUCCESS) {
          setNewSnapIn({
            snapInStatus: SnapInStatusEnum.Detected,
          });
          onNextStep();
        }
      },
    );
    signalR.invokeMethod(SignalRMethodEnums.SUBSCRIBE_SNAP_IN_PAIRING, currentLocationId);

    return () => {
      signalR.invokeMethod(SignalRMethodEnums.UNSUBSCRIBE_SNAP_IN_PAIRING, currentLocationId);
      signalR.stopListenOnMethod(SignalRListenersEnums.ZIGBEE_PAIRING_NOTIFICATION);
    };
  }, []);

  React.useEffect(() => {
    const getSnapInDetailsInterval = setInterval(() => {
      getSnapInById(newSnapIn.id).then(({ snapInStatus }) => {
        if (snapInStatus === SnapInStatusEnum.Detected) {
          setNewSnapIn({
            snapInStatus: SnapInStatusEnum.Detected,
          });
          onNextStep();
        }
      });
    }, checkingSnapInStatusIntervalTime);

    return () => clearInterval(getSnapInDetailsInterval);
  }, []);

  return (
    <>
      <StepHeader>Device pairing</StepHeader>
      <InfoContainer>
        <span>Press shortly pairing button</span>
        <span>The light should blink blue</span>
        <span>It can take up to 3 minutes</span>
      </InfoContainer>
      <CountdownTimer time={180} size={145} onCountdownFinish={() => onNextStep()} />
      <Checklist>
        <ChecklistItem checked={isInterviewStarted} label="Interview started" />
        <ChecklistItem label="Device joined the network" />
      </Checklist>
      <GrayedDescription>Please wait...</GrayedDescription>
    </>
  );
};

export default PairingCountdown;
