import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { getRoomSnapInConfiguration, setRoomSnapInConfiguration } from '@streda/web_components';
import { AppContext } from '../../../../../contexts/app-context/app-context';
import LedsSettings from '../../../../leds-settings/leds-settings';
import { initAppState } from '../../../../main-view.logic';

const LedsRoomSettings = function({ match }) {
  const { params } = match;
  const { roomId } = params;
  const [appContext, dispatchGlobalAction] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const configurationProvider = () => getRoomSnapInConfiguration(currentLocationId, roomId);
  const onConfigurationChange = configuration =>
    setRoomSnapInConfiguration(currentLocationId, roomId, configuration).then(() =>
      initAppState(currentLocationId, dispatchGlobalAction),
    );

  return (
    <LedsSettings
      triggerQueryParam="ledsRoomSettings"
      configurationProvider={configurationProvider}
      onConfigurationChange={onConfigurationChange}
      ledsLevel="Room"
    />
  );
};

export default withRouter(LedsRoomSettings);
