import * as React from 'react';
import { ISnapInReplaceStepComponent, IStep } from '../../snap-in-replace.types';
import TestingInstruction from './testing-instruction/testing-instruction';

const stepsDefinition: IStep<ISnapInReplaceStepComponent>[] = [
  {
    Component: TestingInstruction,
  },
];

const Testing: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { onNextStep, dock, snapIn, room } = props;
  const [step, setStep] = React.useState(0);
  const StepComponent = stepsDefinition[step].Component;

  return (
    <StepComponent
      onNextStep={(stepNumber = step + 1) => {
        if (step >= stepsDefinition.length - 1) {
          onNextStep();
          return;
        }
        setStep(stepNumber);
      }}
      dock={dock}
      snapIn={snapIn}
      room={room}
    />
  );
};

export default Testing;
