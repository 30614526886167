import * as React from 'react';
import { SnapInProductDto } from '@streda/web_components';

const NewSnapInContext = React.createContext<{
  newSnapIn: SnapInProductDto;
  setNewSnapIn: (snapInData: SnapInProductDto) => void;
}>({
  newSnapIn: null,
  setNewSnapIn: () => {},
});

export default NewSnapInContext;
