import {
  ArrowsLoopIcon,
  SignalR,
  SignalRListenersEnums,
  SignalRMethodEnums,
} from '@streda/web_components';
import * as React from 'react';
import { IAddDeviceStepComponent } from '../../add-external-device.types';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { DeviceOriginsEnums, IPairingMessage, PairingStatusesEnums } from '../device-pairing.types';
import {
  StepHeader,
  StepParagraph,
} from '../../../../../common/overlay-modal-steps/overlay-modal-steps';

const InfoStep = function(props: IAddDeviceStepComponent) {
  const { onNextStep, onDeviceUpdate } = props;
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const signalR = SignalR.getInstance();

  React.useEffect(() => {
    signalR.listenOnMethod(
      SignalRListenersEnums.ZIGBEE_PAIRING_NOTIFICATION,
      (data: IPairingMessage) => {
        const { zigbeeId, deviceOrigin, pairingStatus } = data;

        if (
          deviceOrigin === DeviceOriginsEnums.EXTERNAL &&
          pairingStatus === PairingStatusesEnums.INTERVIEW_SUCCESS
        ) {
          onDeviceUpdate({ zigbeeId });
          onNextStep();
        }
      },
    );
    signalR.invokeMethod(SignalRMethodEnums.SUBSCRIBE_SNAP_IN_PAIRING, currentLocationId);

    return () => {
      signalR.invokeMethod(SignalRMethodEnums.UNSUBSCRIBE_SNAP_IN_PAIRING, currentLocationId);
      signalR.stopListenOnMethod(SignalRListenersEnums.ZIGBEE_PAIRING_NOTIFICATION);
    };
  }, []);

  return (
    <>
      <StepHeader>Device pairing</StepHeader>
      <ArrowsLoopIcon width={116} height={116} />
      <StepParagraph>
        Follow the instruction for pairing steps dedicated to your device.
        <br />
        <br />
        After that Streda will detect devices in the network
      </StepParagraph>
    </>
  );
};

export default InfoStep;
