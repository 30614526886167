/* eslint-disable import/no-cycle */
import * as React from 'react';
import { useMatchBreakpoints, Text, ArrowRightIcon } from '@streda/web_components';
import styled from 'styled-components';
import { SinglePolicy } from '../types';

const MOBILE_CLOSED_ROW_HEIGHT = 72;
const MOBILE_OPENED_ROW_HEIGHT = 48;

const TitleText = styled(Text)`
  font-weight: 500;
  font-size: 40px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 48px;
`;

const PointsMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 6;
  margin-top: 140px;
  height: max-content;
`;

const MobilePointsMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 8;
  margin-top: 64px;
  overflow: hidden;
  height: max-content;
`;

const MobileMenuWrapper = styled.div<{ menuToggled: boolean; maxHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 0px;
  height: ${({ menuToggled, maxHeight }) =>
    menuToggled ? `auto` : `${MOBILE_CLOSED_ROW_HEIGHT}px`};
  border-top: 2px solid #45464c;
  border-bottom: 2px solid #45464c;
`;

const PointRow = styled(Text)<{ menuToggled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 24px;
  cursor: pointer;
  scrollbar-width: none;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.medium} {
    justify-content: flex-start;
  }
`;

const StyledArrow = styled(ArrowRightIcon)<{ down?: boolean }>`
  ${({ down }) => down && 'transform: rotate(90deg)'};
`;

interface IPrivacyMenuProps {
  menuData?: SinglePolicy[];
  onRowClick?: (id: number) => void;
  activeId?: number;
}

const PrivacyMenu: React.FunctionComponent<IPrivacyMenuProps> = ({
  menuData,
  onRowClick,
  activeId,
}) => {
  const { isMedium } = useMatchBreakpoints();
  const [mobileOpened, setMobileOpened] = React.useState(false);

  let content = (
    <PointsMenu>
      <TitleText>Terms & Privacy</TitleText>
      {menuData.map(singlePolicy => {
        return (
          <PointRow
            id={singlePolicy.id.toString()}
            key={singlePolicy.id}
            onClick={() => {
              onRowClick(singlePolicy.id);
            }}
          >
            {activeId === singlePolicy.id && <StyledArrow />}
            {singlePolicy.title}
          </PointRow>
        );
      })}
    </PointsMenu>
  );
  if (isMedium) {
    content = (
      <MobilePointsMenu>
        <TitleText>Terms & Privacy</TitleText>
        <MobileMenuWrapper
          menuToggled={mobileOpened}
          maxHeight={menuData.length * MOBILE_OPENED_ROW_HEIGHT}
        >
          {menuData.map(singlePolicy => {
            return (
              <PointRow
                menuToggled={mobileOpened}
                onClick={() => {
                  if (mobileOpened) {
                    setMobileOpened(false);
                    onRowClick(singlePolicy.id);
                  } else {
                    setMobileOpened(true);
                  }
                }}
              >
                {mobileOpened ? singlePolicy.title : menuData.find(el => el.id === activeId).title}
                {mobileOpened ? (
                  activeId === singlePolicy.id && <StyledArrow />
                ) : (
                  <StyledArrow down />
                )}
              </PointRow>
            );
          })}
        </MobileMenuWrapper>
      </MobilePointsMenu>
    );
  }
  return <>{content}</>;
};

export default PrivacyMenu;
