import * as React from 'react';
import { CountdownTimer, SignalRListenersEnums, SignalRMethodEnums } from '@streda/web_components';
import { IAddDeviceStepComponent } from '../../add-external-device.types';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import {
  StepHeader,
  StepParagraph,
} from '../../../../../common/overlay-modal-steps/overlay-modal-steps';
import { useZigbeeOpening } from '../../../../../../utils/hooks/signalR.hooks';

const CountdownStep = function(props: IAddDeviceStepComponent) {
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const { onNextStep } = props;

  useZigbeeOpening(currentLocationId, () => {
    onNextStep();
  });

  return (
    <>
      <StepHeader>Opening Zigbee</StepHeader>
      <CountdownTimer time={120} size={145} onCountdownFinish={() => onNextStep(0)} />
      <StepParagraph>
        To integrate a new external device with Streda infrastructure please follow the steps
      </StepParagraph>
      <StepParagraph>Please wait ...</StepParagraph>
    </>
  );
};

export default CountdownStep;
