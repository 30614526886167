import * as React from 'react';
import {
  clusterActionsMap,
  ColorStateModeEnums,
  ColorTemperatureAction,
  DeviceStateType,
  ExternalClusterNameEnums,
  DeviceFunctionsEnum,
  externalDevicesActions,
  useDebounce,
} from '@streda/web_components';
import { performDeviceAction } from '../../external-device-tile.logic';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { IActionComponent } from '../../external-device-tile.types';

const ColorTempSlider: React.FC<IActionComponent> = function({
  externalDevice,
  deviceStates,
  setPending,
  disabled,
}) {
  const defaultEndpointClusters =
    externalDevice.endpoints.find(({ name }) => name === 'default')?.clusters || [];
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const colorState = deviceStates.find(state => state.type === DeviceStateType.COLOR_STATE)?.data;
  const colorTempState =
    colorState && colorState.mode === ColorStateModeEnums.COLOR_TEMP
      ? colorState.value
      : externalDevicesActions[ExternalClusterNameEnums.COLOR_TEMPERATURE][
          DeviceFunctionsEnum.default
        ]();
  const colorTempStateValue = colorTempState.temperature || colorTempState.Value;
  const [colorTemp, setColorTemp] = React.useState<number>(colorTempStateValue);
  const debouncedColorTemp = useDebounce<number>(colorTemp, 500);

  React.useEffect(() => {
    if (!debouncedColorTemp || colorTempStateValue === colorTemp) {
      return;
    }

    setPending(true);
    performDeviceAction<number>(
      externalDevice,
      currentLocationId,
      ExternalClusterNameEnums.COLOR_TEMPERATURE,
      debouncedColorTemp,
    )
      .catch(() => setColorTemp(colorTempStateValue))
      .finally(() => setPending(false));
  }, [debouncedColorTemp]);

  return (
    <ColorTemperatureAction
      clusters={defaultEndpointClusters}
      disabled={disabled}
      onChange={value => setColorTemp(value.targetActionParameters.Value)}
      targetAction={{
        targetAction:
          clusterActionsMap[ExternalClusterNameEnums.COLOR_TEMPERATURE][
            DeviceFunctionsEnum.default
          ],
        targetActionParameters: {
          Value: colorTemp,
        },
      }}
    />
  );
};

export default ColorTempSlider;
