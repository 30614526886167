import * as React from 'react';
import { ButtonColorType, FailureRoundIcon, Spinner, SuccessIcon } from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import {
  ActionBtn,
  ActionButtonsContainer,
  Description,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import { snapInUnregister } from '../../../../../../utils/api/snap-in.api';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { RemoveStatusEnum } from './removing-status.types';

const RemovingSuccess: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  const { colors } = React.useContext(ThemeContext);

  React.useEffect(() => {
    setTimeout(() => onNextStep(), 3000);
  }, []);

  return (
    <>
      <StepHeader>Snap-in removed</StepHeader>
      <SuccessIcon fill={colors.secondary} width={145} height={145} />
      <GrayedDescription>Success!</GrayedDescription>
    </>
  );
};

const RemovingFailure: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { colors } = React.useContext(ThemeContext);
  const { onPreviousStep } = props;
  const history = useHistory();

  return (
    <>
      <StepHeader>Snap-in not removed</StepHeader>
      <FailureRoundIcon fill={colors.alert} width={145} height={145} />
      <Description>There was an error during snap-in removing</Description>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Cancel"
          onClick={() => history.goBack()}
          colorType={ButtonColorType.WHITE}
        />
        <ActionBtn
          type="button"
          text="Try again"
          onClick={() => onPreviousStep()}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

const RemovingProgress: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { dock, room } = props;

  return (
    <>
      <StepHeader>Removing snap-in</StepHeader>
      <Spinner size={145} show />
      <Description>
        The system is removing snap-in from Dock: {dock.number} in {room.name}
      </Description>
      <Description>Please wait...</Description>
    </>
  );
};

const RemovingStatus: React.FC<ISnapInReplaceStepComponent> = function(props) {
  const { snapIn } = props;
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const [removingStatus, setRemovingStatus] = React.useState<RemoveStatusEnum>(RemoveStatusEnum.PROGRESS);

  React.useEffect(() => {
    snapInUnregister(currentLocationId, snapIn.serialNumber)
      .then(() => setRemovingStatus(RemoveStatusEnum.SUCCESS))
      .catch(() => setRemovingStatus(RemoveStatusEnum.FAILURE));
  }, []);

  if (removingStatus === RemoveStatusEnum.SUCCESS) {
    return <RemovingSuccess {...props} />;
  }

  if (removingStatus === RemoveStatusEnum.FAILURE) {
    return <RemovingFailure {...props} />;
  }

  return <RemovingProgress {...props} />;
};

export default RemovingStatus;
