import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Text } from '@streda/web_components';
import { FooterLink } from './config';

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 9;
  ${({ theme }) => theme.responsiveMargins}
`;

const FooterComponent = styled.div`
  height: 96px;
  width: 100%;
  margin-top: auto;
  border-top: 1.5px solid #5a5b61;
  background: ${({ theme }) => theme.colors.background2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQueries.xLarge} {
    padding: 0px 40px;
  }
`;

const CompanyName = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.font1};
  user-select: none;
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  .activeFooterLink {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const FooterLinkComponent = styled(NavLink)<{ spaced?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.font1};
  padding: ${({ spaced }) => `0px ${spaced ? 24 : 0}px 0 24px`};
  border-right: ${({ spaced }) => spaced && '1px solid #5A5B61'};
  text-decoration: none !important;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

interface IFooterProps {
  companyName: string;
  footerLinks: FooterLink[];
}

const Footer: React.FunctionComponent<IFooterProps> = ({ companyName, footerLinks }) => {
  return (
    <FooterWrapper>
      <FooterComponent>
        <CompanyName>{companyName}</CompanyName>
        <LinksWrapper>
          {footerLinks.map((footerLink, index) => {
            const isLast = footerLinks.length === index + 1;
            return (
              <FooterLinkComponent
                id={footerLink.label}
                key={footerLink.label}
                to={footerLink.href}
                // We have to declare it in such a way to prevent Received `true` for a non-boolean attribute error
                spaced={isLast ? 0 : 1}
                activeClassName="activeFooterLink"
              >
                {footerLink.label}
              </FooterLinkComponent>
            );
          })}
        </LinksWrapper>
      </FooterComponent>
    </FooterWrapper>
  );
};

export default Footer;
