import { Dispatch } from 'react';
import { CompanyDto } from '@streda/web_components';

export interface AppState {
  // assignedLocations: string[];
  currentLocationId: string | null;
  rooms: any[];
  docks: any[];
  locationScenes: any[];
  externalDevices: any[];
  locationDetails;
  companyDetails: CompanyDto;
}

export enum AppStateActionType {
  // UPDATE_ASSIGNED_LOCATIONS = 'UPDATE_ASSIGNED_LOCATIONS',
  UPDATE_CURRENT_LOCATION_ID = 'UPDATE_CURRENT_LOCATION_ID',
  UPDATE_ROOMS = 'UPDATE_ROOMS',
  UPDATE_DOCKS = 'UPDATE_DOCKS',
  UPDATE_SCENES = 'UPDATE_SCENES',
  UPDATE_EXTERNAL_DEVICES = 'UPDATE_EXTERNAL_DEVICES',
  UPDATE_WHOLE_STATE = 'UPDATE_WHOLE_STATE',
}

export type AppStateAction =
  // | {
  //     type: AppStateActionType.UPDATE_ASSIGNED_LOCATIONS;
  //     assignedLocations: string[];
  //   }
  | { type: AppStateActionType.UPDATE_CURRENT_LOCATION_ID; currentLocationId: string }
  | { type: AppStateActionType.UPDATE_WHOLE_STATE; state: AppState }
  | { type: AppStateActionType.UPDATE_DOCKS; docks: any[] }
  | { type: AppStateActionType.UPDATE_ROOMS; rooms: any[] }
  | { type: AppStateActionType.UPDATE_SCENES; locationScenes: any[] }
  | { type: AppStateActionType.UPDATE_EXTERNAL_DEVICES; externalDevices: any[] };

export type AppStateDispatch = Dispatch<AppStateAction>;
