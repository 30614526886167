import * as React from 'react';
import { ButtonColorType, SnapInPlugInImg } from '@streda/web_components';
import {
  ActionBtn,
  ActionButtonsContainer,
  GrayedDescription,
  StepHeader,
} from '../../../snap-in-replace.components';
import { ISnapInReplaceStepComponent } from '../../../snap-in-replace.types';
import { openZigbeeNetwork } from '../../../../../../utils/api/configuration.api';
import { AppContext } from '../../../../../../contexts/app-context/app-context';

const MountInfo: React.FC<ISnapInReplaceStepComponent> = function({ onNextStep }) {
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;

  return (
    <>
      <StepHeader>Mount snap-in</StepHeader>
      <SnapInPlugInImg width={176} height={192} />
      <GrayedDescription>
        Insert snap-in into the dock and when snap-in lights flashes briefly it is correctly on
        power
      </GrayedDescription>
      <ActionButtonsContainer>
        <ActionBtn
          type="button"
          text="Snap-in mounted"
          onClick={() => {
            openZigbeeNetwork(currentLocationId).then(() => onNextStep());
          }}
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default MountInfo;
