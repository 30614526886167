import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@streda/web_components';
// eslint-disable-next-line import/no-cycle
import PrivacyMenu from './components/PrivacyMenu';
import RichContentful from '../../../utils/richContentful';
import { SinglePolicy } from './types';

const PrivacyWrapper = styled.div`
  height: calc(100vh - 160px);
  display: grid;
  grid-column: span 8;
  grid-template-rows: 1fr;
  ${({ theme }) => theme.mediaQueries.medium} {
    grid-column: span 12;
  }
  ${({ theme }) => theme.mediaQueries.small} {
    ${({ theme }) => theme.grids.small}
  }
  ${({ theme }) => theme.mediaQueries.medium} {
    ${({ theme }) => theme.grids.medium}
  }
  ${({ theme }) => theme.mediaQueries.large} {
    ${({ theme }) => theme.grids.large}
  }
  ${({ theme }) => theme.mediaQueries.xLarge} {
    ${({ theme }) => theme.grids.xLarge}
  }
`;

const PointsDetailsWrapper = styled.div`
  grid-row: 1fr;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 8;
  margin: 64px 0px;
  ${({ theme }) => theme.mediaQueries.medium} {
    grid-column: span 6;
    margin: 140px 0px;
    height: calc(100vh - 440px);
  }
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const PointCell = styled.div<{ first?: boolean; minimumHeight?: number; unboardered?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ minimumHeight }) => minimumHeight && `min-height: ${minimumHeight + 50}px`};
  padding: ${({ first }) => (first ? '0px  0px 46px 0px' : '46px 0px')};
  border-bottom: ${({ unboardered }) => (unboardered ? 'none' : '2px solid #45464C')};
`;

const PointCellTitle = styled(Text)`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 24px;
`;

const PointCellDetails = styled(Text)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.87);
  height: 100%;
`;

interface IPrivacyProps {
  privacyItems: SinglePolicy[];
}

const Privacy: React.FunctionComponent<IPrivacyProps> = ({ privacyItems }) => {
  const [chosenElement, setChosenElement] = React.useState(1);
  const pointsWrapper = React.useRef<HTMLDivElement>(null);
  const listRefs = privacyItems
    .sort((a, b) => a.id - b.id)
    .reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, []);

  const scrollHandler = (id: number) => {
    listRefs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onRowClick = (id: number) => {
    setChosenElement(id);
    scrollHandler(id);
  };

  const detailsScrollHandler = () => {
    const el = listRefs.find((elem, _index) =>
      elem && elem.current ? elem.current.offsetTop > pointsWrapper.current.scrollTop : false,
    );
    setChosenElement(parseFloat(el.current.id));
  };

  return (
    <PrivacyWrapper>
      <PrivacyMenu
        menuData={privacyItems}
        onRowClick={id => onRowClick(id)}
        activeId={chosenElement}
      />
      <PointsDetailsWrapper
        ref={pointsWrapper}
        onWheel={() => {
          detailsScrollHandler();
        }}
      >
        {privacyItems.map((singlePolicy, index) => {
          return (
            <PointCell
              id={singlePolicy.id.toString()}
              key={singlePolicy.id}
              ref={listRefs[singlePolicy.id]}
              first={index === 0}
              minimumHeight={
                privacyItems.length === index + 1
                  ? pointsWrapper.current && pointsWrapper.current.clientHeight
                  : undefined
              }
              unboardered={privacyItems.length === index + 1}
            >
              <PointCellTitle>{singlePolicy.title}</PointCellTitle>
              <PointCellDetails>
                <RichContentful document={singlePolicy.details} />
              </PointCellDetails>
            </PointCell>
          );
        })}
      </PointsDetailsWrapper>
    </PrivacyWrapper>
  );
};

export default Privacy;
