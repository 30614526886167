import styled from 'styled-components';
import { Modal } from '@streda/web_components';
import {
  SnapInDetailLabel as DetailsLabel,
  SnapInDetailValue as DetailValue,
} from '../../../add-new-snap-in/registration/snap-in-details/snap-in-details.components';
import { SnapInDetailContainer as DetailContainer } from '../snap-in-to-swap-select.components';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: none;
    overflow: hidden;
    align-items: unset;
  }

  .modal-body {
    overflow: auto;
  }
`;

export const RoomDocksWrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
`;

export const Dock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px;
  cursor: pointer;
  gap: 8px;
  width: 208px;

  &:hover {
    background: ${({ theme }) => theme.colors.table.oddRow};
  }
`;

export const NoDocksInfo = styled.div`
  flex: 1;
  text-align: center;
  margin: 16px 0;
  color: ${({ theme }) => theme.colors.font3};
`;

export const SnapInDetailLabel = styled(DetailsLabel)`
  font-size: 14px;
`;

export const SnapInDetailValue = styled(DetailValue)`
  font-size: 14px;
`;

export const SnapInDetailContainer = styled(DetailContainer)`
  margin: 0;
  white-space: nowrap;
`;
