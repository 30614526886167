import * as React from 'react';
import { ButtonColorType, ReceiptIcon } from '@streda/web_components';
import { IAddDeviceStepComponent } from '../../add-external-device.types';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import {
  StepHeader,
  StepParagraph,
} from '../../../../../common/overlay-modal-steps/overlay-modal-steps';
import { StartButton } from '../zigbee-network-open.components';
import { openZigbeeNetwork } from '../../../../../../utils/api/configuration.api';

const InfoStep = function(props: IAddDeviceStepComponent) {
  const [appContext] = React.useContext(AppContext);
  const { currentLocationId } = appContext;
  const { onNextStep } = props;

  return (
    <>
      <StepHeader>Add new device</StepHeader>
      <ReceiptIcon width={116} height={116} />
      <StepParagraph>
        To integrate a new external device with Streda infrastructure please follow the steps
      </StepParagraph>
      <StartButton
        type="button"
        colorType={ButtonColorType.PRIMARY}
        text="Start"
        onClick={() => {
          openZigbeeNetwork(currentLocationId).then(() => onNextStep());
        }}
      />
    </>
  );
};

export default InfoStep;
