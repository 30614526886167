import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ExternalDevicesList from './external-devices-list/external-devices-list';
import AddExternalDevice from './add-external-device/add-external-device';

const ExternalDevices = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={url}>
        <ExternalDevicesList />
      </Route>
      <Route path={[`${url}/add-device`]}>
        <AddExternalDevice />
      </Route>
    </Switch>
  );
};

export default ExternalDevices;
