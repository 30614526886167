import {
  clusterActionsMap,
  DeviceActionDto,
  ExternalClusterNameEnums,
  ExternalDeviceFullDto,
  DeviceFunctionsEnum,
  externalDevicesActions,
  ToastHelper,
  ToastType,
} from '@streda/web_components';
import { setDevicesStatesForLocation } from '../../../../utils/api/devices-state.api';

export const performDeviceAction = <actionValueType>(
  externalDevice: ExternalDeviceFullDto,
  locationId: string,
  clusterName: ExternalClusterNameEnums,
  actionValue?: actionValueType,
): Promise<any> => {
  const action = clusterActionsMap[clusterName][DeviceFunctionsEnum.default];
  const actionParametersFunction: (actionParam) => any =
    externalDevicesActions[clusterName][DeviceFunctionsEnum.default];
  const actionParameters = actionParametersFunction(actionValue);
  const deviceAction: DeviceActionDto = {
    targetDevice: {
      zigbeeId: externalDevice.zigbeeId,
    },
    action,
    actionParameters,
  };

  return setDevicesStatesForLocation(locationId, deviceAction)
    .then(() => {
      ToastHelper(ToastType.SUCCESS, 'Success!. Device state has been changed');
    })
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during setting device state. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
};

export default performDeviceAction;
