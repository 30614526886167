import * as React from 'react';
import { ExternalDeviceFullDto } from '@streda/web_components';

export interface IDragDropContext {
  isDragging: boolean;
  dragFromRoom: string;
  device: ExternalDeviceFullDto;
}

export enum ExternalDeviceTypeEnums {
  LIGHT = 'light',
}
