import { getConfigurationApi, ToastHelper, ToastType } from '@streda/web_components';
import { getAccount } from '../auth.utils';

export const sendLocationToBcb = locationId => {
  return getConfigurationApi(getAccount())
    .configurationSendLocationToBcbs(locationId)
    .then(result => result);
};

export const openZigbeeNetwork = (locationId: string) =>
  getConfigurationApi(getAccount())
    .configurationStartSnapInConfiguration(locationId)
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during opening zigbee network. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });

export const closeZigbeeNetwork = (locationId: string) =>
  getConfigurationApi(getAccount())
    .configurationStopSnapInConfiguration(locationId)
    .then(response => response.data)
    .catch(error => {
      ToastHelper(
        ToastType.DANGER,
        'Error during closing zigbee network. Please try again or contact with system administrator',
        error,
      );
      return Promise.reject(error);
    });
