import styled from "styled-components";
import { Button } from '@streda/web_components';

export const InfoStepDescription = styled.p`
  color: ${({ theme }) => theme.colors.font3};
  font-size: 16px;
  line-height: 24px;
`;

export const StartButton = styled(Button)`
  width: 100%;
`;
