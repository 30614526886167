import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { ButtonColorType } from '@streda/web_components';
import { useHistory } from 'react-router-dom';
import { IAddDeviceStepComponent } from '../../add-external-device.types';
import { AppContext } from '../../../../../../contexts/app-context/app-context';
import { StepHeader } from '../../../../../common/overlay-modal-steps/overlay-modal-steps';
import {
  ActionButton,
  ActionButtonsContainer,
  DevicePropertyLabel,
  DevicePropertyValue,
  FunctionsDescriptionLabel,
  SectionContainer,
  StyledCheckIcon,
} from './captured-device-step.components';
import { DeviceClusterTypeEnums } from './captured-device-step.types';
import {
  getExternalDeviceByZigbeeId,
  onDeviceAccept,
  onDeviceReject,
} from './captured-device-step.logic';

export const deviceClusterTypesTranslations = {
  [DeviceClusterTypeEnums.BRIGHTNESS_CLUSTER]: 'Adjustable brightness',
  [DeviceClusterTypeEnums.STATE_CLUSTER]: 'ON / OFF',
  [DeviceClusterTypeEnums.COLOR_TEMP_CLUSTER]: 'Color temperature',
  [DeviceClusterTypeEnums.COLOR_XY_CLUSTER]: 'XY color',
};

const CapturedDeviceStep = function(props: IAddDeviceStepComponent) {
  const { onNextStep, onDeviceUpdate, device } = props;
  const [appContext] = React.useContext(AppContext);
  const { colors } = React.useContext(ThemeContext);
  const { currentLocationId } = appContext;
  const history = useHistory();
  const defaultEndpoint = device.endpoints?.find(endpoint => endpoint.name === 'default') || {};

  React.useEffect(() => {
    getExternalDeviceByZigbeeId(currentLocationId, device.zigbeeId).then(data =>
      onDeviceUpdate(data.data),
    );
  }, [device.zigbeeId]);

  return (
    <>
      <StepHeader>Captured device</StepHeader>
      <SectionContainer>
        <p>
          <DevicePropertyLabel>Name: </DevicePropertyLabel>
          <DevicePropertyValue>{device.description}</DevicePropertyValue>
        </p>
        <p>
          <DevicePropertyLabel>Model: </DevicePropertyLabel>
          <DevicePropertyValue>{device.model}</DevicePropertyValue>
        </p>
        <p>
          <DevicePropertyLabel>Producer: </DevicePropertyLabel>
          <DevicePropertyValue>{device.vendor}</DevicePropertyValue>
        </p>
      </SectionContainer>
      <SectionContainer>
        <FunctionsDescriptionLabel>Supported function by Streda:</FunctionsDescriptionLabel>
        {defaultEndpoint.clusters &&
          defaultEndpoint.clusters.map(({ clusterType }) => (
            <p>
              <StyledCheckIcon width={16} height={16} fill={colors.font3} />
              <DevicePropertyValue>
                {deviceClusterTypesTranslations[clusterType]}
              </DevicePropertyValue>
            </p>
          ))}
      </SectionContainer>
      <ActionButtonsContainer>
        <DevicePropertyValue>Do you want add device to network?</DevicePropertyValue>
        <ActionButton
          type="button"
          text="Accept"
          onClick={() =>
            onDeviceAccept(currentLocationId, device.zigbeeId).then(() => onNextStep())
          }
          testId="accept-external-device-button"
          colorType={ButtonColorType.PRIMARY}
        />
        <ActionButton
          type="button"
          text="Reject"
          onClick={() => {
            onDeviceReject(currentLocationId, device.zigbeeId).then(() =>
              history.push(`/external-devices`),
            );
          }}
          testId="reject-external-device-button"
          colorType={ButtonColorType.WHITE}
        />
      </ActionButtonsContainer>
    </>
  );
};

export default CapturedDeviceStep;
