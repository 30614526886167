import { getScenesApi } from '@streda/web_components';
import { getAccount } from '../auth.utils';

export const getScenesForLocation = locationId =>
  getScenesApi(getAccount())
    .sceneGetScenesForLocation(locationId)
    .then(result =>
      // TODO temporary workaround, on BE should be standardised naming convention, bug ISO001-1462
      result.data.map(scene => ({
        ...scene,
        actions: scene.actions.map(action => {
          const { actionParameters } = action;

          if (actionParameters.switchLedAction) {
            actionParameters.SwitchLedAction = actionParameters.switchLedAction;
            delete actionParameters.switchLedAction;
          }

          if (actionParameters.color) {
            actionParameters.Color = actionParameters.color;
            delete actionParameters.color;
          }

          if (actionParameters.switchAction) {
            actionParameters.SwitchAction = actionParameters.switchAction;
            delete actionParameters.switchAction;
          }

          if (Number.isInteger(actionParameters.soundNumber)) {
            actionParameters.SoundNumber = actionParameters.soundNumber;
            delete actionParameters.soundNumber;
          }

          return { ...action, actionParameters };
        }),
      })),
    );

export const invokeScene = (locationId, sceneId) => {
  return getScenesApi(getAccount())
    .sceneInvokeScenes(locationId, [sceneId])
    .then(result => result.data);
};

export default {
  getScenesForLocation,
  invokeScene,
};
