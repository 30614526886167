import { AppState, AppStateAction, AppStateActionType } from './app-context.types';

export const AppContextReducer = (state: AppState, action: AppStateAction) => {
  switch (action.type) {
    case AppStateActionType.UPDATE_WHOLE_STATE:
      return {
        ...action.state,
      };
    // case AppStateActionType.UPDATE_ASSIGNED_LOCATIONS:
    //   return {
    //     ...state,
    //     assignedLocations: action.assignedLocations,
    //   };
    case AppStateActionType.UPDATE_CURRENT_LOCATION_ID:
      return {
        ...state,
        currentLocationId: action.currentLocationId,
      };
    case AppStateActionType.UPDATE_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
      };
    case AppStateActionType.UPDATE_DOCKS:
      return {
        ...state,
        docks: action.docks,
      };
    case AppStateActionType.UPDATE_SCENES:
      return {
        ...state,
        locationScenes: action.locationScenes,
      };
    case AppStateActionType.UPDATE_EXTERNAL_DEVICES:
      return {
        ...state,
        externalDevices: action.externalDevices,
      };
    default:
      throw new Error();
  }
};

export default {
  AppContextReducer,
};
