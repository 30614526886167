import * as React from 'react';
import { getMappedCfData } from '../../../utils/getContentfulData.util';
import Privacy from './Privacy';
import { ISinglePolicyDataFields, SinglePolicy } from './types';

const PrivacyWrapper: React.FunctionComponent = () => {
  const [privacyList, setPrivacyList] = React.useState<SinglePolicy[]>(undefined);
  React.useEffect(() => {
    const cfData = async () => {
      const data = await getMappedCfData<ISinglePolicyDataFields>('singlePolicyData');
      const dataWithIds = data.map(el => {
        return { id: el.id, title: el.title, details: el.details };
      });
      setPrivacyList(dataWithIds as SinglePolicy[]);
    };
    cfData();
  }, []);

  if (!privacyList) {
    return <></>;
  }

  return (
    <>
      <Privacy privacyItems={privacyList} />
    </>
  );
};

export default PrivacyWrapper;
