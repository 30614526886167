import * as React from 'react';
import { hot } from 'react-hot-loader';
import '../assets/scss/styles.scss';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { UnsupportedScreenModal, useMatchBreakpoints } from '@streda/web_components';
import { getAccount } from '../utils/auth.utils';
import LoginPage from './pages/login-page/login.page';
import MainView from './main-view';

const App = () => {
  const [account, setAccount] = React.useState(getAccount());

  const loginCallback = () => {
    const storageAccount = getAccount();
    if (
      !account ||
      storageAccount.accessToken !== account.accessToken ||
      storageAccount.userName !== account.userName
    ) {
      setAccount(storageAccount);
    }
  };

  const { isUnsupported } = useMatchBreakpoints();
  const authorized = account && account.accessToken && account.userName;
  return (
    <div id="app">
      <>
        <UnsupportedScreenModal visible={isUnsupported} />
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage loginCallback={loginCallback} />
            </Route>
            {authorized && (
              <Route path="/">
                <MainView />
              </Route>
            )}
            {!authorized && <Redirect to="/login" />}
          </Switch>
        </Router>
      </>
    </div>
  );
};

declare let module: object;

export default hot(module)(App);
