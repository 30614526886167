import * as React from 'react';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import {
  hideLoader,
  LoaderMode,
  ModalLoader,
  showLoader,
  SnapInProductDto,
  usePromise,
} from '@streda/web_components';
import AddNewSnapIn from './add-new-snap-in/add-new-snap-in';
import SwapSnapIn from './swap-snap-in/swap-snap-in';
import { AppContext } from '../../../contexts/app-context/app-context';
import { getSnapInById } from '../../../utils/api/snap-in.api';

const SnapInReplace = function() {
  const { url, isExact } = useRouteMatch();
  const { dockNumber } = useParams() as {
    dockNumber: string;
  };
  const [appContext] = React.useContext(AppContext);
  const { docks, rooms } = appContext;
  const dock = Object.values(docks)
    .flat()
    .find(dockToCheck => dockToCheck.number === dockNumber);
  const { dockCode, snapInId } = dock || {};
  const getSnapInDetailsPromise = React.useMemo(() => {
    if (!snapInId) {
      return Promise.resolve({});
    }

    showLoader(<ModalLoader />, {
      mode: LoaderMode.MODAL,
    });
    return getSnapInById(snapInId).then(result => {
      hideLoader();
      return result;
    });
  }, [isExact, snapInId]);
  const [snapInDetails] = usePromise<SnapInProductDto>(getSnapInDetailsPromise, {});
  let pending: boolean = true;

  if (!dock) {
    showLoader(<ModalLoader />, {
      mode: LoaderMode.MODAL,
    });
    return null;
  }

  let snapIn = {
    snapInCode: dockCode,
  };
  const filteredRoom =
    Object.entries(docks).find(([, roomDocks]) =>
      roomDocks.find(dock => dock.number === dockNumber),
    ) || [];
  const roomId = filteredRoom.shift();

  if (!roomId) {
    return null;
  }

  const room = rooms.find(({ id }) => id === roomId);

  if (snapInId) {
    snapIn = {
      ...snapIn,
      ...snapInDetails,
    };

    if (snapInDetails.id) {
      pending = false;
    }
  } else {
    hideLoader();
    pending = false;
  }

  if (pending) {
    return null;
  }
  return (
    <Switch>
      <Route exact path={`${url}/add`}>
        <AddNewSnapIn dock={dock} room={room} snapIn={snapIn} />
      </Route>
      <Route exact path={`${url}/swap`}>
        <SwapSnapIn dock={dock} room={room} snapIn={snapIn} />
      </Route>
    </Switch>
  );
};

export default SnapInReplace;
